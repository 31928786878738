import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const instance_axios = axios.create({ baseURL: window.Config.REACT_APP_BACKEND_URL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export const getScreens = async () => {
    try {
        const screens = await instance_axios.get('/generic/screen', {
            withCredentials: true,
            headers: { common: headers }
        });
        return screens;
    } catch (err) {
        return err;
    }
}

export const getScreenGroups = async () => {
    try {
        const screenGroups = await instance_axios.get('/generic/screenGroup', {
            withCredentials: true,
            headers: { common: headers }
        });
        return screenGroups;
    } catch (err) {
        return err;
    }
}

export const getContent = async () => {
    try {
        const content = await instance_axios.get('/content/all', {
            withCredentials: true,
            headers: { common: headers }
        });
        return content;
    } catch (err) {
        return err;
    }
}

export const addEvent = async (event) => {
    try {
        const res = await instance_axios.post('/calendar/', event, {
            withCredentials: true,
            headers: { common: headers }
        });
        return res;
    } catch (err) {
        return err;
    }
}

export const updateEventContent = async (content) => {
    try {
        const r = await instance_axios.put('/content/updatemany', { docs: content }, {
            withCredentials: true,
            headers: { common: headers }
        });
        return r;
    } catch (err) {
        return err;
    }
}

export const disableEventContent = async (id, disableContent) => {
    try {
        const r = await instance_axios.post('/content/disablecontent/' + id, { disableContent }, {
            withCredentials: true,
            headers: { common: headers }
        });
        return r;
    } catch (err) {
        return err;
    }
}

export const getEvents = async () => {
    try {
        const events = await instance_axios.get('/generic/calendarEvent', {
            withCredentials: true,
            headers: { common: headers }
        });
        return events;
    } catch (err) {
        return err;
    }
}

export const getEvent = async (id) => {
    try {
        const event = await instance_axios.get('/generic/calendarEvent/' + id, {
            withCredentials: true,
            headers: { common: headers }
        });
        return event;
    } catch (err) {
        return err;
    }
}

export const updateEvent = async (data) => {
    try {
        const event_1 = await instance_axios.put('/calendar', data, {
            withCredentials: true,
            headers: { common: headers }
        });
        return event_1;
    } catch (err) {
        return err;
    }
}

export const deleteEvent = async (id) => {
    try {
        const event = await instance_axios.delete('/calendar/' + id, {
            withCredentials: true,
            headers: { common: headers }
        });
        return event;
    } catch (err) {
        return err;
    }
}

export const getAdmins = async () => {
    try {
        const admins = await instance_axios.get('/generic/admin', {
            withCredentials: true,
            headers: { common: headers }
        });
        return admins;
    } catch (err) {
        return err;
    }
}

export const endEvent = async (id) => {
    try {
        const res = await instance_axios.put(`/calendar/${id}/end`, {
            withCredentials: true,
            headers: { common: headers }
        });
        return res;
    } catch (err) {
        return err;
    }
}