import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export const getScreens = () => {
    return instance_axios.get('/generic/screen', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(screens => screens)
        .catch(err => err);
}
export const getUser = () => {
    return instance_axios.get('/user', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(user => user)
        .catch(err => err);
}
export const addNewUser = (data) => {
    return instance_axios.post('/user/addnewuser', data, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.response);
}
export const getCustomers = () => {
    return instance_axios.get('/generic/customer', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(user => user)
        .catch(err => err);
}
export const addCustomer = (data) => {
    return instance_axios.post('/generic', [data], {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err);
}
export const getBrands = () => {
    return instance_axios.get('/generic/brand', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(user => user)
        .catch(err => err);
}
export const addBrand = (data) => {
    return instance_axios.post('/generic/', [data], {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err);
}
export const modifyBrand = (brand) => {
    return instance_axios.put('/generic/', brand, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const deleteBrand = (brandID) => {
    return instance_axios.delete('/generic/' + brandID, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const setCurrencyOnUser = (currency) => {
    return instance_axios.put('/user/setcurrency', { currency }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const getUserData = () => {
    return instance_axios.get('/user/data', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const editTableManagement = (data) => {
    return instance_axios.put('/generic', data, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request)
}
export const removeItem = (id) => {
    return instance_axios.delete('/generic' + id, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const modifyCustomers = (customer) => {
    return instance_axios.post('/generic', customer, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const deleteUserWithRole = (id) => {
    return instance_axios.delete('/user/delete/' + id, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const verifyMail = (email) => {
    return instance_axios.get('/user/verifyMail' + email, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err);
}
export const setProgrammatic = (data) => {
    return instance_axios.put('/generic', data, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request)
}
export const setPublisherID = (publisher_id) => {
    return instance_axios.put('/user/setPublisherID', { publisher_id }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request)
}
export const getAllSSPID = () => {
    return instance_axios.get('/screen/getallsspids', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err.request);
}
export const setMailing = (data) => {
    return instance_axios.post('/user/setmailing',data, {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err.request);
}

export const putObject = (data) => {
    return instance_axios.put('/generic',data, {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err.request);
}

export const changeCommercializeString = (commercialize_all_by,screens_ids) => {
    
    return instance_axios.put('/user/setcommercialize',{commercialize_all_by,screens_ids}, {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err.request);
}
