import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export const getAllContent = () => {
    return instance_axios.get('/content/all', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const getByParent = (path) => {
    return instance_axios.post('/content/getbyparent', { path }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const deleteContent = (IDs) => {
    return instance_axios.post('/content/deletecontent', { IDs }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const renameContent = (newName, id) => {
    return instance_axios.post('/content/rename/' + id, { newName }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const changePath = (newPath, id) => {
    return instance_axios.post('/content/changepath/' + id, { newPath }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const changeScreenID = (screens_ids, id) => {
    return instance_axios.post('/content/changescreenid/' + id, { screens_ids }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => console.log(err))
}
export const updateContent = (newDoc) => {
    return instance_axios.put('/content/updatemany', { docs: [newDoc]}, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err)
}

export const updateManyContent = (docs) => {
    return instance_axios.put('/content/updatemany', { docs: docs}, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err)
}

export const checkLimitExceeded = (screens_ids) => {
    return instance_axios.post('/content/checklimit', { screens_ids })
        .then(r => r)
        .catch(err => err);
}
export const changeIconClasses = (content) => {
    return instance_axios.put('/', content, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err)

}
export const getContent = () => {
    return instance_axios.get('/content/all', {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err);
}

export const getScreenGroups = () => {
    return instance_axios.get('/generic/screenGroup', {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(screenGroups => screenGroups)
    .catch(err => err);
}

export const postIterativeContent = (iterativeContent) => {
    return instance_axios.post('/generic/',iterativeContent, {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err.request);
}

export const replaceMediaContentFromItem = (id, path) => {
    return instance_axios.put('/content/replace/' + id, { path }, {
        withCredentials: true,
        headers: { common: headers }
    })
    .then(r => r)
    .catch(err => err.request);
}