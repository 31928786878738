import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getUser, postUser, changePassword } from "./Profile.functions";
import { validateMail } from "../utils/auth";
import "./Profile.css";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: {},
            btnEditar: false,
        };
    }
    componentDidMount = async () => {
        try {
            let usuario = await getUser();
            this.setState({
                usuario: usuario.data,
            });
        } catch (e) {
            console.log(e);
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };

    /**
     * Checking if the fields are complete and if the email is valid.Then send the user info to the backend.
     * */
    saveChanges = () => {
        let name = document.getElementById("inputName");
        let lastName = document.getElementById("inputLastName");
        let publisher_id = document.getElementById("inputPublisherId");
        let inputPhone = document.getElementById("inputPhone");
        let inputEmail = document.getElementById("inputEmail");
        let arrayData = [name, lastName, publisher_id, inputPhone, inputEmail];
        let fieldAreFine = false;
        if (this.completeFields(arrayData)) {
            let dataMail = inputEmail.value;
            if (dataMail === "") {
                dataMail = inputEmail.placeholder;
            }
            if (validateMail(dataMail)) {
                fieldAreFine = true;
            } else {
                let span = document.getElementById("spanMail");
                span.classList.remove("invisible");
            }
        } else {
            fieldAreFine = false;
        }
        if (fieldAreFine === true) {
            let user = this.state.usuario;
            user.first_name = name.value;
            user.last_name = lastName.value;
            user.publisher_id = publisher_id.value;
            user.phone_number = inputPhone.value;
            user.email = inputEmail.value;
            this.postUser(user);
            this.unlockInput();
        }
    };

    /**
     * Calling the postUser function and passing the user object.
     * @param {{}} User
     * */
    postUser = async (user) => {
        try {
            const response = await postUser(user);
            if (response.status !== 201) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
                return;
            }
            user._rev = response.data[0].rev;
            this.setState({ usuario: user });
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };

    /**
     * Checking if the value of the input is empty, if it is empty it will replace the value with the placeholder and return boolean whit the answer
     * @param {[]} arrayData
     * @return {boolean}boolean
     * */
    completeFields = (arrayData) => {
        let fieldAreEmpty = false;
        arrayData.forEach((data) => {
            if (data.value === "") {
                data.value = data.placeholder;
            }
        });

        return !fieldAreEmpty;
    };
    /**
     * change the  button options and unlock the inputs field
     */
    unlockInput = () => {
        let btn = document.getElementById("btnSave");
        if (this.state.btnEditar === false) {
            btn.classList.remove("invisible");
            this.setState({ btnEditar: !this.state.btnEditar });
        } else {
            btn.classList.add("invisible");
            this.setState({ btnEditar: !this.state.btnEditar });
        }
        let inputs = document.querySelectorAll("input");
        inputs.forEach((input) => {
            if (!input.classList.contains("basicInfo")) {
                input.classList.add("bg-blue-100");
                input.disabled = !input.disabled;
            }
            if (input.disabled === true) {
                input.classList.remove("bg-blue-100");
            }
        });
    };

    /**
     * take the value of the fields , if they match,sent the data to backend and change the password
     *
     */
    changePassword = async () => {
        let password = document.getElementById("oldPassword").value;
        let newPasword = document.getElementById("newPassword").value;
        let repNewPasword = document.getElementById(
            "repeatedNewPassword"
        ).value;

        if (newPasword === repNewPasword) {
            let userId = this.state.usuario._id;
            try {
                const response = await changePassword(
                    newPasword,
                    password,
                    userId
                );
                if (response.status === 200) {
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t(
                            "common.notification.passwordSuccess"
                        ),
                    });
                    document
                        .getElementById("blackScreen")
                        .classList.add("hidden");
                    document
                        .getElementById("ModalProfile")
                        .classList.add("hidden");
                    document
                        .getElementById("blackScreen")
                        .classList.remove("blackScreen");

                    this.disconnect();
                    return;
                } else if (response.status === 404) {
                    this.props.showNotification({
                        type: "warning",
                        text: this.props.t(
                            "common.notification.passwordDoNotMatch"
                        ),
                    });
                    return;
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t("common.notification.serverError"),
                    });
                    return;
                }
            } catch (err) {
                console.log(err);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t("common.notification.serverError"),
                });
                return;
            }
        }
    };

    render() {
        const { t } = this.props;
        const { modalID } = this.props;

        return (
            <React.Fragment>
                <div
                    // onClick={this.props.toggleProfile}
                    id="blackScreen"
                    className=" hidden w-full z-20  "
                >
                    <div
                        id={modalID}
                        className="container profileBox slideInRight rounded relative bg-white sm:w-auto   shadow-lg mr-0 h-full pb-0 z-30 hidden overflow-auto"
                    >
                        <div className=" pl-20 pr-20  h-full  mb-0">
                            <div className="w-90 mx-auto">
                                <div
                                    className="cursor-pointer pt-10 pb-5 "
                                    onClick={() => {
                                        this.props.handleModalsOpen(
                                            "",
                                            this.props.toggleProfile()
                                        );
                                    }}
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fal"
                                        data-icon="times"
                                        className="svg-inline--fa fa-times fa-fw text-blue-400 mb-2"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                        ></path>
                                    </svg>
                                    <div className="block my-2 font-semibold">
                                        {" "}
                                        {t("common.menu.profile")}
                                    </div>
                                </div>
                                <div></div>
                                <div className="ml-0 flex flex-row w-full">
                                    <label
                                        className="block uppercase tracking-wide text-gray-600 text-xs font-bold pb-2 mt-1 border-b-2 w-full"
                                        htmlFor="grid-first-name"
                                    >
                                        {t("sections.profile.basicInformation")}
                                    </label>
                                    <div className="border-b-2">
                                        <button
                                            id="btnSave"
                                            onClick={this.saveChanges}
                                            // className="flex uppercase item-center font-bold text-xs h-6 text-blue-600 buttonPrimary-sm mb-0 invisible ; "
                                            className="flex buttonPrimary-sm invisible"
                                        >
                                            {t("sections.profile.save")}
                                        </button>
                                    </div>
                                    <div className="border-b-2">
                                        <button
                                            onClick={this.unlockInput}
                                            href="#"
                                            // className="flex    uppercase  item-center font-bold text-xs h-6 text-blue-600 buttonPrimary-sm mb-0  "
                                            className="flex buttonPrimary-sm "
                                        >
                                            {t("sections.profile.edit")}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2  mt-5"
                                            htmlFor="inputName"
                                        >
                                            {t(
                                                "sections.register.name-placeholder"
                                            )}
                                        </label>
                                        <input
                                            className=" appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="inputName"
                                            type="text"
                                            placeholder={
                                                this.state.usuario
                                                    ?.first_name ?? ""
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-5"
                                            htmlFor="inputLastName"
                                        >
                                            {t(
                                                "sections.register.lastname-label"
                                            )}
                                        </label>
                                        <input
                                            className="  appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="inputLastName"
                                            type="text"
                                            placeholder={
                                                this.state.usuario?.last_name ??
                                                ""
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full md:w-1/2 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-2"
                                            htmlFor="inputPublisherId"
                                        >
                                            {/* publisher_id */}
                                            {t("sections.profile.publisherID")}
                                        </label>
                                        <input
                                            className="  appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="inputPublisherId"
                                            type="number"
                                            placeholder={
                                                this.state.usuario
                                                    ?.publisher_id ?? ""
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                                            htmlFor="inputPhone"
                                        >
                                            {t("sections.register.phone-label")}
                                        </label>
                                        <input
                                            className="  appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="inputPhone"
                                            type="text"
                                            placeholder={
                                                this.state.usuario
                                                    ?.phone_number ?? ""
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="w-full md:w-100 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-4"
                                            htmlFor="inputEmail"
                                        >
                                            {t("sections.register.email-label")}
                                        </label>
                                        <input
                                            className="  appearance-none block w-full bg-gray-200 text-gray-600 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="inputEmail"
                                            type="text"
                                            placeholder={
                                                this.state.usuario?.email ?? ""
                                            }
                                            disabled
                                        />
                                        <span
                                            id="spanMail"
                                            className="text-red-500 invisible"
                                        >
                                            {t(
                                                "sections.profile.PleaseWriteValidEmail"
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className="m-7 ml-0 pt-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2  mt-4 border-b-2"
                                        htmlFor="grid-first-name"
                                    >
                                        {t(
                                            "sections.resetPassword.changePassword"
                                        )}
                                    </label>
                                </div>

                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="password"
                                        >
                                            {t(
                                                "sections.register.password-label"
                                            )}
                                        </label>
                                        <input
                                            className=" basicInfo appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="oldPassword"
                                            type="password"
                                            placeholder="******************"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t(
                                                "sections.resetPassword.newPassword"
                                            )}
                                        </label>
                                        <input
                                            className="basicInfo appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="newPassword"
                                            type="password"
                                            placeholder="******************"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-3">
                                        <label
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t(
                                                "sections.profile.repeatNewPassword"
                                            )}
                                        </label>
                                        <input
                                            className="basicInfo appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="repeatedNewPassword"
                                            type="password"
                                            placeholder="******************"
                                        />
                                    </div>
                                    <div className="flex justify-end  w-full  ">
                                        <button
                                            type="submit"
                                            className=" buttonPrimary"
                                            onClick={this.changePassword}
                                        >
                                            {t(
                                                "sections.resetPassword.changePassword"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(Profile);
