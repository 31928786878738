import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import ButtonSwitcher from '../buttons/ButtonSwitcher';
class StepFive extends Component {
    back = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep} = this.props;
        setCurrentStep(currentStep - 1);
    }
    
    continue = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep} = this.props;
        setCurrentStep(currentStep + 1);
    }

    handleCheckbox = (e) => {
        const checkbox = e.target;
 
        if(checkbox.getAttribute("id") === "imgRelation"){
            if(checkbox.checked){
                if(document.querySelector("#screenRelation").checked){
                    document.querySelector("#screenRelation").checked = false
                }
                this.props.setAspectRatio(checkbox)
            }
        }else if(checkbox.getAttribute("id") === "screenRelation"){
            if(checkbox.checked){
                if(document.querySelector("#imgRelation").checked){
                    document.querySelector("#imgRelation").checked = false
                }
                this.props.setAspectRatio(checkbox)
            }
        }
    }

    render() {
        const handleSimpleSelect = this.props.handleSimpleSelect;
        const handleProgrammatic = this.props.setProgrammaticVisibility;
        const {min_prints, run_time, aspectRatio, programmatic_view} = this.props.values;
        const impresiones = [
            { name: '500', value: '500'},
            { name: '1000', value: '1000'}
        ];
        const actions = [
            { name: this.props.t('sections.wizard.stepFive.immediate'),value:'inmediate'},
            { name: '24hs', value: '24hs'},
            { name: '48hs', value: '48hs'}
        ];

        return (
            <div className="w-full p-5">
                <h1 className="text-gray-800 text-xl font-bold">{this.props.t('sections.wizard.stepFive.stepTitle')} <span className="font-light text-sm text-gray-700"> - {this.props.t('sections.wizard.stepFive.stepTitleExtra')}</span></h1>
                <hr/>

                <div className="my-2">
                    <label>
                        {this.props.t('sections.wizard.stepFive.programmatic')}
                        <FontAwesomeIcon data-tip data-for='programaticInfo' icon={["fas", "info-circle"]} className="ml-2 text-sm outline-none" />
                    </label>
                    <div className="flex-nowrap flex flex-start">
                        <ButtonSwitcher handleVisibility={handleProgrammatic} status={programmatic_view} labelOn="On" labelOff="Off"  revertLabel={true} />         
                    </div>
                </div>
                
                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFive.minimumPrints')}</label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select onChange={handleSimpleSelect('min_prints')} defaultValue="-1" value={min_prints} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                <option value="-1">{this.props.t('sections.wizard.stepFive.noMinimum')}</option>
                                {impresiones.map((impresion, index) => (
                                    <option key={index} value={impresion.value}>{impresion.name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFive.actionTime')}</label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select onChange={handleSimpleSelect('run_time')} defaultValue="0" value={run_time} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                {actions.map((action, index) => (
                                    <option key={index} value={action.value}>{action.name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFive.restrictions')}</label>
                    <ul className="list-disc">
                        {restrictions.map(restriccion => <li key={restriccion._id} className="ml-5 text-gray-700">{restriccion.name}</li>)}
                    </ul>
                </div> */}
                <div className="my-2 w-auto">
                        <label>
                            {this.props.t("sections.wizard.stepFive.aspectRatio")}
                            <ReactTooltip effect="solid" className="w-48 p-2" id="aspectRatioInfo" aria-haspopup='true'>
                                <div className="mb-5">
                                    <FontAwesomeIcon className="mx-2 text-blue-400 " icon={["far", "image"]} size="1x" title={this.props.t("common.modals.fileDropper.image")}/>
                                    {this.props.t('sections.wizard.stepFive.imgTooltip')} <br/>
                                </div>
                                <div>
                                    <FontAwesomeIcon className="mx-2 text-blue-400 " icon={["far", "desktop"]} size="1x" title={this.props.t("common.modals.fileDropper.screen")}/>
                                    {this.props.t('sections.wizard.stepFive.scrTooltip')}
                                </div>
                            </ReactTooltip>
                            <FontAwesomeIcon data-tip data-for='aspectRatioInfo' icon={["fas", "info-circle"]} className="ml-2 text-sm outline-none" />
                        </label>
                        <div className="flex-nowrap">
                            <FontAwesomeIcon className="mx-2 text-blue-400" icon={["far", "image"]} size="1x" title={this.props.t("common.modals.fileDropper.image")}/>
                            {aspectRatio === 'image' ?
                            <input className="border-blue-300 text-blue-400" type="checkbox" id="imgRelation" checked  onChange={this.handleCheckbox}/>
                            : <input className="border-blue-300 text-blue-400" type="checkbox" id="imgRelation" onChange={this.handleCheckbox}/>
                            }
                        </div>
                        <div className="flex-nowrap">
                            <FontAwesomeIcon className="mx-2 text-blue-400" icon={["far", "desktop"]} size="1x" title={this.props.t("common.modals.fileDropper.screen")}/>
                            {aspectRatio === 'screen' ?
                            <input className="border-blue-300 text-blue-400" type="checkbox" id="screenRelation" checked onChange={this.handleCheckbox}/>
                            : <input className="border-blue-300 text-blue-400" type="checkbox" id="screenRelation" onChange={this.handleCheckbox}/>
                            } 
                        </div>
                </div>
                <div className="mt-8">
                    <button className="buttonSecondary"
                            onClick={this.back}>{this.props.t('common.buttons.back')}</button>
                    <button className="buttonPrimary"
                            onClick={this.continue}>{this.props.t('common.buttons.next')}</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(StepFive);