import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../utils/modals.css';
import { cloneDeep } from 'lodash';
const optionsRol = [
    {
        value:"account_manager",
        label:"Account Manager (traducir)",
    },{
        value:"stat_viewer",
        label:"Stat Viewer (traducir)",
    },
    {
        value:"broker",
        label:"Broker (traducir)",
    },
    {
        value:"owner",
        label:"Owner (traducir)",
    }
]
class EditUserModal extends React.Component {
    constructor(){
        super();
        this.state = {

        }
    }
    componentDidUpdate = (prevProps) => {
        if(prevProps.values !== this.props.values){
            Object.keys(this.props.values).forEach(key => {
                this.setState({[key]: this.props.values[key]}, () => {
                });
            })
        }
    }
    returnValue = (e) => {
        let state = cloneDeep(this.state);
        state._id = this.props.values._id;
        state._rev = this.props.values._rev;
        this.props.callbackFunction(state);
        this.closeModal();
    }
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add('hidden');
    }
    rolChange = (e) => {
        let userRol = e.target.value;
        this.setState({userRol});
    }
    changeInput =(e)  =>{;
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    render() {
        return (
            <div id={this.props.modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className="modalContent modal-lg bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-2xl"><span className="font-normal">{"Editar Usuario (traducir)"}:</span> {this.props.values.first_name + " " + this.props.values.last_name}</div>
                        <div className="button cursor-pointer" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className="modal-content h-auto py-4 px-2 rounded text-gray-500">
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2" >{"first_name (traducir)"}</label>
                            <input id="first_name" onChange={this.changeInput} className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`} value={this.state.first_name}/>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2" >{"last_name (traducir)"}</label>
                            <input id="last_name" onChange={this.changeInput} className= {`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`}value={this.state.last_name}/>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2" >{this.props.t('sections.management.section.commercial.customers.customerTable.company')}</label>
                            <input id="company" onChange={this.changeInput} className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`} value={this.state.company}/>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.management.section.commercial.customers.customerTable.mail')}</label>
                            <input id="email" onChange={this.changeInput} className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`} value={this.state.email}/>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.management.section.commercial.customers.customerTable.phone')}</label>
                            <input id="phone_number" onChange={this.changeInput} className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`} value={this.state.phone_number}/>
                        </div>
                        <div className="mb-4">
                            <label className="labelModal text-gray-700 text-sm font-bold mb-2">{"actual_cms (traducir)"}</label>
                            <input id="actual_cms" onChange={this.changeInput} type="text" className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`} value={this.state.actual_cms}/>
                        </div>
                        <div className="mb-4">
                        <label className="labelModal text-gray-700 text-sm font-bold mb-2">{"role (traducir)"}</label>
                            <select tabIndex="2" onChange={this.rolChange} id="userRol" className={`inputModal${this.props.type} w-full block shadow appearance-none border rounded py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring`}>
                                {optionsRol.map((option, i) => {
                                    if(option.value === this.state.userRol){
                                        return <option selected key={i} value={option.value}>{option.label}</option>;
                                    }else{
                                        return <option key={i} value={option.value}>{option.label}</option>;
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button className="buttonPrimary" onClick={this.returnValue}>{this.props.t('common.buttons.submit')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EditUserModal);