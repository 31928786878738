import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import ManagementHeader from './ManagementHeader';
import ButtonSwitcher from '../buttons/ButtonSwitcher';
import * as dotenv from 'dotenv';
import EditComScreenModal from '../utils/EditComScreenModal';
import EditCustomerModal from '../utils/EditCustomerModal';
import EditUserModal from '../utils/EditUserModal';
import {getScreens, getUser, addCustomer, getCustomers, getBrands, addBrand, setCurrencyOnUser, getUserData, removeItem, modifyCustomers, editTableManagement, verifyMail, modifyBrand, setProgrammatic, setPublisherID, getAllSSPID, deleteBrand,setMailing,putObject,changeCommercializeString} from './Management.Functions';
import {validateMail} from '../utils/auth';
import {register} from '../Log_Reg/UserFunctions'
import CreateModal from '../utils/CreateModal';
// import CreateUserModal from '../utils/CreateUserModal';
import DecisionModal from '../utils/DecisionModal';
import currency from '../../util_files/currency.json';
import Select, {createFilter, components} from 'react-select';
import iabCategoriesEN from '../../util_files/iab_categories_en.json';
import cloneDeep from 'lodash.clonedeep';
import ReactTooltip from 'react-tooltip';
dotenv.config();

class Management extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveSection : "finance",
            isActiveCommercialMenu : "pricing",
            isModalCommercialDealOpen : false,
            isActiveMailingMenu : "contactMail",
            editRow : [],
            user :{},
            customers : [],
            brands : [],
            screens : [],
            currency : currency,
            actualCurrency : "",
            actualRow: "",
            publisher_id : "",
            toggledClearRows : false,
            iab_categories: [],
            programmaticScreens: [],
            publisherScreens: [],
            emailsAdresses : [],
            periodicityMail : "",
        }
    }
   
    // }
    /* Creating a table with the following columns:
    1. Name
    2. Ad Value
    3. Currency
    4. Button that trigger a function for the row*/
    commercialScreenPricing = [
        {name: 'id', selector: row => row._id, omit: true},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.name'), selector: row => row.name, sortable: true, width: '45%'},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.priceFor'), selector: row => row._id,cell:(row) => <ButtonSwitcher handleVisibility={()=>{this.setCommercializeScreenBy(row._id,row.commercialize_by)}} status={this.statusSwicher(row.commercialize_by)} labelOn={this.props.t('sections.management.section.commercial.pricing.priceTable.second')} labelOff={this.props.t('sections.management.section.commercial.pricing.priceTable.impression')} > </ButtonSwitcher>}, 
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.adValue'), selector: row => row.adValue || this.props.t('sections.management.section.commercial.pricing.priceTable.noAdValue'), sortable: true},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.currency'), selector: row => row.currency || this.props.t('sections.management.section.commercial.pricing.priceTable.noCurrency'), sortable: false},
        {
            //Hacer andar el Modal para editar datos:
            //Editar Valor de Ad Impression (es el valor de cada impresión o salida de un anuncio en esta pantalla).
            cell: (row) => <button className="buttonPrimary" raised primary onClick={(e) => {this.toggleCommercialScreen(row, true); this.props.openModal("editComScreenModal")}}>{this.props.t('sections.management.section.commercial.pricing.priceTable.button')}</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
      /* Creating a table with the following columns:
    1. Company
    2. Contact Name
    3. Email
    4. Phone
    5. Discount
    6. Button that gives the row the function to edit values
    7. Button that gives the function to delete the row*/
    commercialCustomersList = [
        {name: 'id', selector: row => row._id, omit : true},
        {name: this.props.t('sections.management.section.commercial.customers.customerTable.company'), selector: row => row.company, sortable: true},
        {name: this.props.t('sections.management.section.commercial.customers.customerTable.contact'), selector: row => row.contact_name, sortable: true},
        {name: this.props.t('sections.management.section.commercial.customers.customerTable.mail'), selector: row => row.email, sortable: false},
        {name: this.props.t('sections.management.section.commercial.customers.customerTable.phone'), selector: row => row.phone, sortable: false},
        {name: this.props.t('sections.management.section.commercial.customers.customerTable.discount'), selector: row => row.discount, sortable: false},
        {
            //Hacer andar el Modal para editar datos.
            //Editar Datos principales y opción de BORRAR Customer (con confirmación).
            cell: (row) => <button className="buttonSecondary-sm" raised primary onClick={() => {this.toggleCommercialCustomersModal(row, true); this.props.openModal("editCustomerModal")}}>{this.props.t('sections.management.section.commercial.customers.customerTable.editButton')}</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => <button className="px-3 p-1 bg-red-50" onClick={(e) =>{
                this.setState({actualRow: {
                    type: "customer",
                    row
                }},() => {
                        this.props.openModal("removeRow");
                })
            }}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" /></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];
    /**
     * Fields that will be displayed in "Create Customer" modal
     */
    customersFields = [
        {name: 'company', value: this.props.t('sections.management.section.commercial.customers.customerTable.company'), field_type:"input"},
        {name: 'contact_name', value: this.props.t('sections.management.section.commercial.customers.customerTable.contact'), field_type:"input"},
        {name: 'email', value: this.props.t('sections.management.section.commercial.customers.customerTable.mail'), field_type:"input"},
        {name: 'phone', value: this.props.t('sections.management.section.commercial.customers.customerTable.phone'), field_type:"input"},
        {name: 'discount', value: this.props.t('sections.management.section.commercial.customers.customerTable.discount'), field_type:"input"}
    ];
    /**
     * Fields that will be displayed in "Create Brand" modal
     */
    brandValue = [
        {name: 'name', value: this.props.t('sections.management.section.commercial.brands.brandName'), field_type:"input"},
        {name: 'categories', value: this.props.t('sections.management.section.commercial.brands.brandCategory'), field_type: 'select'}
    ];
    /**
     * Creating a table with the following columns:
     * 1. Complete Name
     * 2. Email
     * 3. User Rol
     * 4. Phone Number
     * 5. Button that gives the row the function to edit values
     * 6. Button that gives the function to delete the row
     */
    // usersUsersList = [
    //     {name: 'id', selector: "_id", omit : true},
    //     {name: this.props.t('sections.management.section.users.usersTable.name'), selector: "complete_name", sortable: true},
    //     {name: this.props.t('sections.management.section.users.usersTable.mail'), selector: "email", sortable: true},
    //     {name: this.props.t('sections.management.section.users.usersTable.role'), selector: "user_rol", sortable: true},
    //     {name: this.props.t('sections.management.section.users.usersTable.phone'), selector: "phone_number", sortable: true},
    //     {
    //         //Hacer andar el Modal para editar datos.
    //         //Editar Datos principales y opción de BORRAR Customer (con confirmación).
    //         cell: (row) => <button className="buttonPrimary" raised primary onClick={() => {this.toggleUserModal(row, true); this.props.openModal("editUserModal")}}>{this.props.t('sections.management.section.users.usersTable.editButton')}</button>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //     },
    //     {
    //         //Hacer andar el Modal para editar datos.
    //         //Editar Datos principales y opción de BORRAR Customer (con confirmación).
    //         cell: (row) => <button className="buttonDanger" raised primary onClick={() => {this.setState({rowToDelete: row}, () => {this.props.openModal('removeUser')})}}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" /></button>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //     },
    // ];
    usersFields = ['name', 'rol', 'mail', 'phone'];
    componentDidMount = () => {
        getScreens().then(r => {
            if(r.status === 200){
                let screens = r.data;
                this.setState({screens});
            }
        })
        .catch(err => console.log(err));
        getUser().then(r => {
             if(r.status === 200){
                this.setState({user:r.data});
             }
        })
        .catch(err => {
            console.log(err);
            this.props.showNotification({
                type: "error",
                text: this.props.t('sections.management.users.errorGetUser')
            });
        });
        getCustomers().then(r => {
            if(r.status === 200){
                let customers = r.data;
               this.setState({customers});
            }
        })
        .catch(err => console.log(err));
        getBrands().then(r => {
            if(r.status === 200){
                let brands = r.data;
                this.setState({brands});
            }
        })
        .catch(err => console.log(err));
        getUserData().then(r => {
            if(r.status === 200){
                this.setState({emailsAdresses: r.data.mails});
                this.setState({periodicityMail: r.data.periodicity});
                this.setState({actualCurrency: r.data.currency}, () => {
                    this.setState({publisher_id: r.data.publisher_id}, () => {
                        this.setProgrammaticCPM();
                        this.parseDataOfPublisherScreens();
                    });

                });
            }
        });
        this.filterCategories();
        if(this.props.location.state){
            this.setState({isActiveSection: this.props.location.state.data.isActiveSection})
            this.props.closeNotification()
        }
    }
     /**
     * Return the status of switcher general
     * @param {*} allInTrue 
     * @returns boolean
     */
      getComercializeStatus = (allInTrue) =>{
        if(allInTrue){
            if( this.state.user.commercialize_all_by === "seconds"){
                return true;
            }
        }
        return false;
    }
    /**
     * Return the status of switcher in the table
     * @param {*} value 
     * @returns boolean
     */
    statusSwicher = (value)=>{
        if(value === "seconds"){
            return true;
        }
        return false;
    }
    /**
     * Return a boolean that say if all the switchest in the tabla are in seconds
     * @returns  boolean
     */
    isAllSwitchInTrue = () => {
        let screens = cloneDeep(this.state.screens);
        let commercialize_by = screens.map(e => e.commercialize_by);
        if(!commercialize_by.includes("impressions")){
            return true;
        }
        return false;
    }
    /**
     * set the switch general white the data that has pass for parameter
     * @param {*} user with the seasson open 
     * @param {*} commercialize_all_by the value of the switch
     */
    setPriceGeneralOfUser = async (user,commercialize_all_by) => {
        try {
            user.commercialize_all_by = commercialize_all_by;
            let response = await putObject(user);
            if(response.status === 200){
                user._rev = response.data.rev;
                this.setState({user:user});
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Set the switch general with the data that has pass for parameter
     * @param {*} id of the screen
     * @param {*} commercialize_by the value of the switch
     */
     setCommercializeScreenBy = async (id, commercialize_by) => {
        let screens = cloneDeep(this.state.screens);
        let screen = screens.find(screen => screen._id === id);
        let user = cloneDeep(this.state.user);

        if(commercialize_by === "impressions"){
            screen.commercialize_by = "seconds";
        }else{
            screen.commercialize_by = "impressions";
            this.setPriceGeneralOfUser(user,"impressions");
        }
        try {
            let response = await putObject(screen);
            if(response.status === 200){
                screen._rev = response.data.rev;
                this.setState({screens:screens});
                this.props.showNotification({
                    type: "success",
                    text: this.props.t('common.notification.management.commercial.pricing.ScreenPriceChanged')
                });
            }
            if(this.isAllSwitchInTrue()){
                this.setPriceGeneralOfUser(user,"seconds");
            }
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Change the value of all Switches in the table with the value passed for parameter
     * @param {*} value 
     */
     setCommercializeAllScreensBy = async (value) => {
        let screens = cloneDeep(this.state.screens);
        let user = cloneDeep(this.state.user);
        let screensIds = screens.map(screen => screen._id);

        try {
            let response = await changeCommercializeString(value,screensIds);
            if(response.status === 200) {
                user._rev = response.data.user.rev;
                screens.forEach(screen => {
                    let object = response.data.screens.find(object => object.id === screen._id);
                    screen._rev = object.rev;
                    if(value === "seconds"){
                        screen.commercialize_by = "seconds";
                        user.commercialize_all_by= "seconds";
                    }else{
                        screen.commercialize_by = "impressions";
                        user.commercialize_all_by= "impressions";
                    }
                })
                this.setState({user:user,screens:screens});
                this.props.showNotification({
                    type: "success",
                    text: this.props.t('common.notification.management.commercial.pricing.ScreenPriceChanged')
                });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Returns the opposite value that the user has
     * @returns 
     */
    getCommercializeString = () => {
        let user = cloneDeep(this.state.user);
        if(user.commercialize_all_by){
            if(user.commercialize_all_by === "seconds"){
                return "impressions";
            }else{
                return "seconds";
            }
        }
        return "impressions";
    }
    /**
     * Function that gets today Date in format:
     * YYMMDDHH
     * @returns
     */
    getToday = () => {
        let now = Date.now();
        let todayDate = new Date(now);
        let parsedToday = todayDate.getFullYear().toString().slice(2,4) + ("0" + (todayDate.getMonth()+1).toString()).slice(-2) + ("0" +todayDate.getDate().toString()).slice(-2) + ("0"+todayDate.getHours().toString()).slice(-2)
        return parsedToday;
    }
    /**
     * Checking if the width and height of the screen in the database is equal to the width and height of the screen in the state.
     * @param {Object} screen
     * @returns boolean that represents if param screen and programmatic screen have equal dimensions
     */
    itemAndProgrammaticHasEqualDimension = (screen) => {
        let publisherScreens = cloneDeep(this.state.publisherScreens);
        let test;
        publisherScreens.forEach(publisherScreen => {
            let progID = publisherScreen.id.split('_')[1];
            if(progID === screen.SSPID){
                test = (Number(screen.pixel_size_width) === Number(publisherScreen.width) && Number(screen.pixel_size_height) === Number(publisherScreen.height));
            }
        });
        return test;
    }
    /**
     * Function that parse programmatic screens and set in state the array.
     */
    parseDataOfPublisherScreens = async () => {
        let publisherID = cloneDeep(this.state.publisher_id);
        let programmaticScreens = await this.getProgrammaticScreens(publisherID);
        let publisherScreens = [];
        Object.keys(programmaticScreens.data).forEach(key => {
            let progScreen = programmaticScreens.data[key];
            if(Number(progScreen.publisher.id.split('_')[1]) === Number(publisherID)){
                let screen = {
                    id: progScreen.id,
                    width: progScreen.width,
                    height: progScreen.height,
                }
                publisherScreens.push(screen);
            }
        });
        this.setState({publisherScreens});
    };
    /**
     * Function that fetch preogrammatic screen from screens API
     * @param {String} publisher
     * @returns JSON Object that contains preogrammatic screen properties inside
     */
    getProgrammaticScreens = async (publisher) => {
        try {
            //tfx_3148
            /* let screenResponse = await fetch(`https://api-stat-v2.taggify.net/api/statistic/raw?storage=ssp.disp.supply&from=22011300&till=22011323&group=ad&publishers=${publisher}`); */
            let screenResponse = await fetch('https://api-screens-v2.taggify.net/api/screens/list');
            let screenResponseJson = await screenResponse.json();
            return screenResponseJson;
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.reports.getProgScreenError') //Error al traer pantallas programaticas
            });
            return null;
        }
    }
    /**
     * Function that fetch statistics in given date, with specific publisher ID and currency.
     * Then search for the correspondent record and calculate the CPM.
     * Then filter the screens by SSPID and set the CPM of this screen.
     * At the end save screens in the state.
     */
    setProgrammaticCPM = () => {
        let actualDate = this.getToday();
        let publisher_id = this.state.publisher_id;
        let currency = this.state.actualCurrency;
        if(!publisher_id || !currency){
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.programmatic.screenWithProgrammaticwarning')
            });
            return;
        }
        let urlApi = `https://api-stat-v2.taggify.net/api/statistic/raw?storage=ssp.disp.supply&from=01010100&till=${actualDate}&group=tag&publishers=${publisher_id}&currencies=${currency}`;
        fetch(urlApi).then(r => {
            if(r.status === 200){
                r.json().then(json => {
                    if(!json.error){
                        getAllSSPID().then(r => {
                            if(r.status === 200){
                                let ssp_ids = r.data;
                                let programaticStats = json.data.records.filter(record => ssp_ids.includes(record.id)).map(record => {
                                    let impresion = record.values[7];
                                    let revenue = record.values[16];
                                    let cpm = (revenue/impresion)*1000. || 0;
                                    return {
                                        id: record.id,
                                        cpm: cpm.toFixed(2)
                                    }
                                });
                                let screens = cloneDeep(this.state.screens);
                                let screensToShow = screens.filter(screen => {
                                    let screenProgramaticStat = programaticStats.filter(stat => stat.id === screen.SSPID);
                                    if(screenProgramaticStat.length > 0){
                                        screen.cpm = screenProgramaticStat[0].cpm;
                                        return screen;
                                    }else
                                        return null;
                                })
                                this.setState({programmaticScreens: screensToShow});
                            }
                        })
                        .catch(err => console.log(err));
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

  
    /**
     * Function that create an object of type "customer" and fill it with the values given by props.
     * Then post it to DB
     * @param {Array} customerValues
     */
    addCustomer = (customerValues = []) =>{
        customerValues.forEach(v => {
            v.name = v.name.toLowerCase();
            v.name = v.name.replace(' ','_');
            v.name = v.name.replace(' ','');
            v.name = v.name.replace('%','');
        })
        let customer = {
            type: "customer"
        };
        customerValues.forEach(customerValue => {
            customer[customerValue.name] = customerValue.value;
        });
        customer.brands = [];
        addCustomer(customer)
        .then(r => {
            if(r.status === 201){
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('sections.management.customerCreated')
                });
                getCustomers().then(r => {
                    if(r.status === 200){
                        let customers = r.data;
                        this.setState({customers});
                    }
                })
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('sections.management.customerCreateErr')
                });
            }
        })
        .catch(err => {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('sections.management.serverErr')
            });
            console.log(err);
        })
    }
    /**
     * Function that create an object of type "brand" and fill it with the values given by props.
     * Then post it to DB
     * @param {Array} customerValues
     */
    addBrand = (brandValue = []) =>{
        brandValue.forEach(b => {
            b.name = b.name.toLowerCase();
            b.name = b.name.replace(' ','_');
            b.name = b.name.replace(' ','');
        })
        let brand = {
            type: "brand"
        };
        brandValue.forEach(value => {
            brand[value.name] = value.value;
        });
        addBrand(brand).then(r => {
            if (r.status === 201) {
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('sections.management.brandCreated')
                });
                getBrands().then(r => {
                    if(r.status === 200){
                        let brands = r.data;
                        this.setState({brands});
                    }
                })
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('sections.management.brandCreateErr')
                });
            }
        }).catch(err => {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('sections.management.serverErr')
            });
            console.log(err);
        })
    }
    /**
     * Function that set the property "type" of param object with value "user".
     * Then if there is no problem with mail format, post the user into DB.
     * @param {Object} user
     */
    // addUser = (user) => {
    //     user.type = "user";
    //     user.complete_name = user.first_name + " " + user.last_name;
    //     if(!validateMail(user.email)) {
    //         this.props.showNotification({
    //           text: this.props.t('sections.register.mailFormat'),
    //           type: "warning",
    //           duration: 3000
    //         });
    //         return;
    //     }
    //     addNewUser(user).then(r => {
    //         if (r.status === 201) {
    //             this.props.showNotification({
    //                 type: 'success',
    //                 text: this.props.t('common.notification.management.users.userCreated')
    //             });
    //             getUsers().then(r => {
    //                 if(r.status === 200){
    //                     let users = r.data;
    //                     this.setState({users});
    //                 }
    //             })
    //         } else if(r.status === 409){
    //             this.props.showNotification({
    //                 type: 'warning',
    //                 text: this.props.t('common.notification.management.users.existingUser')
    //             });
    //         }
    //         else {
    //             this.props.showNotification({
    //                 type: 'error',
    //                 text: this.props.t('common.notification.management.users.userCreateErr')
    //             });
    //         }
    //     }).catch(err=> {
    //         this.props.showNotification({
    //             type: 'error',
    //             text: this.props.t('sections.management.serverErr')
    //         });
    //         console.log(err);
    //     })

    // }
    /**
     * When the publisher_id changes, set the state of the publisher_id to the value of the target.
     * @param e - the event object
     */
    onChangePublisherId = (e) => {
        this.setState({publisher_id: e.target.value})
    }
    onChangeEmailAdresses = (e) => {
        let mails = e.target.value.replace(" ","").split(',');
        this.setState({emailsAdresses: mails})
    }
    /**
     * The function takes in a value and sets the state of the component to the value.
     * @param value - The value of the section that was clicked.
     */
    handleSections(value) {
        this.setState({ isActiveSection: value });
    }
    /**
     * When the user clicks on the Commercial Menu, the state of the Commercial Menu is set to true.
     * @param value - true or false
     */
    handleCommercialMenu(value) {
        this.setState({ isActiveCommercialMenu: value });
    }
    handleMailingMenu(value) {
        this.setState({ isActiveMailingMenu: value });
    }
    /**
     * This function sets the state of the modal to true or false depending on the value passed to it.
     * @param e - is the row number
     * @param value - true/false
     */
    toggleCommercialCustomersModal = (e,value) => {
        this.setState({editRow: e});

        this.setState({ isModalCommercialDealOpen: value});
    }
    /**
     * This function is called when a user clicks on a button to edit a row in a table. The function
     * sets the state of the component to the row that was clicked on, and then opens a modal window.
     * @param e - is the object that is being passed to the function
     * @param value - true/false
     */
    toggleCommercialScreen = (e, value) => {
        let filteredObj = {
            id: e._id,
            rev: e._rev,
            name: e.name,
            ad_value: e.adValue,
            currency: e.currency
        };
        this.setState({editRow: filteredObj});
        this.setState({ isModalCommercialDealOpen: value});
    }
    /**
     * It takes an event and a value, and sets the state of the component to the value of the event.
     * @param e - is the object that I'm passing to the function
     * @param value - true/false
     */
    toggleUserModal = (e, value) => {
        let filteredArr = {
            id: e._id,
            rev: e._rev,
            name: e.complete_name,
            email: e.email,
            phone: e.phone_number,
            role: e.userRol
        };
        this.setState({editRow: filteredArr})
        this.setState({ isModalCommercialDealOpen: value});
    }

    /**
     * The function takes a value as an argument and sets the state of the component to the value
     * passed in.
     * @param value - The value of the section that was clicked.
     */
    handleVisibility = (value) =>{
        this.setState({isActiveSection: value});
    }
    /**
     * "handleOnChangeCurrency" is a function that is called when a user selects a currency from a
     * dropdown menu.
     *
     * The function then calls another function called "setCurrencyOnUser" which is an API call that
     * sets the currency on the user.
     *
     * If the API call is successful, the user's currency is set.
     *
     * If the API call is unsuccessful, the user is notified.
     * @param e - the event object
     */
    handleOnChangeCurrency = (e) => {
        let currency = e.target.value;
        if(currency !== 0 ){
            setCurrencyOnUser(currency).then(r =>{
                if(r.status === 200){

                }else if(r.status === 409){
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.management.currencyConflict'),
                        duration : 3000
                    });
                }else{
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.serverError'),
                        duration : 3000
                    });
                }
            })
        }
    }
    /**
     * Function that update a customer document in a database, and then update the state of the
     * component with the new data.
     * @param e - the event object
     * @param brandId - the id of the brand
     */
    handleOnChangeBrands = (e, brandId) => {
        let customerID = e.value;
        let customers = [...this.state.customers];
        let customerToModify = customers.find(customer => customer._id === customerID);
        let customersToModify = [];
        if(customerID !== "-1"){
            let previousCustomer = customers.find(customer => {
                if(customer.brands && customer.brands.includes(brandId)){
                    return customer;
                }else{
                    return undefined;
                }
            });
            if(previousCustomer){
                previousCustomer.brands.forEach((brand,i) => {
                    if(brand === brandId){
                        previousCustomer.brands.splice(i,1);
                    }
                });
                customersToModify.push(previousCustomer);
            }
            if(customerToModify.brands){
                if(customerToModify.brands instanceof Array && !customerToModify.brands.includes(brandId)){
                    customerToModify.brands.push(brandId);
                }
            }else{
                let brandArr = [brandId];
                customerToModify.brands = brandArr;
            }
            if(customerToModify !== previousCustomer){
                customersToModify.push(customerToModify);
            }
            modifyCustomers(customersToModify).then(r => {
                if(r.status === 201){
                    customers.forEach(customer => {
                        r.data.forEach(doc => {
                            if(customer._id === doc.id){
                                customer._rev = doc.rev;
                            }
                        })
                    });
                }else{
                    console.log('err in .then',r);
                }
            })
            .catch(err => {
                   console.log(err);
            })
        }else{
            let customerWithBrand = customers.filter(customer => {
                if(customer.brands && customer.brands.includes(brandId)){
                    return customer;
                }else{
                    return undefined;
                }
            });
            if(customerWithBrand){
                customerWithBrand[0].brands.forEach((brand,i) => {
                    if(brand === brandId){
                        customerWithBrand[0].brands.splice(i,1);
                    }
                });
            }
            modifyCustomers(customerWithBrand).then(r => {
                if(r.status === 201){
                    customers.forEach(customer => {
                        r.data.forEach(doc => {
                            if(customer._id === doc.id){
                                customer._rev = doc.rev;
                            }
                        })
                    });
                }
            })
            .catch(err => {
                this.props.notifications({
                    type: 'error',
                        text: this.props.t('common.notification.management.customer.customerError'),
                        duration : 3000
                })
            })
        }
        getBrands().then(r => {
            this.setState({brands: []});
            if(r.status === 200){
                let brands = r.data;
                this.setState({brands});
            }
        })
    }
    /**
     * It takes an array of categories and a brand ID, then it loops through the brands in state, finds
     * the brand with the matching ID, and sets the categories of that brand to the categories passed
     * in.
     * @param e - the selected categories
     * @param id - the id of the brand
     */
    handleChangeBrandCategories = (e, id) => {
        let brands = [...this.state.brands];
        let brandID = id;
        let categories;
        if(e){
            categories = e;
        } else {
            categories = [];
        }
        brands.forEach(brand => {
            if(brand._id === brandID){
                brand.categories = categories;
                modifyBrand(brand).then(r => {
                    if (r.data.ok) {
                        brand._rev = r.data.rev;
                    }
                }).catch(err => console.log(err));
            }
        })
        this.setState({brands});
    }
    /**
     * Function that remove an item from a table, and then delete the item from DB.
     */
    removeItemFromTable = () => {
        let row = this.state.actualRow.row;
        removeItem(row._id).then(r => {
            if(r.status === 200){
                let stateToModify = "";
                if(this.state.actualRow.type === "customer"){
                    stateToModify = this.state.customers;
                }
                stateToModify.forEach((customer, i)=>{
                    if(customer._id === row._id){
                        stateToModify.splice(i,1)
                    }
                });
                if(this.state.actualRow.type === "customer"){
                    this.setState({customers:[]}, () => {
                        this.setState({customers: stateToModify}, () => {
                        })
                    })
                }
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('common.notification.management.removeItem.success'),
                    duration : 3000
                });
            }else{
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.removeItem.error'),
                    duration : 3000
                });
            }
        })
        .catch(err => console.log(err))
    }

    /**
     * It deletes a user from the database and then updates the state of the component with the new
     * list of users.
     * @param id - the id of the user to be deleted
     */
    // deleteUser = (id) => {
    //     deleteUserWithRole(id).then(r => {
    //         if(r.status === 200){
    //             this.setState({users: []});
    //             getUsers().then(r => {
    //                 if(r.status === 200){
    //                     let users = r.data;
    //                     users.forEach(user => {
    //                         user.complete_name = user.first_name + " " + user.last_name;
    //                     });
    //                     this.setState({users});
    //                     this.props.showNotification({
    //                         type: 'error',
    //                         text: this.props.t('common.notification.management.removeItem.success'),
    //                         duration : 3000
    //                     });
    //                  } else {
    //                     this.props.showNotification({
    //                         type: 'error',
    //                         text: this.props.t('common.notification.management.removeItem.error'),
    //                         duration : 3000
    //                     });
    //                  }
    //             }).catch(err => console.log(err));
    //         }
    //     })
    // }

    /**
     * It deletes a brand from the database and then updates the state of the component with the new
     * list of brands.
     * @param id - the id of the item to be deleted
     */
    deleteBrand = (id) => {
        deleteBrand(id).then(r => {
            if(r.status === 200){
                this.setState({brands: []});
                getBrands().then(r => {
                    if(r.status === 200){
                        let brands = r.data;
                        this.setState({brands});
                        this.props.showNotification({
                            type: 'success',
                            text: this.props.t('common.notification.management.removeItem.success'),
                            duration : 3000
                        });
                     } else {
                        this.props.showNotification({
                            type: 'error',
                            text: this.props.t('common.notification.management.removeItem.error'),
                            duration : 3000
                        });
                     }
                }).catch(err => console.log(err));
            }
        }).catch(err => console.log(err));
    }

    /**
     * It takes a screen object, finds the screen in the state, updates the screen object with the new
     * values, and then updates the state with the new screen object.
     * @param editedScreen - {
     */
    editComScreen = (editedScreen) => {
        let screens = [...this.state.screens];
        let edScreen = screens.find(screen => screen._id === editedScreen._id);
        edScreen.adValue = editedScreen.ad_value;
        edScreen.currency = editedScreen.currency;
        editTableManagement(edScreen)
        .then(r => {
            if (r.status === 200) {
                edScreen._rev = r.data.rev;
                this.setState({screens: []}, () => {
                    this.setState({screens});
                });
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('common.notification.management.commercial.pricing.screenEditSuccess'),
                    duration : 3000
                });
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.editError'),
                    duration : 3000
                });
            }
        })
        .catch(
            err => console.log(err)
        )
    }
    /**
     * It takes an object as a parameter, finds the object in the state array, and updates the object
     * in the state array with the new values.
     * @param editedCustomer - {
     */
    editCustomer = (editedCustomer) => {
        let customers = [...this.state.customers];
        let edCustomer = customers.find(customer => customer._id === editedCustomer._id);
        if(editedCustomer.company)
            edCustomer.company = editedCustomer.company;
        if(editedCustomer.email)
            edCustomer.email = editedCustomer.email;
        if(editedCustomer.phone)
            edCustomer.phone = editedCustomer.phone;
        if(editedCustomer.discount)
            edCustomer.discount = editedCustomer.discount;
        editTableManagement(edCustomer)
        .then(r => {
            if (r.status === 200) {
                edCustomer._rev = r.data.rev;

                this.setState({customers: []}, () => {
                    this.setState({customers});
                });
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('common.notification.management.commercial.customers.customerEditSuccess'),
                    duration : 3000
                });
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.editError'),
                    duration : 3000
                });
            }
        })
        .catch(
            err => console.log(err)
        )
    }
    /**
     * Function to edit a user in a table, but before I do that, I want to check if the email is
     * already in use. If it is, I want to show a notification. If it isn't, I want to edit the user.
     *
     * @param editedUser - An object that contains the values for the user to update
     */
    editUser = (editedUser) => {
        let users = [...this.state.users];
        let edUser = users.find(user => user._id === editedUser._id);
        verifyMail(editedUser.email).then( r => {
            if(r.status === 200) {
                this.props.showNotification({
                    type: 'warning',
                    text: this.props.t('common.notification.management.users.existingUser'),
                    duration : 3000
                });
                return;
            }
        }).catch( err => console.log(err))
        if(editedUser.email)
            edUser.email = editedUser.email;
        if(editedUser.phone_number)
            edUser.phone_number = editedUser.phone_number;
        edUser.userRol = editedUser.user_rol;
        edUser.company = editedUser.company;
        editTableManagement(edUser)
        .then(r => {
            if (r.status === 200) {
                edUser._rev = r.data.rev;

                this.setState({users: []}, () => {
                    this.setState({users});
                });
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('common.notification.management.users.userEditSuccess'),
                    duration : 3000
                });
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.editError'),
                    duration : 3000
                });
            }
        })
        .catch(
            err => console.log(err)
        )
    }
    /**
     * It takes an array of objects, and creates a new array of objects with only two properties from
     * the original array.
     */
    filterCategories = () => {
        let filtered_categories = [];
        iabCategoriesEN.forEach(cat => {
            let filt_cat = {};
            filt_cat.label = cat.Name;
            filt_cat.value = cat.UniqueID;
            filtered_categories.push(filt_cat);
        })
        this.setState({iab_categories: filtered_categories});
    }
    /**
     * It takes an array of objects, and returns an array of objects with the same keys, but with
     * different values.
     * @returns An array of objects.
     */
    filteredCustomers = () => {
        let filtered_customers = [];
        this.state.customers.forEach(customer => {
            let filt_customer = {};
            filt_customer.label = `${customer.company} - ${customer.contact_name}`;
            filt_customer.value = customer._id;
            filtered_customers.push(filt_customer);
        })
        return filtered_customers;
    }
    /**
     * It takes an id, finds the screen with that id, toggles the programmatic_view property, and then
     * updates the database with the new value.
     * @param id - the id of the screen
     */
    handleProgrammatic = (id) => {
        let screens = cloneDeep(this.state.screens);
        let screen = screens.find(screen => screen._id === id);
        screen.programmatic_view = !screen.programmatic_view;
        setProgrammatic(screen).then(r => {
            if(r.status === 200){
                screen._rev = r.data.rev;
                this.setState({screens: []}, () => {
                    this.setState({screens}, () => {
                        this.props.showNotification({
                            type: 'success',
                            text: this.props.t('common.notification.management.switchChanged'),
                            duration : 3000
                        });
                    });
                });
            }else{
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.editError'),
                    duration : 3000
                })
            }
        })
        .catch(err => console.log(err));
    }
    /**
     * Function that update de Publisher ID for the logged user.
     */
    setPublisherID = () => {
        let publisher_id = this.state.publisher_id;
        if(publisher_id !== ""){
            setPublisherID(publisher_id).then(r => {
                if(r.status === 200){
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.programmatic.setedPublisherID'),
                        duration : 3000
                    });
                }else{
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.serverError'),
                        duration : 3000
                    });
                }
            }).catch( err => console.log(err));
        }
    }
    setPeriodicityMail = async () => {
        let response = {};
        let error = null;
        try {
            response = await setMailing({mails: this.state.emailsAdresses, periodicity: this.state.periodicityMail});
        } catch (err) {
            error = err;
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.serverError'),
                duration : 3000
            });
        } finally {
            if(error === null){
                if(response){
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.mailing.mailSeted'),
                        duration : 3000
                    });
                }
            }
        }
    }
    /**
     * When the button is clicked, toggle the state of toggleClearRows to the opposite of what it
     * currently is.
     */
    toggleClearRows = () => {
        this.setState({toggleClearRows: !this.state.toggleClearRows})
    }
    /**
     * It takes a brandID as a parameter, and returns the customerID of the customer that has that
     * brandID in their brands array.
     * @param brandID - the brand ID that we want to find the customer for
     * @returns The customer ID of the customer that has the brand ID.
     */
    getCustomerWithBrand = (brandID) => {
        let customers = [...this.state.customers];
        let customerToReturn = customers.find(customer => customer.brands.includes(brandID));
        if(customerToReturn){
            return customerToReturn._id;
        }
        return -1;
    }
    /**
     * It takes a JSON object as a parameter, and if the email is valid, it sends a POST request to the
     * server, and if the server returns a 201 status code, it shows a success notification, if it
     * returns a 409 status code, it shows a warning notification, and if it returns any other status
     * code, it shows an error notification.
     * @param values - JSON object with values for the user fields.
     */
    registerOtherRol = (values) => {
        let userData = {
            type : "user",
            first_name : values.first_name,
            last_name : values.last_name,
            passsword : values.passsword,
            email : values.email,
            country : values.country,
            phone_number : values.phone_number,
            company : values.company,
            user_rol : values.user_rol,
        };
        if(!validateMail(userData.email)) {
            this.props.showNotification({
              text: this.props.t('sections.register.mailFormat'),
              type: "warning",
              duration: 3000
            });
            return;
        }
        register(userData).then(res => {
            if (res.status === 201) {
              this.props.showNotification({
                text : this.props.t('sections.register.success'),
                type : "success",
                duration: 3000
              })
            }
            else if (res.status === 409){
              this.props.showNotification({
                text: this.props.t('sections.register.userAlreadyExists'),
                type: "warning",
                duration: 3000
              })
            }
            else {
              this.props.showNotification({
                text: this.props.t('common.notification.serverError'),
                type: "error",
                duration: 3000
              })
            }
          })
          .catch(err => {
            console.log('catch del register', err);
            this.props.showNotification({
              text: this.props.t('common.notification.serverError'),
              type: "error",
              duration: 3000
            })
          })
    }
    /**
     * It returns a span with a tooltip.
     * @param badge - The text to display in the badge
     * @param tooltip - The text that will be displayed in the tooltip
     * @param id - The id of the item
     * @returns A JSX component.
     */
    itemNeedTooltip = (badge, tooltip, id) => {
        return (
            <>
                <ReactTooltip effect="solid" className="p-2" id={"contentOutOfDateTooltip"+id} aria-haspopup='true'>
                    <p className="text-center max-w-sm">
                        {tooltip}
                    </p>
                </ReactTooltip>
                <span data-tip data-for={"contentOutOfDateTooltip"+id} className="ml-2 p-1 rounded font-semibold">{badge}</span>
            </>
        );
    }
    /**
     * Creates an array that has the options of the periodicity select.
     * @returns an array that contains objects with label, value.
     */
    getPeriodicityOptions = () => {
        let options = [10,15,20,30,60,120];
        return options.map(option => {
            return {
                label : option ,
                value : option
            };
        });
    }
    /**
     * Handle the state change periodicityMail.
     * @param {*} e event prop.
     */
    handlePeriodicityOnMailContact = (e) => {
        this.setState({periodicityMail: e.value});
    }
    /** Get the emails from contact on the state and accumulate them into a string.
     *
     * @returns all the mails accumulated.
     */
    parseEmailsAdresses = () => {
        let emailsAdresses = cloneDeep(this.state.emailsAdresses);
        if(emailsAdresses.length === 0){
            return "";
        };
        return emailsAdresses.reduce((acc,val) => {
            acc+=","+val;
            return acc;
        });
    }

    render() {
        const isActiveSection = this.state.isActiveSection;
        const isActiveCommercialMenu = this.state.isActiveCommercialMenu;
        const isActiveMailingMenu = this.state.isActiveMailingMenu;
        const defaultOptionSelectPeriodicity = this.getPeriodicityOptions().find(option => option.value === this.state.periodicityMail);
        const customFilter = createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: 'any',
            trim: true
        });
        const filteredCustomers = this.filteredCustomers();
        const customPeriodicityOption = props => {
            return (
                <components.Option {...props}>
                    {props.children}<span className='ml-2'>{this.props.t('sections.management.section.mailing.contactMail.selectOptionDecorator')}</span>
                </components.Option>
            );
        };
        const singleValue = ({children, ...props}) => <components.SingleValue {...props}>{children} {this.props.t('sections.management.section.mailing.contactMail.selectOptionDecorator')}</components.SingleValue>;
        return(
            <>
            <ManagementHeader />
            <div className="relative w-full h-screen overflow-hidden flex py-2">
                <div className="w-full bg-opacity-30 bg-white p-2 overflow-y-auto">
                    <div className="flex flex-col md:flex-row space-y-2 justify-between">
                        <div>
                            <nav className="flex flex-nowrap overflow-x-auto items-center">
                                <button id="sectionFinance"  onClick={() => this.handleSections("finance")}  className={`${isActiveSection === "finance" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t('sections.management.section.finance.tab')}
                                </button>
                                <button id="sectionCommercial" onClick={() => this.handleSections("commercial")}  className={`${isActiveSection === "commercial" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t('sections.management.section.commercial.tab')}
                                </button>
                                {/* <button id="sectionUsers"  onClick={() => this.handleSections("users")}  className={`${isActiveSection === "users" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t('sections.management.section.users.tab')}
                                </button> */}
                                <button id="sectionProgrammatic"  onClick={() => this.handleSections("programmatic")}  className={`${isActiveSection === "programmatic" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t('sections.management.section.programmatic.tab')}
                                    {/* PubID + CPMs + enable screen */}
                                </button>
                                <button id="sectionPlayers"  onClick={() => this.handleSections("players")}  className={`${isActiveSection === "players" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t("sections.management.section.players.tab")}
                                </button>
                                <button id="sectionPlayers"  onClick={() => this.handleSections("mailing")}  className={`${isActiveSection === "mailing" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                                    {this.props.t("sections.management.section.mailing.tab")}
                                </button>
                            </nav>
                        </div>
                    </div>
                    <div className={`${isActiveSection === "finance" ? "block" : "hidden" } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            <FontAwesomeIcon icon={["fal", "coin"]} fixedWidth className="text-blue-500" /> {this.props.t('sections.management.section.finance.currencyTitle')}
                            <p className="font-light">{this.props.t('sections.management.section.finance.currencyTxt')}</p>
                        </label>
                        <div className="my-2 md:w-2/6">
                            <div className="flex">
                                <div className="relative flex-grow mr-5">
                                    <select onChange={this.handleOnChangeCurrency} tabIndex="2" className="block text-gray-700 w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                        <option value="-1">{this.props.t('sections.management.section.finance.currencySelect')}</option>
                                        {this.state.currency.map((curr, i) => (
                                            (this.state.actualCurrency === curr.ISO
                                            ?  <option selected value={curr.ISO} key={i}>{curr.symbol} {curr.ISO} - {curr.name}</option>
                                            :  <option value={curr.ISO} key={i}>{curr.symbol} {curr.ISO} - {curr.name}</option>
                                            )
                                        ))}
                                    </select>
                                    {/* <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isActiveSection === "commercial" ? "block" : "hidden" } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <div className="flex divide-x divide-dotted">
                            <div className="w-20 md:w-44">
                                <nav className="flex flex-col">
                                    <button id="commercialSectionPricing"  onClick={() => this.handleCommercialMenu("pricing")}  className={`${isActiveCommercialMenu === "pricing" ? 'tabActiveV font-semibold' : ''} tabGeneral uppercase text-xs text-right`}>
                                        <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.pricing.tab')}</span> <FontAwesomeIcon icon={["fal", "wallet"]} className="text-blue-500" fixedWidth />
                                    </button>
                                    <button id="commercialSectionCustomers" onClick={() => this.handleCommercialMenu("customers")}  className={`${isActiveCommercialMenu === "customers" ? 'tabActiveV font-semibold truncate' : ''} tabGeneral uppercase text-xs text-right`}>
                                        <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.customers.tab')}</span> <FontAwesomeIcon icon={["fal", "user-friends"]} className="text-blue-500" fixedWidth />
                                    </button>
                                    <button id="commercialSectionBrands"  onClick={() => this.handleCommercialMenu("brands")}  className={`${isActiveCommercialMenu === "brands" ? 'tabActiveV font-semibold' : ''} tabGeneral uppercase text-xs text-right`}>
                                        <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.brands.tab')}</span>  <FontAwesomeIcon icon={["fal", "tags"]} className="text-blue-500" fixedWidth />
                                    </button>
                                </nav>
                            </div>
                            <div className="px-4 w-full md:w-10/12 pb-10">
                                <div className={`${isActiveCommercialMenu === "pricing" ? "block" : "hidden" } w-full`}>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        {this.props.t('sections.management.section.commercial.pricing.priceTitle')}
                                        <p className="font-light">{this.props.t('sections.management.section.commercial.pricing.priceTxt')}</p>
                                            <p>{this.props.t('sections.management.section.commercial.pricing.priceTable.screensPricePer')}</p>
                                        <div className="mx-2 px-2 w-24">  
                                            <ButtonSwitcher handleVisibility={()=>{this.setCommercializeAllScreensBy(this.getCommercializeString())}} status={this.getComercializeStatus(true)} labelOn={this.props.t('sections.management.section.commercial.pricing.priceTable.second')} labelOff={this.props.t('sections.management.section.commercial.pricing.priceTable.impression')} revertLabel={true}className="absolute left-0" />
                                        </div>
                                    </label>
                                    <div className="w-full overflow-y-auto max-h-96">
                                  
                                        {/* NOTA: Les dejo una tabla que podría servir para editar ahi mismo: https://github.com/glideapps/glide-data-grid */}
                                        <DataTable
                                            id="dataTable"
                                            className="w-full m-0 p-0"
                                            responsive={true}
                                            dense={true}
                                            columns={this.commercialScreenPricing}
                                            noHeader={true}
                                            data={
                                                this.state.screens
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={`${isActiveCommercialMenu === "customers" ? "block" : "hidden" }`}>
                                    <div className="flex justify-between">
                                        <div>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                {this.props.t('sections.management.section.commercial.customers.customerTitle')}
                                                <p className="font-light">{this.props.t('sections.management.section.commercial.customers.customerTxt')}</p>
                                            </label>
                                        </div>
                                        <div>
                                            <button className="group buttonNew" onClick={(e) => this.props.openModal("createCustomerModal")}>
                                                {/* Abrir modal para dar de alta nuevo cliente
                                                    - Nombre de Empresa
                                                    - Nombre de Contacto Ppal.
                                                    - Mail
                                                    - Telefono
                                                    - Porcentaje de Descuento.
                                                 */}
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" onClick={(e) => this.props.openModal("createCustomerModal")}/>
                                                <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light" onClick={(e) => this.props.openModal("createCustomerModal")}>{this.props.t('sections.management.section.commercial.customers.createButton')}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full relative overflow-y-auto max-h-96">
                                        {/* <div className={`${isModalCommercialDealOpen === true ? 'block' : 'hidden'} bg-white rounded-lg shadow-lg absolute top-5 left-5 p-4 z-20`}>
                                            Abro Modal
                                        </div> */}
                                        {/* NOTA: Les dejo una tabla que podría servir para editar ahi mismo: https://github.com/glideapps/glide-data-grid */}
                                        <DataTable
                                            className="w-full"
                                            responsive={true}
                                            dense={true}
                                            keyField="_id"
                                            noDataComponent={false}
                                            columns={this.commercialCustomersList}
                                            noHeader={true}
                                            data={
                                                this.state.customers
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={`${isActiveCommercialMenu === "brands" ? "block" : "hidden" } overflow-y-auto`}>
                                    <div className="flex justify-between">
                                        <div>
                                            {/* Una marca debe estar asociada a un customer, por ende, por cada marca, agregaremos un dropdown para seleccionar Customer */}
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                {this.props.t('sections.management.section.commercial.brands.brandTitle')}
                                                <p className="font-light">{this.props.t('sections.management.section.commercial.brands.brandTxt')}</p>
                                            </label>
                                        </div>
                                        <div>
                                            <button className="group buttonNew" onClick={(e) => this.props.openModal("createBrandModal")}>
                                                {/* Abrir modal para dar de alta Nueva Brand
                                                    - Nombre de Marca
                                                    - DropDown de Customer
                                                 */}
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" onClick={(e) => this.props.openModal("createBrandModal")}/>
                                                <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light" onClick={(e) => this.props.openModal("createBrandModal")}>{this.props.t('sections.management.section.commercial.brands.createButton')}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full overflow-y-auto h-full pb-10">
                                        <ul className="divide-y divide-dotted space-y-2">
                                            {this.state.brands.map(brand => {
                                                return <li className="block text-gray-700 text-sm font-bold py-2">
                                                    <div className="flex justify-between">
                                                        <p>{brand.name}</p>
                                                        <button className="mr-2" onClick={() => {
                                                            this.setState({selectedBrand: brand}, () => {
                                                                this.props.openModal("removeBrand");
                                                            })
                                                        }}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" /></button>
                                                    </div>
                                                    <div className="mt-1 ml-2">
                                                        <Select
                                                            filterOptions={customFilter}
                                                            className="w-11/12"
                                                            name={'brandCustomerSelect'+brand._id}
                                                            onChange={(e) => this.handleOnChangeBrands(e, brand._id)}
                                                            options={filteredCustomers}
                                                            value={filteredCustomers.find(customer => customer.value === this.getCustomerWithBrand(brand._id))}
                                                        />
                                                    </div>
                                                    <div className="mt-1 ml-2">
                                                        <p className="text-gray-400">{this.props.t('sections.management.section.commercial.brands.brandCategoryPlaceholder')}</p>
                                                        <Select
                                                            filterOptions={customFilter}
                                                            className="w-11/12"
                                                            name={brand.id}
                                                            isMulti
                                                            onChange={(e) => this.handleChangeBrandCategories(e, brand._id)}
                                                            options={this.state.iab_categories}
                                                            value={brand.categories}
                                                        />
                                                    </div>
                                                    {/* <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div> */}
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   {/*  <div className={`${isActiveSection === "users" ? "block" : "hidden" } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <div className="flex justify-between">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fal", "user-cog"]} fixedWidth className="text-blue-500" /> {this.props.t('sections.management.section.users.title')}
                                    <p className="font-light">{this.props.t('sections.management.section.users.usersTxt')}</p>
                                </label>
                            </div>
                            <div>
                                <button className="group buttonNew" onClick={(e) => this.props.openModal("createUserModal")}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" onClick={(e) => this.props.openModal("createUserModal")}/>
                                    <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light" onClick={(e) => this.props.openModal("createUserModal")}>{this.props.t('sections.management.section.users.createButton')}</span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full">
                            <DataTable
                                className="w-full"
                                responsive={true}
                                dense={true}
                                columns={this.usersUsersList}
                                noHeader={true}
                                data={
                                    this.state.users
                                }
                            />
                        </div>
                    </div> */}
                    <div className={`${isActiveSection === "programmatic" ? "block" : "hidden"} bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <div className="flex flex-col md:flex-row divide-x divide-dotted">
                            <div className="md:w-6/12 p-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fal", "fingerprint"]} fixedWidth className="text-blue-500" /> {this.props.t('sections.management.section.programmatic.title')}
                                    <p className="font-light">{this.props.t('sections.management.section.programmatic.programmaticTxt')}</p>
                                </label>
                                <div className="my-2 md:w-4/6">
                                    <div className="relative flex flex-grow">
                                        <input type="text"
                                            id="publisherID"
                                            value={this.state.publisher_id}
                                            onChange={this.onChangePublisherId}
                                            tabIndex="1"
                                            className="shadow appearance-none border rounded w-full my-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                            placeholder="SSP Publisher ID" />
                                        <button className="buttonPrimary ml-10" onClick={(e) => this.setPublisherID()}>
                                            {this.props.t('sections.management.section.programmatic.setPublisherID')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-6/12 p-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fal", "coins"]} fixedWidth className="text-blue-500" /> {this.props.t('sections.management.section.programmatic.programmaticList.title')}  {this.itemNeedTooltip(<FontAwesomeIcon icon={["fas", "info-circle"]} className="text-black-500 text-sm" />, this.props.t('sections.management.section.programmatic.programmaticList.programmaticTable.noSSPIDTooltip'), 0)}
                                    <p className="font-light">{this.props.t('sections.management.section.programmatic.programmaticList.listTxt')}</p>
                                </label>
                                <div className="flex-grow overflow-auto h-40">
                                    <table className="relative w-full">
                                        <thead>
                                            <tr>
                                                <th className="sticky z-20 text-left rounded-tl-lg bg-gray-200 top-0 px-1 md:px-3 py-1">{this.props.t('sections.management.section.programmatic.programmaticList.programmaticTable.name')}</th>
                                                <th className="sticky z-20 bg-gray-200 top-0 px-1 md:px-3 py-1 text-right">{this.props.t('sections.management.section.programmatic.programmaticList.programmaticTable.cpm')}</th>
                                                <th className="sticky z-20 text-right rounded-tr-lg bg-gray-200 top-0 px-1 md:px-3 py-1">{this.props.t('sections.management.section.programmatic.programmaticList.programmaticTable.active')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-dotted odd:bg-gray-100 overflow-y-auto max-h-96">
                                            {this.state.programmaticScreens.map(screen => {
                                                return  <tr className="hover:bg-gray-50 transition-colors duration-100">
                                                            <td className="px-1 md:px-3 font-light text-sm py-2">{screen.name}{this.itemAndProgrammaticHasEqualDimension(screen) ? '' : this.itemNeedTooltip(<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-500 text-sm" />, this.props.t('sections.management.section.programmatic.programmaticList.programmaticTable.diffSize'), screen._id)}</td>
                                                            <td className="px-1 md:px-3 font-light text-sm py-2 text-right">$ {screen.cpm}</td>
                                                            <td className="px-1 md:px-3 font-light text-sm py-2 text-right overflow-ellipsis md:w-44"><ButtonSwitcher handleVisibility={() => this.handleProgrammatic(screen._id)} status={screen.programmatic_view}  revertLabel={true} /></td>
                                                        </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isActiveSection === "players" ? "block" : "hidden"} bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <div className="md:w-6/12 p-4 flex content-center">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                <FontAwesomeIcon icon={["fal", "bring-forward"]} fixedWidth className="text-blue-500" /> {this.props.t("sections.management.section.players.title")} <span className='capitalize'>{window.Config.REACT_APP_WL_NAME.toString()}</span>
                                <p className="font-light">{this.props.t("sections.management.section.players.playersTxt")}</p>
                            </label>
                        </div>
                        <div className="flex flex-wrap flex-col">
                            <div className="p-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fab", "windows"]} fixedWidth className="text-blue-500 text-base" /> {this.props.t("sections.management.section.players.windows.title")}:
                                </label>
                                <div className="flex h-40">
                                    <table className="relative w-full">
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="sticky z-20 text-left rounded-t-lg bg-gray-200 top-0 px-1 md:px-3 py-1">{this.props.t("sections.management.section.players.windows.tableTitle")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-dotted odd:bg-gray-100">
                                            <tr className="hover:bg-gray-50 transition-colors duration-100 my-2">
                                                <td className="px-1 md:px-3 font-semibold text-sm py-2 capitalize">{window.Config.REACT_APP_WL_NAME.toString()} Player 2.1.0</td>
                                                <td>
                                                    {window.Config.REACT_APP_WL_NAME.toString() === "taggify" ?
                                                        <a href="https://ds-static.taggify.net/Taggify-Player%20Setup%202.1.0.exe" rel="noreferrer">
                                                            <button className="bg-gray-100 hover:bg-gray-400 text-gray-800 hover:text-white font-semibold py-2 px-4 m-2 mr-2 float-right rounded inline-flex items-center">
                                                                <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                                                            </button>
                                                        </a>
                                                    :
                                                        <a href="https://ds-static.bebotdigital.com/Bebot-Player%20Setup%202.1.0.exe" rel="noreferrer">
                                                            <button className="bg-gray-100 hover:bg-gray-400 text-gray-800 hover:text-white font-semibold py-2 px-4 m-2 mr-2 float-right rounded inline-flex items-center">
                                                                <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                                                            </button>
                                                        </a>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="p-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fab", "apple"]} fixedWidth className="text-blue-500 text-base" /> {this.props.t("sections.management.section.players.mac.title")}:
                                </label>
                                <div className="flex h-40">
                                    <table className="relative w-full">
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="sticky z-20 text-left rounded-t-lg bg-gray-200 top-0 px-1 md:px-3 py-1">{this.props.t("sections.management.section.players.mac.tableTitle")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-dotted odd:bg-gray-100">
                                            <tr className="hover:bg-gray-50 transition-colors duration-100 my-2">
                                                <td className="px-1 md:px-3 font-light text-sm py-2">{this.props.t("sections.management.section.players.noPlayer")}...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className=" p-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon icon={["fab", "linux"]} fixedWidth className="text-blue-500 text-base" /> {this.props.t("sections.management.section.players.linux.title")}:
                                </label>
                                <div className="flex h-40">
                                    <table className="relative w-full">
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="sticky z-20 text-left rounded-tl-lg bg-gray-200 top-0 px-1 md:px-3 py-1">{this.props.t("sections.management.section.players.linux.tableTitle")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-dotted odd:bg-gray-100">
                                            <tr className="hover:bg-gray-50 transition-colors duration-100 my-2">
                                                <td className="px-1 md:px-3 font-semibold text-sm py-2 capitalize">{window.Config.REACT_APP_WL_NAME.toString()} Player 2.1.0</td>
                                                <td>
                                                    {window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? 
                                                        <a href="https://ds-static.taggify.net/Taggify-Player-Linux-2.1.0.AppImage" rel="noreferrer">
                                                            <button className="bg-gray-100 hover:bg-gray-400 text-gray-800 hover:text-white font-semibold py-2 px-4 m-2 mr-2 float-right rounded inline-flex items-center">
                                                                <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                                                            </button>
                                                        </a>
                                                    :
                                                        <a href="https://ds-static.bebotdigital.com/Bebot-Player-Linux-2.1.0.AppImage" rel="noreferrer">
                                                            <button className="bg-gray-100 hover:bg-gray-400 text-gray-800 hover:text-white font-semibold py-2 px-4 m-2 mr-2 float-right rounded inline-flex items-center">
                                                                <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                                                            </button>
                                                        </a>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isActiveSection === "mailing" ? "block" : "hidden" } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                        <div className="flex divide-x divide-dotted">
                            <div className="w-20 md:w-44">
                                <nav className="flex flex-col">
                                    <button id="commercialSectionPricing"  onClick={() => this.handleMailingMenu("contactMail")}  className={`${isActiveCommercialMenu === "pricing" ? 'tabActiveV font-semibold' : ''} tabGeneral uppercase text-xs text-right`}>
                                        <span className="hidden md:inline-block">{this.props.t('sections.management.section.mailing.contactMail.tab')}</span> <FontAwesomeIcon icon={["fa", "at"]} className="text-blue-500" fixedWidth />
                                    </button>
                                </nav>
                            </div>
                            <div className="px-4 w-full md:w-10/12 pb-10">
                                <div className={`${isActiveMailingMenu === "contactMail" ? "block" : "hidden" } w-full`}>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        {this.props.t('sections.management.section.mailing.title')}
                                        <p className="font-light">{this.props.t('sections.management.section.mailing.contactMail.description')}</p>
                                    </label>
                                </div>
                                <div className='flex flex-col w-1/3'>
                                    <div className="w-full my-2">
                                        <input type="text"
                                            id="contactMailInput"
                                            value={this.parseEmailsAdresses()}
                                            onChange={this.onChangeEmailAdresses}
                                            tabIndex="1"
                                            className="shadow appearance-none border rounded w-full my-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                            placeholder={this.props.t('sections.management.section.mailing.contactMail.placeholder')} />
                                    </div>
                                    <div className="w-full my-2">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        <p className="font-light">{this.props.t('sections.management.section.mailing.contactMail.periodicity')}</p>
                                    </label>
                                        <Select
                                            className="w-full"
                                            onChange={this.handlePeriodicityOnMailContact}
                                            value={defaultOptionSelectPeriodicity}
                                            options={this.getPeriodicityOptions() || {}}
                                            components={{ Option: customPeriodicityOption, SingleValue: singleValue }}
                                            />
                                    </div>
                                <button className="buttonPrimary ml-10" onClick={(e) => this.setPeriodicityMail()}>
                                    {this.props.t('sections.management.section.mailing.contactMail.button')}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DecisionModal modalID="removeRow" callbackFunction={this.removeItemFromTable} buttonText="Aceptar" text="Desea borrar?"></DecisionModal>
            <DecisionModal modalID="removeBrand" callbackFunction={this.deleteBrand} idParameter={this.state.selectedBrand?._id} buttonText="Aceptar" text={`Desea borrar la marca ${this.state.selectedBrand?.name}?`}></DecisionModal>
            {/* <DecisionModal modalID="removeUser" callbackFunction={this.deleteUser} idParameter={this.state.rowToDelete?._id} buttonText="Aceptar" text={`Desea borrar la marca ${this.state.rowToDelete?.complete_name}?`}></DecisionModal> */}
            <EditComScreenModal modalID="editComScreenModal" values={this.state.editRow} callbackFunction={this.editComScreen}></EditComScreenModal>
            <EditCustomerModal modalID="editCustomerModal" values={this.state.editRow} callbackFunction={this.editCustomer}></EditCustomerModal>
            <EditUserModal modalID="editUserModal" values={this.state.editRow} callbackFunction={this.editUser} type="EditUser"></EditUserModal>
            <CreateModal modalID="createCustomerModal" type="Customer" values={this.customersFields} callbackFunction={this.addCustomer} showNotification={this.props.showNotification}></CreateModal>
            <CreateModal modalID="createBrandModal" type="Brand" values={this.brandValue} callbackFunction={this.addBrand} showNotification={this.props.showNotification} select_values={this.state.iab_categories}></CreateModal>
            {/* <CreateUserModal modalID="createUserModal" type="User" values={this.usersFields} callbackFunction={this.addUser} showNotification={this.props.showNotification}></CreateUserModal> */}
            </>
        )
    }
}

export default withTranslation()(Management);
