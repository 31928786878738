import './style.css';
import React, { Component } from 'react'
import { getScreens } from '../map/MapFunctions';
import { getBrands, getCustomers, getScreenGroups } from '../utils/CommonRequests';
import axios from 'axios';
import * as dotenv from 'dotenv';
import { checkLimitExceeded } from '../content/contentAJAXFunctions';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadFiles } from '../utils/StorageFunctions';
import iabCatEN from '../../util_files/iab_categories_en.json';
import cloneDeep from 'lodash.clonedeep';
import GroupedSelect from '../utils/GroupedSelect/GroupedSelect';
import CustomScreenOption from '../utils/GroupedSelect/CustomComponents/CustomScreenOption'
import Select, {components} from 'react-select';
import ButtonSwitcher from '../buttons/ButtonSwitcher';
// import HTML5IMG from '../../images/html.png';
// import MPGFILEIMG from '../../images/mpg-file.png';

dotenv.config();
class FileDropper extends Component {
    constructor() {
        super()
        this.state = {
            screens: [],
            extraData: [],
            selectedCategories: [],
            selectedScreens: [],
            brands: [],
            customers: [],
            selectedBrandId: '',
            selectedCustomerId: '',
            isZipFile : false,
            isScreenSelectLoading: true,
            contentHasDailyLimitRepetitions:false,
            daily_limit:0,
            endDate:true
        }
    }

    eventAdd() { //Modal
        var openmodal = document.querySelectorAll('.modal-open')
        for (var i = 0; i < openmodal.length; i++) {
            openmodal[i].addEventListener('click', function (event) {
                event.preventDefault()
                this.toggleModal();
            })
        }

        const overlay = document.querySelector('.modal-overlay')
        overlay.addEventListener('click', this.toggleModal)

        var closemodal = document.querySelectorAll('.modal-close')
        for (let i = 0; i < closemodal.length; i++) {
            closemodal[i].addEventListener('click', this.toggleModal)
        }
    }

    componentDidMount() {
        const dropContainer = document.querySelector('.file-drop-target');
        dropContainer.ondragover = dropContainer.ondragenter = function (evt) {
            evt.preventDefault();
        };

        getScreens()
            .then(screens => {
                // this.setState({ screens: screens.data });
                getScreenGroups()
                .then(groups => {
                    let optionsScreens = [
                        {
                            label: this.props.t('sections.wizard.stepOne.group-label'),
                            value: 1,
                            options: this.parseGroupedOptions(groups.data)
                        },
                        {
                            label: this.props.t('sections.content.screen'),
                            value: 2,
                            options: this.parseGroupedOptions(screens.data)
                        }
                    ]
                    this.setState({
                        screenOptions: optionsScreens,
                        defaultScreenOptions: optionsScreens,
                        screens: screens.data,
                        groups: groups.data,
                        isScreenSelectLoading: false
                    });
                })
                .catch(err => console.log(err)) // Fragmento de codigo utilizado para implementacion de select de grupos y pantallas
            })
            .catch(err => console.log(err))


        document.addEventListener('keydown', (e) => {
            e = e || window.event
            var isEscape = false
            if ("key" in e) {
                isEscape = (e.key === "Escape" || e.key === "Esc")
            } else {
                isEscape = (e.keyCode === 27)
            }
            if (isEscape)
                this.props.closeModal();
        }, { once: true });

        const arrayFiles = this.props.files;
        const div_preview = document.querySelector('#div_img_preview');
        const img_ext = /(jpg|jpeg|png|gif)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de img.
        const video_ext = /(mp4|ogg|webm)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de video.
        const zip_ext = /(x-zip-compressed)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de zip.
        const arrayOfZipExt = ["rar","zip"];
        const arrayExtToConvert = ["mpg","mpg-2","mpg-3","mpg-4"];
        const div_loading = document.createElement('div');
        const text_loading = document.createTextNode(this.props.t('common.buttons.loading') + "...");
        div_loading.appendChild(text_loading);
        div_preview.innerHTML = "";
        div_preview.appendChild(div_loading);
        for (let i = 0; i < arrayFiles.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(arrayFiles[i]);
            let typeArray = arrayFiles[i].type.split("/")
            if (img_ext.test(typeArray)) {
                let img_preview = document.createElement('img');
                img_preview.alt = 'Image Preview';
                img_preview.width = 200;
                img_preview.height = 200;
                img_preview.className = ["rounded object-fill m-1"];
                img_preview.src = URL.createObjectURL(arrayFiles[i]);
                div_preview.appendChild(img_preview);
                let new_img = new Image();
                new_img.src = img_preview.src;
                new_img.onload = () => {
                    let img_data = {
                        name: arrayFiles[i].name.toString(),
                        width: new_img.width,
                        height: new_img.height
                    };

                    let extraData = [...this.state.extraData];
                    extraData.push(img_data);
                    this.setState({ extraData });
                }
            }
            else if(zip_ext.test(typeArray) || arrayOfZipExt.includes(arrayFiles[i].name.split(".")[1])){
                let img_preview = document.createElement('img');
                img_preview.alt = 'HTML Preview';
                img_preview.width = 200;
                img_preview.height = 200;
                img_preview.className = ["rounded object-fill m-1"];
                img_preview.src = require(`../../images/common/html.png`);
                div_preview.appendChild(img_preview);
            }
            else if(arrayExtToConvert.includes(arrayFiles[i].name.split(".")[1])){
                let img_preview = document.createElement('img');
                img_preview.alt = 'MPG Preview';
                img_preview.width = 200;
                img_preview.height = 200;
                img_preview.className = ["rounded object-fill m-1"];
                img_preview.src = require(`../../images/common/mpg-file.png`);
                div_preview.appendChild(img_preview);
            }
            else if(video_ext.test(typeArray)) {
                let video_preview = document.createElement('video');
                video_preview.alt = 'Video Preview';
                video_preview.width = 400;
                video_preview.height = 400;
                video_preview.className = ["rounded object-fill m-1"];
                /* reader.onload = () => {
                    let new_video = document.createElement('video');
                    new_video.src = reader.result;
                    new_video.onloadedmetadata = () => {
                        let vid_data = {
                            name: arrayFiles[i].name,
                            width: new_video.videoWidth,
                            height: new_video.videoHeight
                        };
                        let extraData = [...this.state.extraData];
                        extraData.push(vid_data);
                        this.setState({ extraData })
                    }
                } */
                video_preview.muted = true;
                video_preview.src = URL.createObjectURL(arrayFiles[i]);
                div_preview.appendChild(video_preview);
                let new_video = document.createElement('video');
                new_video.src = video_preview.src;
                new_video.onloadedmetadata = () => {
                    let vid_data = {
                        name: arrayFiles[i].name,
                        width: new_video.videoWidth,
                        height: new_video.videoHeight
                    };
                    let extraData = [...this.state.extraData];
                    extraData.push(vid_data);
                    this.setState({ extraData })
                }
            }
            else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.fileDropper.error.file_type')
                })
                let file_not_allowed_preview = document.createElement('img');
                file_not_allowed_preview.width = 200;
                file_not_allowed_preview.height = 200;
                file_not_allowed_preview.className = ["rounded object-fill m-1"];
                file_not_allowed_preview.src = require(`../../images/common/file-not-allowed.png`);
                div_preview.appendChild(file_not_allowed_preview);
                let extraData = [...this.state.extraData];
                let errData = {
                    name: arrayFiles[i].name,
                    error: true
                };
                extraData.push(errData);
                this.setState({ extraData })
            }
        }
        div_preview.removeChild(div_loading);
        this.setToday();
        this.setMonthLater();
        this.filterCat();
        this.getBrands();
        this.getCustomers();
    }
    // unZipFile = (zipFile) => { // not working
    //     return new Promise((resolve, reject) => {
    //         let zipNameAux = zipFile.name;
    //         zipNameAux = zipNameAux.split('.');
    //         let zipName = zipNameAux[0].split('-');
    //         let zipNameWidth = zipName[0].split('x')[0];
    //         let zipNameHeight = zipName[0].split('x')[1];
    //         zipNameHeight = zipNameHeight.split('_')[0];
    //         if(!Number.isNaN(Number(zipNameWidth)) && !Number.isNaN(Number(zipNameHeight))){
    //             resolve({
    //                 width: Number(zipNameWidth),
    //                 height: Number(zipNameHeight)
    //             })
    //         }else{
    //         let zip = new JSZip();
    //         zip.loadAsync(zipFile).then(objZip => {
    //                 Object.keys(objZip.files).forEach((key,i) => {
    //                     let file = objZip.files[key];
    //                     if(key.includes('index.html') && !key.includes("__MACOSX")){
    //                         file.async("text").then(fileParsed => {
    //                             let htmlText = fileParsed;
    //                             let indexOfBodyStart = htmlText.indexOf("<body>");
    //                             let indexOfBodyEnd = htmlText.indexOf("</body>");
    //                             let bodyText = htmlText.substring(indexOfBodyStart + 6, indexOfBodyEnd);
    //                             let gwdPageStart = bodyText.indexOf("<gwd-page id");
    //                             let gwdPageEnd = bodyText.indexOf("</gwd-page>");
    //                             let gwdPage = bodyText.substring(gwdPageStart, gwdPageEnd+11);
    //                             let heightStart = gwdPage.indexOf("data-gwd-height");
    //                             let heightEnd = gwdPage.indexOf("px", heightStart);
    //                             let height = gwdPage.substring(heightStart+17, heightEnd);
    //                             let widthStart = gwdPage.indexOf("data-gwd-width");
    //                             let widthEnd = gwdPage.indexOf("px", widthStart);
    //                             let width = gwdPage.substring(widthStart+16, widthEnd);
    //                             resolve({
    //                                 width: Number(width),
    //                                 height: Number(height)
    //                             });
    //                         })
    //                     }else{
    //                         console.log(Object.keys(objZip.files).length-1, i);
    //                         if(Object.keys(objZip.files).length-1 === i ){
    //                             resolve(null);
    //                         }
    //                     }
    //                 })
    //             });
    //         }
    //     })
    // }
    getBrands = async() => {
        try {
            const response = await getBrands();

            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                return;
            }

            this.setState({brands: response.data});
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.serverError')
            });
        }
    }

    getCustomers = async() => {
        try {
            const response = await getCustomers();

            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                return;
            }
            this.setState({customers: response.data});
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.serverError')
            });
        }
    }

    createContentObject(file, createdFiles) {
        return new Promise((resolve) => {
            const nameSplit = file.name.split('.');
            let obj = {}
            if(nameSplit.length > 2){
                let realName = '';
                nameSplit.forEach((part,i) => {
                    if(i !== nameSplit.length-1){
                        realName+=part;
                        if(i !== nameSplit.length-2){
                            realName+='.';
                        }
                    }
                });
                obj = {
                    name : realName,
                    ext : nameSplit[nameSplit.length-1]
                }

            }else{
                obj = {
                    name: nameSplit[0],
                    ext: nameSplit[1],
                }
            }
            createdFiles.forEach(created => {
                if (created.originalname === file.name) {
                    const split = created.location.split('/');
                    obj.path = split[split.length - 1];
                }
            });

            const videoExt = ['mp4', 'ogg', 'webm'];
            const imgExt = ['jpg', 'jpeg', 'png', 'gif'];
            if (videoExt.includes(obj.ext.toLowerCase())) {
                const video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    obj.duration = video.duration;
                    obj.height = video.videoHeight;
                    obj.width = video.videoWidth;
                    console.log(obj);
                    resolve(obj);
                }
                video.src = URL.createObjectURL(file);
            }
            else if (imgExt.includes(obj.ext.toLowerCase())) {
                let copyData = cloneDeep(this.state.extraData)
                copyData.forEach(data => {
                    let lastIndexDot = data.name.lastIndexOf('.');
                    data.name = data.name.slice(0,lastIndexDot);
                    if (obj.name === data.name) {
                        obj.width = data.width;
                        obj.height = data.height;
                    }
                })
                resolve(obj);
            }else{
                resolve(obj)
            }
        });
    }

    openModal = (id) => {
        document.getElementById(id).showModal();
    }

    closeModal = () => {
        window.location.reload();
    }
    isNumberValid = (number)=>{
        if(number >= 1 && (number) - Math.floor(number) === 0){
            return true
        }
        return false
    }

    handleSend = async () => {
        let schedule = this.getSchedule();
        const selectedFiles = this.props.files;
        if (schedule.time === "" || schedule.startDate === "" ) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.fileDropper.selectSchedule')
            });
            return;
        }
        if(this.state.endDate && schedule.endDate !== '' && schedule.startDate >= schedule.endDate){
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.fileDropper.scheduleDate')
            });
            return;
        }else if(!this.state.endDate){
            schedule.endDate = "";
        }
        if (!selectedFiles) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.fileDropper.selectSomeFile')
            });
            return;
        }

        this.props.setLoading(true);

        const baseURL = window.Config.REACT_APP_BACKEND_URL;
        const { showNotification } = this.props;
        const img_ext = /(jpg|jpeg|png|gif)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de img.
        const video_ext = /(mp4|ogg|webm)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de video.
        const zip_ext = /(x-zip-compressed|zip)$/i; //Expresion regular para verificar si el archivo es del tipo admitido de zip.
        try {
            selectedFiles.forEach(content => {
                if(content.type === "video/mpeg" || content.type === "video/mpg"){
                    showNotification({
                        type: "warning",
                        text: this.props.t('common.notification.fileDropper.convertingFile'),
                        duration: 5000
                    });
                }
            });
            let filteredFiles = selectedFiles.filter(content => {
                if( !img_ext.test(content.type.split("/")[1]) &&
                    !video_ext.test(content.type.split("/")[1]) &&
                    !zip_ext.test(content.type.split("/")[1]) &&
                    content.type !== "video/mpg" &&
                    content.type !== "video/mpeg"){
                    showNotification({
                        type: "error",
                        text: this.props.t('common.notification.fileDropper.error.fileTypeNotAllowed').replace('_name_', content.name),
                        duration: 5000
                    });
                    return false;
                }
                return true;
            });
            if(filteredFiles.length === 0){
                setTimeout(() => {
                    showNotification({
                        type: "error",
                        text: this.props.t('common.notification.fileDropper.error.empty_list'),
                        duration: 3000
                    });
                    this.props.setLoading(false);
                    this.props.closeModal();
                }, 6000);
            }
            let response = await uploadFiles(filteredFiles);
            if (response.status === 201) {
                if (response.data.error) {
                    showNotification({
                        type: "error",
                        text: this.props.t('common.notification.serverError')
                    });
                    this.props.setLoading(false);
                } else {
                    const createdFiles = response.data.filesArray;
                    let data = { parentPath: '/', screen_id: this.getSelectedScreens(), schedule: this.getSchedule(), contents: [], categories: [] };
                    const contents = [];
                    for (let i = 0; i < filteredFiles.length; i++) {
                        let content = await this.createContentObject(filteredFiles[i], createdFiles);
                        content.screensBeforeEvent = [];
                        contents.push(content);
                    }
                    data.contents = contents;
                    data.categories = this.getCategories();

                    const {selectedBrandId} = this.state;
                    if (selectedBrandId)
                        data.brand = selectedBrandId;

                    const {selectedCustomerId} = this.state;
                    if (selectedCustomerId)
                        data.customer = selectedCustomerId;

                    const {contentHasDailyLimitRepetitions} = this.state;
                    if(contentHasDailyLimitRepetitions){
                        let inputValue = document.getElementById('inputDailyLimitFileDropper').value;
                        if(this.isNumberValid(inputValue)){
                            data.daily_limit = Number(inputValue);
                        }
                    }
                    try {
                        response = await axios.post(`${baseURL}/content/content-db`, data, { withCredentials: true });
                        if (response.status === 201) {
                            checkLimitExceeded(data.screen_id)
                                .then(r => {
                                    if (r.data.length > 0){
                                        let data = r.data;
                                        let quantityContentMore = 0;
                                        data.forEach(screenCnt => {
                                            if(screenCnt.quantityContent > screenCnt.quantitySpots){
                                                quantityContentMore += screenCnt.quantityContent-screenCnt.quantitySpots;
                                            }
                                        })

                                        showNotification({
                                            type: "warning",
                                            title: this.props.t('common.notification.fileDropper.exceedTitle').replace('_number_', quantityContentMore),
                                            text: this.props.t('common.notification.fileDropper.exceedBody').replace('_number_', data.length),
                                            duration: 5000
                                        });
                                    }
                                    else {
                                        showNotification({
                                            type: "success",
                                            text: this.props.t('common.notification.fileDropper.success')
                                        });
                                    }
                                    this.props.setLoading(false);
                                    setTimeout(() => {
                                         this.closeModal();
                                    }, 5000);
                                });
                        }
                    } catch (error) {
                        console.log(error);
                        showNotification({
                            type: "error",
                            text: this.props.t('common.notification.serverError')
                        });
                        this.props.setLoading(false);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            showNotification({
                type: "error",
                text: this.props.t('common.notification.serverError')
            });
            this.props.setLoading(false);
        }
    }

    setToday = () => {
        var _dat = document.querySelector("#startDate");
        var hoy = new Date(),
            d = hoy.getDate(),
            m = hoy.getMonth()+1,
            y = hoy.getFullYear(),
            data;

        if(d < 10){
            d = "0"+d;
        };
        if(m < 10){
            m = "0"+m;
        };

        data = y+"-"+m+"-"+d;
        _dat.value = data;
    }

    setMonthLater= () => {
        var _dat = document.querySelector("#endDate");
        let monthLater = (86400000 * 30);
        let now = Date.now()
        let newDateMs = now + monthLater
        var hoy = new Date(newDateMs),
        d = hoy.getDate(),
        m = hoy.getMonth()+1,
        y = hoy.getFullYear(),
        data;

        if(d < 10){
            d = "0"+d;
        };
        if(m < 10){
            m = "0"+m;
        };

        data = y+"-"+m+"-"+d;
        _dat.value = data;
    }

    getSchedule = () => {
        const obj = {};

        obj.startDate = document.getElementById('startDate').value;

        if(this.state.endDate){
            obj.endDate = document.getElementById('endDate').value;
        } else {
            obj.endDate = '';
        }


        obj.time = document.getElementById('adTime').value;

        return obj;
    }

    filterCat = () => {
        let filtered_categories = [];
        iabCatEN.forEach(cat => {
            let filt_cat = {};
            filt_cat.label = cat.Name;
            filt_cat.value = cat.UniqueID;
            filtered_categories.push(filt_cat);
        })
        this.setState({iab_categories: filtered_categories});
    }

    handleCategories = (e) => {
        this.setState({selectedCategories: e});
    }

    /* handleScreens = (e) => {
        let actual_screens = [];
        e.forEach(screen => {
            actual_screens.push(screen.value);
        })
        this.setState({selectedScreens: actual_screens});
    } */
    handleScreens = (selected) => { // Funcion utilizada para implementacion de select de grupos y pantallas, reemplazar por actual
        // (selected, groups, screens, screenOptions, defaultScreens)
        if (selected === null) {
            selected = [];
            this.setState({ screenOptions: this.state.defaultScreenOptions });
        }else{
            selected.forEach(sel => {
                let filteredOptions;
                if(this.state.groups.find(g => g._id === sel.value)){
                    let screens = this.state.screens;
                    let selectedScreens = screens.filter(s => s.group === sel.value);
                    filteredOptions = this.state.screenOptions.map(data => {
                        return {
                            label: data.label,
                            value: data.value,
                            options: data.options.filter(o =>
                                        !selectedScreens.find(s => s._id === o.value)
                                    )
                        }
                    })
                }else if(this.state.screens.find(s => s._id === sel.value)){
                    let groups = this.state.groups;
                    let screensByGroup = groups.map(g => {
                        return {
                            group: g._id,
                            screens: this.state.screens.filter(s => {
                                return s.group === g._id;
                            })
                        }
                    });
                    let selectedScreenOfGroup = screensByGroup.filter(s => {
                        return s.screens.find(screen => screen._id === sel.value)
                    })[0];
                    let selectedScreens = this.state.screens.map(screen => selected.find(s => s.value === screen._id)).filter(screen => screen !== undefined)
                    if(selectedScreenOfGroup && selectedScreenOfGroup.screens.every(s => selectedScreens.find(screen => screen.value === s._id))){
                        filteredOptions = this.state.screenOptions.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            selectedScreenOfGroup.group !== o.value
                                        )
                            }
                        });
                    }else{
                        filteredOptions = this.state.defaultScreenOptions.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            selectedScreens.find(s => s.value !== o.value)
                                        )
                            }
                        })
                    }
                }else{
                    if(this.state.prevScreenOptions)
                        filteredOptions = this.state.prevScreenOptions;
                    else
                        filteredOptions = this.state.screenOptions;
                }
                this.setState({
                    prevScreenOptions: this.state.screenOptions,
                    screenOptions: filteredOptions,
                })
            })
        }
        let selectedScreens = this.parseSelected(selected);
        selectedScreens.filter(screen => this.state.selectedScreens.includes(screen))
        this.setState({selectedScreens});
    }

    parseSelected = (selected) => {
        let parsedSelected = [];
        selected.forEach(sel => {
            if(this.state.groups.find(g => g._id === sel.value)){
                let screens = this.state.screens;
                let selectedScreens = screens.filter(s => s.group === sel.value);
                selectedScreens.forEach(screen => parsedSelected.push(screen._id));
            }else{
                parsedSelected.push(sel.value)
            }
        });
        return parsedSelected;
    }

    handleBrand = (e) => {
        this.setState({selectedBrandId: e.value});
    }

    handleCustomer = (e) => {
        this.setState({selectedCustomerId: e.value});
    }

    getCategories = () => {
        return this.state.selectedCategories;
    }

    getSelectedScreens = () => {
        return this.state.selectedScreens;
    }

    clickSubmit() {
        const btnForm = document.getElementById('button_submit_form');
        btnForm.click();
    }

    getTimeNow = () =>{
        let now = new Date();
        return ("0"+ now.getHours().toString()).slice(-2) + ":" + ("0"+ now.getMinutes().toString()).slice(-2);
    }

    handleNoEndDate = (e) => {
        this.setState({
            endDate: !this.state.endDate
        }, () => {
            let divToChange = document.getElementById('endDate');
            if (this.state.endDate) {
                divToChange.classList.remove('opacity-0');
                divToChange.classList.remove('pointer-events-none');
            } else {
                divToChange.classList.add('opacity-0');
                divToChange.classList.add('pointer-events-none');
            }
        })
    }
    /**
     * Issue DS-224,
     * Change the value of the imput for some valid value (integer)
     * @param {*} e value imput number
     */
    onChangeValueOfImput = (e) =>{
        let daily_limit = cloneDeep(this.state.daily_limit)
        daily_limit = Number(e.target.value.replace(/[,-.]/g,'')).toString()
        this.setState({daily_limit:daily_limit,})
    }

    screenOptions = () => this.state.screenOptions?.map(c => c)

    parseGroupedOptions = (options) => {
        return options.map(c => {
            return {
                label: c.name,
                value: c._id,
            }
        })
    }
    /**
     * Issue DS-224,
     * Function that change the value of the switch of daily limit
     */
    handleDailyLimitRepetitions=()=>{
        this.setState({contentHasDailyLimitRepetitions: !this.state.contentHasDailyLimitRepetitions})
    }

    getBrandsOptions = () => this.state.brands.map(brand => {return {label: brand.name, value: brand._id}});
    getCustomersOptions = () => this.state.customers.map(customer => {return {label: customer.contact_name+"-"+customer.company, value: customer._id}});
    getScreenOptions = () => this.state.screens.map(screen => {return {label: screen.name, value: screen._id}});
    getSelectedScreensOptions = () => this.state.screens.filter(screen => this.state.selectedScreens.find(s => screen._id === s)).map(screen => {return {label: screen.name, value: screen._id}});

    render() {
        const screens = this.state.screens;
        const customScreenOption = props => {
            return (
                <components.Option {...props}>
                    <CustomScreenOption props={props} />
                </components.Option>
            )
        }
        return (
            <div id='filedrop-root'>
                {/* <!--Modal--> */}
                <div className="modal fixed z-40 w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 backdrop"></div>
                    <div className="lg:container modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-40 overflow-y-auto">
                        <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                            <div className="title text-gray-600 text-2xl">
                                {this.props.t("common.modals.fileDropper.modal-title")}
                                <p className="text-sm font-light text-gray-600">{this.props.t("common.modals.fileDropper.modal-description")}</p>
                            </div>
                            <div className="button cursor-pointer" onClick={this.props.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                        </div>
                        <div className="relative modal-content h-5/6 text-left md:px-6">
                            {/* <!--Body--> */}
                            {/* <!--Img Previews--> */}
                            <div className="container mx-auto h-full relative flex md:flex-row flex-col justify-between">
                                <div id='div_container_preview' className="relative w-full md:w-3/6 overflow-y-hidden mr-2 border-2 border-dashed rounded-lg p-2 mt-2">
                                    <p>{this.props.t("common.modals.fileDropper.preview")}</p>
                                    <div className="h-8 w-full pointer-events-none absolute top-7 left-0 ml-2 mt-1 z-10 bg-gradient-to-b from-white"></div>
                                    <div className="h-8 w-full pointer-events-none absolute bottom-0 left-0 mt-1 z-10 bg-gradient-to-t from-white"></div>
                                    <div id='div_img_preview' className="relative p-2 overflow-y-scroll h-full pb-2">
                                        <div className=''>
                                            <FontAwesomeIcon icon={["fal", "upload"]} size="4x" opacity='0.05' color='black' />
                                            <p><span className='font-bold'>{this.props.t("common.modals.fileDropper.dropFiles")}</span> {this.props.t("common.modals.fileDropper.dropHere")}</p>
                                        </div>
                                        {/* {this.state.isZipFile &&
                                            <iframe id="iframe-preview" height="300" width="300">

                                            </iframe>
                                        } */}
                                    </div>
                                </div>
                                {/*<!--Select--> */}
                                <div id='div_container_checkbox' className="w-full md:w-3/6 checkboxHandlebox-content h-full div-checkbox overflow-y-auto p-2 mt-2">
                                    <div>
                                        <p className="text-xl">{this.props.t("common.modals.fileDropper.selectScreens")}</p>
                                        <p className="font-thin">{this.props.t("common.modals.fileDropper.doLater")}</p>
                                    </div>
                                    <div className="h-5/6 relative overflow-y-scroll">
                                        {screens.length === 0
                                            ?
                                                <div className="mt-5">
                                                    <span className="text-gray-500">{this.props.t('common.notification.customers.customersNotAvailable')}</span>
                                                </div>
                                            :
                                                <div className="mt-5">
                                                    <p>{this.props.t('common.modals.fileDropper.screen')}</p>
                                                    {/* <Select
                                                        className="w-80"
                                                        isMulti
                                                        onChange={this.handleScreens}
                                                        options={this.screenOptions() || {}}
                                                        components={{ Option: CustomScreenOption }}
                                                        isLoading={this.state.isScreenSelectLoading}
                                                        /> */}
                                                    <GroupedSelect
                                                        getGroupedOptions={this.screenOptions}
                                                        selected={this.getSelectedScreensOptions()}
                                                        customComponents={{Option: customScreenOption}}
                                                        changeCallback={this.handleScreens}
                                                        extraProps={{isMulti: "true", isLoading: this.state.isScreenSelectLoading}}
                                                    ></GroupedSelect>
                                                </div>
                                        }
                                        {/* Schedule */}
                                        <div>
                                            <p><span className="font-semibold">{this.props.t("common.modals.fileDropper.schedule")}</span> {this.props.t("common.modals.fileDropper.theContent")}</p>
                                            <p className="text-gray-500">{this.props.t("common.modals.fileDropper.schedule-explain")} <span className="font-light text-xs">{this.props.t("common.modals.fileDropper.doLater")}</span></p>
                                            <div className="mt-5 flex">
                                                <FontAwesomeIcon className="mr-2 my-auto" icon={["far", "calendar"]} size="1x" color='grey' />
                                                <input className="mr-3 rounded-md border-gray-200" type="date" name="" id="startDate"/>
                                                <input className="rounded-md border-gray-200" type="date" name="" id="endDate"/>
                                                <label className="flex flex-col mx-auto">
                                                    {this.props.t("common.modals.fileDropper.noEndDate")}
                                                    {/* <input className="mx-auto rounded appearance-none checked:bg-blue-500" type="checkbox" onChange={this.handleNoEndDate}/> */}
                                                    <ButtonSwitcher handleVisibility={this.handleNoEndDate} status={this.state.endDate} labelOn={this.props.t("common.modals.fileDropper.withEndDate")} labelOff={this.props.t("common.modals.fileDropper.noEndDate")} revertLabel={true}></ButtonSwitcher>
                                                </label>
                                            </div>
                                            <div className="mt-5">
                                                <FontAwesomeIcon className="mr-2" icon={["far", "clock"]} size="1x" color='grey' />
                                                <input className="rounded-md border-gray-200" type="time" defaultValue={this.getTimeNow()} name="" id="adTime" />
                                            </div>
                                            {/* <div className="mt-5">
                                                <label>{this.props.t("common.modals.fileDropper.aspectRatio")}</label>
                                                <FontAwesomeIcon className="mx-2" icon={["far", "image"]} size="1x" color='grey' title={this.props.t("common.modals.fileDropper.image")}/>
                                                <input className="border-blue-300 text-blue-400" type="checkbox" id="imgRelation" onChange={this.handleCheckbox}/>
                                                <FontAwesomeIcon className="mx-2" icon={["far", "desktop"]} size="1x" color='grey' title={this.props.t("common.modals.fileDropper.screen")}/>
                                                <input className="border-blue-300 text-blue-400" type="checkbox" id="screenRelation" onChange={this.handleCheckbox}/>
                                            </div> */}
                                        </div>
                                          {/* Daily_limit */}
                                        <div className="mt-4 w-full ">
                                            <div className="flex">
                                                <FontAwesomeIcon fixedWidth icon={['fas', 'tasks-alt']} className=" mt-1 text-gray-600" />
                                                <span className="text-gray-700 ml-1 mr-2">{this.props.t('sections.content.Dailyimpressionlimit')}:</span>
                                                <span>
                                                    <ButtonSwitcher handleVisibility={this.handleDailyLimitRepetitions} labelOn={this.props.t('sections.content.repLimit')} labelOff={this.props.t('sections.content.noReplayLimit')} revertLabel={true}></ButtonSwitcher>
                                                </span>
                                            </div>
                                            <input id="inputDailyLimitFileDropper" type="number"  value={this.state.daily_limit===undefined ? "": this.state.daily_limit} className={`block w-1/4 appearance-none border border-gray-300  rounded py-2  text-gray-700 leading-tight focus:outline-none focus:focus:ring ${!this.state.contentHasDailyLimitRepetitions && "opacity-0 pointer-events-none"}`} onChange={this.onChangeValueOfImput}></input>
                                        </div>
                                        {this.state.brands.length === 0
                                            ?
                                                <div className="mt-5">
                                                    <span className="text-gray-500">{this.props.t('common.notification.brands.brandsNotAvailable')}</span>
                                                </div>
                                            :
                                                <div className="mt-5">
                                                    <p>{this.props.t('common.modals.fileDropper.contentBrand')}</p>
                                                    <Select
                                                        className="w-80"
                                                        onChange={this.handleBrand}
                                                        options={this.getBrandsOptions()}
                                                    />
                                                </div>
                                        }
                                        {this.state.customers.length === 0
                                            ?
                                                <div className="mt-5">
                                                    <span className="text-gray-500">{this.props.t('common.notification.customers.customersNotAvailable')}</span>
                                                </div>
                                            :
                                                <div className="mt-5">
                                                    <p>{this.props.t('common.modals.fileDropper.contentCustomer')}</p>
                                                    <Select
                                                        className="w-80"
                                                        onChange={this.handleCustomer}
                                                        options={this.getCustomersOptions()}
                                                    />
                                                </div>
                                        }
                                        {/* <div className="mt-5">
                                            <p>{this.props.t('common.modals.fileDropper.contentCategory')}</p>
                                            <Select
                                                className="w-80"
                                                isMulti
                                                onChange={this.handleCategories}
                                                options={this.state.iab_categories}
                                            />
                                        </div> */}
                                        {/*  */}

                                    </div>
                                    <div className=" absolute -bottom-5 right-3 flex justify-end pt-2">
                                        <button onClick={this.props.closeModal} className="modal-close buttonSecondary">{this.props.t('common.buttons.close')}</button>
                                        <button id='button_submit' onClick={this.handleSend} className="buttonPrimary">{this.props.t('common.buttons.submit')}</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(FileDropper);
