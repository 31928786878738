import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export const addGroup = (data) => {
    return instance_axios.post('/generic/', [data], {
        withCredentials: true,
        headers: { common: headers }
    })
        .then(groups => groups)
        .catch(err => console.log(err));
}

export const addScreen = (data) => {
    return instance_axios.post('/generic/', data, {
        withCredentials: true,
        headers: { common: headers }
    })
        .then(screen => screen)
        .catch(err => err);
}
export const editScreen = (data) => {
    return instance_axios.put('/screen/update', data, {
        withCredentials: true,
        headers: { common: headers }
    })
        .then(r => r)
        .catch(err => err);
}

export const getGroups = () => {
    return instance_axios.get('/generic/screenGroup', {
        withCredentials: true,
        headers: { common: headers }
    })
        .then(groups => groups)
        .catch(err => console.log(err));
}

export const getRestrictions = () => {
    return instance_axios.get('/generic/restriction', {
        withCredentials: true,
        headers: { common: headers }
    })
        .then(restrictions => restrictions)
        .catch(err => console.log(err));
}
export const getScreenByID = (ID) => {
    return instance_axios.get('/generic/screen/' +ID, {
        withCredentials: true,
        headers: { common: headers}
    })
    .then(r => r)
    .catch(err => err)
}
export const readSheetAndReturnJsonScreen = (files) => {
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
        data.append('sheet', files[i], files[i].name);
    }
    return instance_axios.post(`/screen/readsheet`, data, {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        },
        withCredentials: true
    })
        .then(r => r)
        .catch(err => err.request);
}
