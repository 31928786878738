import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../utils/modals.css';
import Select, {createFilter} from 'react-select';
class CreateModal extends React.Component {
    /**
     * Function that get the values of inputs with specific class name.
     * Then, if all inputs are completed, creates obj for each input and push it in an array.
     * Finally trigger a callback function with the array passed by props.
     */
    returnValue = (e) => {
        let arrayReturn = [];
        let inputs = document.querySelectorAll('.inputModal'+this.props.type);
        let emptyInputs = this.verifyEmpty(inputs);
        if(emptyInputs.length === 0){
            inputs.forEach((p,i) => {
                let obj = {
                    name : this.props.values[i].name,
                    value: inputs[i].value
                }
                arrayReturn.push(obj);
            });
            if(this.state && (this.state.selectedCategories || this.state.selectedCategories.length > 0)){
                let obj = { 
                    name: "categories",
                    value: this.state.selectedCategories
                }
                arrayReturn.push(obj);
                this.setState({selectedCategories: []});
            }
            inputs.forEach(r => {
                r.value = "";
            })
            this.props.callbackFunction(arrayReturn);
            this.closeModal();
        }else{
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.management.fillInput'),
                duration : 3000
            });
        }
    }

    /**
     * It takes an array of inputs, and returns an array of the names of the inputs that are empty
     * @param inputs - an array of input elements
     * @returns An array of strings.
     */
    verifyEmpty = (inputs) => {
        let inputEmptyNames = [];
        inputs.forEach(input => {
            if(input.value === ""){
                inputEmptyNames.push(input.getAttribute('data-type'));
            }
        })
        return inputEmptyNames;
    }
    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add('hidden');
    }
    handleChange = (e) => {
        this.setState({selectedCategories: e});
    }

    render() {
        const customFilter = createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any'
        });
        return (
            <div id={this.props.modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className="modalContent modal-lg bg-white w-80 rounded-md p-5 flex flex-col h-auto">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-2xl">{this.props.t('sections.management.titleC') + " " + this.props.type}</div>
                        <div className="button cursor-pointer" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className="modal-content mt-4 md:px-4">
                        {this.props.values.map( prop => {
                            return <div className="block items-center mt-3">
                                    <label className="labelModal text-gray-700 text-sm font-bold mb-2">{prop.value}</label>
                                    {prop.field_type === "input" && 
                                        <input data-type={prop.name} placeholder={this.props.t('sections.management.placeholder') + " " + prop.value} className= {`block shadow appearance-none border rounded w-9/12 py-2 mx-auto px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring inputModal`+this.props.type}/>
                                    }
                                    {prop.field_type === "select" && 
                                        <div className="mt-3">
                                            <Select 
                                                filterOptions={customFilter} 
                                                id="categories"
                                                className={"w-9/12 mx-auto selectModal"+this.props.type} 
                                                isMulti 
                                                onChange={this.handleChange}
                                                options={this.props.select_values} 
                                                value={this.state?.selectedCategories || []}
                                            />
                                        </div>
                                    }
                                    </div>
                        })}
                    </div>
                    <div className="flex justify-end">
                        <button className="buttonPrimary" onClick={this.returnValue}>{this.props.t('common.buttons.submit')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CreateModal);