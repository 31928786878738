import axios from 'axios'
import * as dotenv from 'dotenv';
dotenv.config();
const instance_axios = axios.create({ baseURL: window.Config.REACT_APP_BACKEND_URL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export const getDirectImpressions = (filters) => {
    return instance_axios.post('/screen/impressions', filters, {
        headers: { common: headers },
        withCredentials: true,
    })
        .then(r => r)
        .catch(err => err.request);
}
export const getUserData = () => {
    return instance_axios.get('/user/data', {
        headers: { common: headers },
        withCredentials: true,
    })
        .then(r => r)
        .catch(err => err.request);
}
export const getCustomers = () => {
    return instance_axios.get('/generic/customer', {
        headers: { common: headers },
        withCredentials: true,
    })
    .then(r => r)
    .catch(err => err.request);
}