import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
export const register = newUser => {
    return instance_axios.post('/auth/register', newUser)
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        })
}
export const login = async(user) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return instance_axios.post('/auth/login', {
            email: user.email,
            password: user.password
        }, {
            headers: { common: headers },
            withCredentials: true,
        })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        })

}
export const forgot_password = email => {
    return instance_axios.put('/auth/forgotpassword', {
            email: email
        })
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        })
}
export const reset_password = newData => {
    return instance_axios.put('/auth/resetpassword', {
            resetLink: newData.resetLink,
            newPass: newData.newPass
        })
        .then(res => res)
        .catch(err => err)
}
export const isLogin = () => {
    return document.cookie.includes('jwt=');
}
export const remainingSecondsToExpireSession = () => {
    return instance_axios.get('/auth/check-login', { withCredentials: true })
        .then(res => {
            if (res.status === 200)
                return res.data.expirationAt - Number(Date.now().toString().slice(0, 10)) - 60;
            else
                return 0;
        })
        .catch(err => {
            console.log(err);
            return 0;
        })
}
export const logOut = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return instance_axios.get('/auth/logOut', {
            withCredentials: true,
            headers: { common: headers }
        }).then(r => r)
        .catch(err => err)
}
export const confirmAccount = (code) => {
    return instance_axios.post(`/auth/confirm_account/${code}`)
        .then(r => r)
        .catch(err => err.response);
}