import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const instance_axios = axios.create({ baseURL: window.Config.REACT_APP_BACKEND_URL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export const getScreens = () => {
    return instance_axios.get('/generic/screen', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(screens => screens)
        .catch(err => err);
}
export const getScreenWithCoords = (prop) => {
    return instance_axios.post('/generic/screen/location', { prop }, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(screen => screen)
        .catch(err => err);
}