import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export const sendForm = (dataArray) => {
    return instance_axios.post('/user/support', dataArray, {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err);
}

export const getLoggedUser = () => {
    return instance_axios.get('/user/getLoggedUser', {
            withCredentials: true,
            headers: { common: headers }
        })
        .then(r => r)
        .catch(err => err);
}
