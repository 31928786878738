import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal';
import ButtonSwitcher from '../../buttons/ButtonSwitcher';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Step1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            repeat: [
                {
                    label: this.props.t('sections.calendar.wizard.step1.repeats.daily'),
                    value: 0
                },
                {
                    label: this.props.t('sections.calendar.wizard.step1.repeats.weekly'),
                    value: 1
                }
            ],
            selectedRepeat: 0
        }
    }
    componentDidMount() {
        const { event } = this.props;
        let selectedRepeat = event.repetitive_day ? event.repetitive_day.length > 0 ? 1 : 0 : 0;
        this.setState({
            event: cloneDeep(event),
            selectedRepeat
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (isEqual(this.props.event, {}) && isEqual(this.state.event, {})) {
            if (this.props.isEventClicked !== undefined && !isEqual(this.props.datePicked, prevProps.datePicked)) {
                let today = new Date().getTime();
                if (new Date(this.props.datePicked+"T23:59:59").getTime() < today) {
                    this.props.showNotification({
                        type: "warning", 
                        text: this.props.t("common.notification.calendar.pastDate")
                    });
                }
            }
        } else if (!isEqual(prevProps.event, this.props.event)) {
            let event = cloneDeep(this.props.event);
            let selectedRepeat = event.repetitive_day ? event.repetitive_day.length > 0 ? 1 : 0 : 0;
            this.setState({
                event: event,
                allDay: this.props.event.allDay,
                selectedRepeat
            })
        }
    }
    handleInputChange = (event) => {
        this.props.handleInputChange(event);
    }
    /**
     * Function that checks if event start and end are ok and sets it in state.event
     * @param {*} event 
     */
    handleDateChange = (event) => {
        let ids = event.target.id.split("-");
        let field = ids[ids.length - 1].toLowerCase();
        let eventObj = cloneDeep(this.state.event);
        if(!eventObj[field]) {
            if (field === 'start' && eventObj.end) {
                eventObj[field] = event.target.value;
            } else if (field === 'end' && eventObj.start) {
                eventObj[field] = event.target.value;
            } else {
                let today = new Date().getTime();
                if (new Date(event.target.value+"T23:59:59").getTime() < today) {
                    this.props.showNotification({
                        type: "warning", 
                        text: this.props.t("common.notification.calendar.pastDate")
                    });
                }
                eventObj[field] = event.target.value;
            }
        } else {
            if (field === 'start') {
                if(eventObj.end) {
                    eventObj[field] = event.target.value;
                } else {
                    let today = new Date().toJSON().split('T')[0]
                    if (event.target.value < today) {
                        this.props.showNotification({
                            type: "warning", 
                            text: this.props.t("common.notification.calendar.pastDate")
                        });
                    }
                    eventObj[field] = event.target.value;
                }
            } else if (field === 'end') {
                if(eventObj.start) {
                        eventObj[field] = event.target.value;
                } else 
                    eventObj[field] = event.target.value;
            }
        }
        this.setState({
            event: eventObj
        })
    }

    /**
     * function that determines if a selected date satisfies the (start < end) condition
     * @param {String} field represents the prop inside an event
     * @param {String} date represents the date to check
     * @returns boolean
     */
    checkDateField = (field, date) => {
        let eventObj = cloneDeep(this.state.event);
        if(!eventObj.start && field !== 'start')
            eventObj.start = this.props.datePicked;
        eventObj[field] = date;
        if (eventObj.start && eventObj.end) {
            if(new Date(eventObj.start) > new Date(eventObj.end)){
                this.props.showNotification({
                    type: "warning", 
                    text: this.props.t("common.notification.calendar.dateError")
                });
                return false;
            }
        }
        return true;
    }
    /**
     * Function that sets selected hour in the appropiate property of event
     * @param {*} e 
     */
    handleHourChange = (e) => {
        let ids = e.target.id.split("-");
        let hourField = (ids[1]+ids[ids.length-1]).toLowerCase();
        let field = ids[ids.length - 1].toLowerCase();
        let eventObj = cloneDeep(this.state.event);
        if(!eventObj[field]) {
            eventObj[hourField] = e.target.value;
            eventObj[field] = this.props.datePicked.includes("T") ? (this.props.datePicked.split("T")[0] + "T" + e.target.value) : this.props.datePicked + "T" + e.target.value
        } else {
            if (field === 'start' && eventObj.end) {
                eventObj[hourField] = e.target.value;
                eventObj[field] = eventObj[field].split("T")[0] + "T" + e.target.value
            } else if (field === 'end' && eventObj.start) {
                eventObj[hourField] = e.target.value;
                eventObj[field] = eventObj[field].split("T")[0] + "T" + e.target.value
            }
        }
        this.setState({
            event: eventObj
        })
    }
    /**
     * Function that swap the allDay property inside event object
     * If an event has allDay property in true, sets the hours of the event to satisfy all day and disable the hour pickers
     */
    handleVisibility = () => {
        let eventObj = cloneDeep(this.state.event);
        eventObj.allDay = !eventObj.allDay;
        if(eventObj.allDay) {
            if(eventObj.start)
                eventObj.start = eventObj.start.split("T")[0] + "T00:00:00";
            if(eventObj.end)
                eventObj.end = eventObj.end.split("T")[0] + "T23:59:59";
            eventObj.hourstart = "00:00";
            eventObj.hourend = "23:59";
        }
        this.setState({
            event: eventObj
        })
        let fields = ['start', 'end'];
        fields.forEach(field => {
            if(eventObj.allDay){
                document.getElementById(`new-hour-${field}`).disabled = true;
                document.getElementById(`new-hour-${field}`).classList.add('bg-gray-300');
                document.getElementById(`new-hour-${field}`).value = '';
            } else {
                document.getElementById(`new-hour-${field}`).disabled = false;
                document.getElementById(`new-hour-${field}`).classList.remove('bg-gray-300');
            }
        });
    }
    /**
     * Function that parse a given string date to satisfy the date format from inputs
     * @param {String} date 
     * @returns String if given date exists
     */
    parseEventDate = (date) => {
        if (date) {
            let dateArray = new Date(date.split("T")[0].replace('-','/'));
            let year = dateArray.getFullYear();
            let month = (dateArray.getMonth()+1).toString().length === 1 ? `0${dateArray.getMonth()+1}`: dateArray.getMonth()+1;
            let day = (dateArray.getDate()).toString().length === 1 ? `0${dateArray.getDate()}`: dateArray.getDate();
            return `${year}-${month}-${day}`;
        } else {
            return null
        }
    }
    /**
     * Function that check if mandatory event properties are fullfiled and trigger a callback function
     * @returns in case of a trouble if there is a missing propertry of event
     */
    nextStep = () => {
        let event = cloneDeep(this.state.event);
        if(!event?.title) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.calendar.emptyTitle")
            });
            return;
        }
        if (!event.start) {
            event.start = this.props.datePicked;
        }
        if (!event.end) {
            event.end = this.props.datePicked;
        }
        if(new Date(event.start.split("T")[0].replace('-', '/')).getTime() > new Date(event.end.split("T")[0].replace('-', '/')).getTime()){
            this.props.showNotification({
                type: "warning", 
                text: this.props.t("common.notification.calendar.pastDate")
            });
            return;
        }
        if(!event.hourstart && !event.hourend) {
            event.hourstart = "00:00";
            event.hourend = "23:59";
            event.allDay = true;
        }else if(!event.hourstart || !event.hourend){
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.calendar.timeError")
            });
            return;
        }
        if(!event.start.includes("T")) {
            event.start = event.start + 'T' + event.hourstart + ":00";
        }
        if(!event.end.includes("T")) {
            event.end = event.end + 'T' + event.hourend + ":00";
        }

        if(event.repetitive_day && event.repetitive_day.length === 0){
            delete event.repetitive_day
        }
        this.props.nextStep(event);
    }
    renderSwitch = () => {
        if(this.state.event && !isEqual(this.state.event, {})){
            if(this.state.event.allDay){
                return <ButtonSwitcher className="my-2 capitalize" handleVisibility={() => this.handleVisibility()} status={true} labelOn="Yes" labelOff="No" revertLabel={true}></ButtonSwitcher>
            }
        }
        return <ButtonSwitcher className="my-2 capitalize" handleVisibility={() => this.handleVisibility()} status={false} labelOn="Yes" labelOff="No" revertLabel={true}></ButtonSwitcher>
    }
    renderTimeRange = () => {
        if(this.state.event && !isEqual(this.state.event, {})){
            return this.state.event.allDay ? 
                    <label htmlFor="timerange" className="flex w-auto uppercase tracking-wide text-gray-600 text-xs font-bold">
                        <label className="flex flex-col flex-1 p-1" htmlFor="allDayEvent">
                            {this.props.t('sections.calendar.wizard.step1.allDayEvent')}?
                            {this.renderSwitch()}
                        </label>
                        <label htmlFor="start-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.startTime')}:
                            <input type="time" className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight bg-gray-300 focus:outline-none focus:focus:ring" name="newEventTill" id="new-hour-start" onChange={this.handleHourChange} disabled/>
                        </label>
                        <label htmlFor="end-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.endTime')}:
                            <input type="time" className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight bg-gray-300 focus:outline-none focus:focus:ring" name="newEventFrom" id="new-hour-end" onChange={this.handleHourChange} disabled/>
                        </label>
                    </label> 
                    : 
                    <label htmlFor="timerange" className="flex w-auto">
                        <label className="flex flex-col flex-1 p-1" htmlFor="allDayEvent">
                            {this.props.t('sections.calendar.wizard.step1.allDayEvent')}?
                            {this.renderSwitch()}
                        </label>
                        <label htmlFor="start-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.startTime')}:
                            <input type="time" className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring" name="newEventTill" id="new-hour-start" onChange={this.handleHourChange} value={this.state.event.hourstart}/>
                        </label>
                        <label htmlFor="end-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.endTime')}:
                            <input type="time" className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring" name="newEventFrom" id="new-hour-end" onChange={this.handleHourChange} value={this.state.event.hourend}/>
                        </label>
                    </label>
        }
    }
    /**
     * Function that returns name of days of week
     * @returns Array of week day names
     */
    getSchedule = () =>{
        return [
            this.props.t('common.days.sunday'),
            this.props.t('common.days.monday'),
            this.props.t('common.days.tuesday'),
            this.props.t('common.days.wednesday'),
            this.props.t('common.days.thursday'),
            this.props.t('common.days.friday'),
            this.props.t('common.days.saturday'),
        ]
    }
    closeModal = () => {
        this.setState({ 
            event: {}
        }, () => {
            this.props.closeModal();
        })
    }
    handleRepetitiveDayChange = (weekdayNumber) => {
        let event = this.state.event;
        this.props.handleRepetitiveDayChange(weekdayNumber, event)
    }
    handleRepeatEvent = (selected) => {
        this.setState({ 
            selectedRepeat: selected.value
        }, () => {
            let event = cloneDeep(this.state.event);
            delete event.repetitive_day;
            this.setState({ 
                event: event
            })
        })
    }
    render() {
        return (
            <div className="stepOne">
                <label htmlFor="Title" className="block uppercase flex-row-reverse tracking-wide text-gray-600 text-xs font-bold mb-2 mx-1 mt-5">
                    <span className='flex justify-between'>
                        {this.props.t('sections.calendar.wizard.step1.eventTitle')}:
                        <small id="helpId" className="form-text text-gray-400">{this.props.t('sections.calendar.wizard.step1.eventTitleDesc')}</small>
                    </span>
                    <input type="text" className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="newEventTitle" id="new-title" onChange={this.handleInputChange} value={this.state.event?.title || ''}/>
                </label>
                <label htmlFor="date" className="flex w-full dateBox  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2  mt-5">
                    <label htmlFor="Start" className="flex flex-col w-1/2 p-1">
                        {this.props.t('sections.calendar.wizard.step1.startDate')}:
                        <input type="date" className="appearance-none block  uppercase  text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="newEventStart" id="new-start" onChange={this.handleDateChange} value={this.parseEventDate(this.state.event?.start) || this.parseEventDate(this.props.datePicked)}/>
                    </label>
                    <label htmlFor="End" className="flex flex-col w-1/2 p-1">
                        {this.props.t('sections.calendar.wizard.step1.endDate')}:
                        <input type="date" className="appearance-none block uppercase text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="newEventEnd" id="new-end" onChange={this.handleDateChange} value={this.parseEventDate(this.state.event?.end) || this.parseEventDate(this.props.datePicked)}/>
                    </label>
                </label>

                {!this.props.isEventClicked ? 
                    <label htmlFor="timerange" className="flex w-auto dateBox overflow-x uppercase tracking-wide text-gray-600 text-xs font-bold ">
                          <label className="flex flex-col flex-1 p-1" htmlFor="allDayEvent">
                            {this.props.t('sections.calendar.wizard.step1.allDayEvent')}?
                            <div className="flex flex-row md:mx-auto mt-2 ">
                                {this.renderSwitch()}
                            </div>
                        </label>
                        <label htmlFor="start-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.startTime')}:
                            <input type="time" className="appearance-none block  text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="newEventTill" id="new-hour-start" onChange={this.handleHourChange} value={this.state.event?.starthour}/>
                        </label>
                        <label htmlFor="end-hour" className="flex flex-col flex-1 p-1">
                            {this.props.t('sections.calendar.wizard.step1.endTime')}:
                            <input type="time" className="appearance-none block   text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="newEventFrom" id="new-hour-end" onChange={this.handleHourChange} value={this.state.event?.endhour}/>
                        </label>
                    </label>
                    :
                    this.renderTimeRange()
                }
                <label htmlFor="schedule" className="block flex-row-reverse uppercase text-xs font-bold tracking-wide text-gray-600 mb-2 mx-1 mt-5">
                    <ReactTooltip effect="solid" className="p-2 text-center font-light normal-case" id={'1rstStepRepeatDay'} aria-haspopup='true'>
                        {this.props.t('sections.calendar.wizard.step1.repeatInfo')}
                    </ReactTooltip>
                    <span className='flex'>
                        {this.props.t('sections.calendar.wizard.step1.repetitiveEvent')}
                        <FontAwesomeIcon icon={['fas', 'question-circle']} className="my-auto pl-1 text-gray-400" data-tip data-for="1rstStepRepeatDay"/>
                    </span>
                    <Select 
                        className='font-semibold'
                        onChange={this.handleRepeatEvent}
                        options={this.state.repeat}
                        value={this.state.repeat[this.state.selectedRepeat]}
                    />
                    {this.state.selectedRepeat === 1 && 
                        <div className={'flex justify-around my-auto'}>
                            {this.getSchedule().map((day, index) => {
                                return (
                                    <button className={`my-2 p-1.5 border font-semibold rounded ${this.state.event?.repetitive_day?.includes(index) ? 'bg-blue-500 text-white' : ''}`} data-day={index} onClick={() => this.handleRepetitiveDayChange(index)}>{day}</button>
                                )
                            })}
                        </div>
                    }
                </label>
                <div className="flex justify-between mt-3">
                    {!this.props.isEventClicked &&
                        <>
                            <button onClick={this.closeModal} className="buttonSecondary">{this.props.t('common.buttons.close')}</button>
                        </>
                    } 
                    <button onClick={this.nextStep} className="m-auto  buttonPrimary ">{this.props.t('common.buttons.next')}</button>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Step1);
