import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import {getTimeThatContentFitsInTimeLeftOnScreens} from '../content/Content.utils';
import {formatNumber} from '../../util_files/formatNumber'
class ListItem extends Component {
    
    render() {
        const content = this.props.content;
        const index = this.props.index;
        
        const {  selectedContentId, contentMenuIsVisible,contentMenuDetailThumbnail } = this.props;
        const imageExtensions = ['png', 'jpg', 'jpeg'];
        const videoExtensions = ['mp4', 'ogg', 'webm'];
        const htmlExtensions = ['zip','html'];
        const isContentForFuture = (content) => {
            return new Date().getTime() < new Date(content.schedule.startDate).getTime();
        };
        return (
            <>
                {content.daily_limit > 0 &&
                    <>
                    {/**icons */}
                        <ReactTooltip type="info" className="p-2" id={"DailyLimit"+content._id} aria-haspopup='true'>
                            <p className="text-center max-w-sm">
                            {this.props.t('sections.screens.screenPlaylist.dailyLimit')}
                            </p>
                        </ReactTooltip>
                        <div data-tip data-for={"DailyLimit"+content._id} className='absolute flex justify-center  w-5 h-5 bg-white rounded-full ml-2 mt-3 cursor-pointer z-10'>
                            <FontAwesomeIcon  icon={["fal", "tasks-alt"]} className="relative text-xs top-1 opacity-100"/>
                        </div>
                        <ReactTooltip type="error"   className="p-2" id={"DailyLimitExceeded"+content._id} aria-haspopup='true'>
                            <p className="text-center max-w-sm">
                            {this.props.t('sections.screens.screenPlaylist.dailyLimitExceeded')}
                            </p>
                        </ReactTooltip>
                        {Number(content.daily_limit) === content.impressions_in_day &&
                            <div data-tip data-for={"DailyLimitExceeded"+content._id} className='absolute flex justify-center w-5 h-5 bg-white rounded-full ml-24 mt-3 cursor-pointer z-10'>
                                <FontAwesomeIcon  icon={["fa", "pause"]} className="relative text-xs top-1 text-red-500"/>
                            </div>
                        }
                    </>
                }
                <div  id={`detailThumbnail${content._id}`} className={`absolute z-20 w-26 left-32 mt-3 h-30  justify-center p-2 px-6 text-xs bg-white rounded-md shadow divide-y  divide-blue-200 ${selectedContentId === `detailThumbnail${content._id}` && contentMenuDetailThumbnail ? "" : "hidden"}`}>
                    {!content.isCreativeCarrousel &&
                        <div onClick={()=>{this.props.handleModalOfContent("DetailContentModal",content)}} className="py-2 w-4/5 px-3 mb-2  text-left cursor-pointer">{this.props.t('sections.screens.screenPlaylist.details')}</div>
                    }
                        <Link  className="py-2 w-4/5 px-3 cursor-pointer " to={{
                                    pathname: '/content',
                                    state: {
                                        data: {
                                            contentBeingEdited:content._id,
                                        }
                                    }
                                }}>
                            <button className="">{this.props.t("common.buttons.edit")}</button>
                        </Link>

                </div>
                <div className="w-full  items-center flex flex-row truncate">
                    <div className="contenedorEvento flex items-center justify-center mx-1 rounded-lg bg-black overflow-hidden ">
                    <ReactTooltip type="info" effect="solid" place="left" className="p-2" id={"tooltip"+content._id} aria-haspopup='true'>
                        <p className="text-center max-w-sm">
                            {content.path}
                        </p>
                    </ReactTooltip>
                    {/**img */}
                    <div onContextMenu={(e)=>this.props.openOptionsOfDetailContent(e,content)} className="bg-white">
                        <div className={`${Number(content.daily_limit) === content.impressions_in_day ? "opacity-40 ":""}`}>
                                {imageExtensions.includes(content.ext) &&
                                    <> <img data-tip data-for={"tooltip"+content._id} className="" src={`${window.Config.REACT_APP_FILESERVER_URL}/${content.path}`} alt={content.name}/></>
                                }
                                {videoExtensions.includes(content.ext) &&
                                    <><video data-tip data-for={"tooltip"+content._id} src={`${window.Config.REACT_APP_FILESERVER_URL}/${content.path}`} alt={content.name}></video></>
                                }
                                {htmlExtensions.includes(content.ext)&&
                                    <><img data-tip data-for={"tooltip"+content._id} src={require('../../images/common/html.png')} alt={content.name}></img></>
                                }
                        </div>
                    </div>
                    </div>
                    {content.daily_limit > 0 &&
                    <div className=" absolute ml-3.5 w-24 mt-16 bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                        {((content.impressions_in_day/content.daily_limit)*100) === 100 ?
                        <div className="bg-red-600 h-2 rounded-full" style={{width: (content.impressions_in_day/content.daily_limit)*100 +"%"}}></div>
                        :
                        <div className={`${((content.impressions_in_day/content.daily_limit)*100) <30 ? "bg-blue-300":"bg-yellow-300"} h-2 rounded-full`} style={{width: (content.impressions_in_day/content.daily_limit)*100 +"%"}}></div>
                        }
                    </div>
                    }
                    <div className="playlist-item-description mr-2 md:w-5/6 flex flex-col items-start content-center truncate">
                        <span className=" playlist-item-name font-bold mr-3">
                            <ReactTooltip effect="solid" className="w-72 p-2" id={`contentFullName${content._id}`} aria-haspopup='true'>
                                <p className="text-center truncate">
                                    {content.name}
                                </p>
                            </ReactTooltip>
                            <div className='flex justify-between'>
                                <p className="max-w-xs ml-1 truncate" data-tip data-for={`contentFullName${content._id}`}>
                                    {content.name}
                                    {content.daily_limit && Number(content.daily_limit) !== content.impressions_in_day?
                                        <span className="spanBadge ml-1">
                                            <span class="px-1 py-0.5 rounded bg-gray-300 text-gray-800 font-semibold text-xs">{this.props.t('sections.screens.screenPlaylist.dailyLimit')}</span>
                                        </span>
                                :
                                    <>
                                        {content.daily_limit && Number(content.daily_limit) === content.impressions_in_day &&
                                            <span className="spanBadge ml-1">
                                                <span class="px-1 py-0.5 rounded bg-red-400 text-gray-800 font-semibold text-xs">{this.props.t('sections.screens.screenPlaylist.dailyLimitExceeded')}</span>
                                            </span>
                                        }
                                    </>
                                    }
                                </p>
                                {isContentForFuture(content) &&
                                    <div className='mx-1 px-1 py-0.5 rounded bg-gray-300 text-gray-800 font-semibold text-xs'>
                                        {this.props.t('sections.screens.screenPlaylist.scheduledContent')}
                                    </div>
                                }
                            </div>
                            {!content.isCreativeCarrousel ?
                                !htmlExtensions.includes(content.ext) ?
                                    <span className="playlist-item-size font-extralight text-gray-600 mr-1 ml-1"><FontAwesomeIcon  icon={['fal','expand-wide' ]} className="text-blue-400 ml-0 mr-1.5 "/> {content.width}x{content.height}</span>
                                    :
                                    <span className="playlist-item-size font-extralight text-gray-600 ml-1">{this.props.t('sections.screens.screenPlaylist.htmlFile')}</span>
                            :
                            <>
                                <FontAwesomeIcon  icon={['fad','clipboard-list' ]} className="text-blue-400 ml-1.5 mr-1.5 cursor-pointer"  />
                                <span className="playlist-item-size font-extralight pr-1 text-gray-600">{this.props.t('sections.content.carousel')}</span>
                            </>
                            }
                            <span className="italic text-xs text-gray-400">{content.original_content ? this.props.t('sections.screens.screenPlaylist.cloned') : ""}</span>
                        </span>
                        <div className='flex'>
                            
                        {!content.isCreativeCarrousel && 
                            <>
                                {
                                    videoExtensions.includes(content.ext) ?
                                    <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fal", "stopwatch"]} fixedWidth className="text-blue-600 mr-1 ml-0.5 text-sm" />{(content.duration)?.toFixed(2)}"</span>
                                    :
                                    <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fal", "stopwatch"]} fixedWidth className="text-blue-600 mr-1 ml-0.5 text-sm" />{(this.props.screen.ad_duration)}"</span>
                                }
                            </>
                        }
                            {content.schedule.endDate !== "" ?
                            <div>
                                {!content.isCreativeCarrousel &&
                                    <div>
                                        <FontAwesomeIcon icon={['fal','calendar-alt' ]} className="text-blue-400 ml-1.5 mr-1.5"  />
                                        <span className="playlist-item-size font-extralight pr-1 text-gray-600">{content.schedule.startDate}</span>
                                        <span className="playlist-item-size font-extralight pr-1 text-gray-600">{content.schedule.endDate?"~ "+content.schedule.endDate:""}</span>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <FontAwesomeIcon icon={['fal','calendar-alt' ]} className="text-blue-400 ml-1.5 mr-1.5"  />
                                <span className="playlist-item-size font-extralight pr-1 text-gray-600">{content.schedule.startDate}</span>
                                <span className="playlist-item-size font-extralight pr-1 text-gray-600">{content.schedule.endDate?"~ "+content.schedule.endDate:""}</span>
                            </div>
                            }
                            {isContentForFuture(content) &&
                                <>
                                    <ReactTooltip effect="solid" className="p-2" id={`futureContent${content._id}`} aria-haspopup='true'>
                                        <p className="text-center">
                                            {this.props.t('sections.screens.screenPlaylist.futureContent')}: {content.schedule.startDate}
                                        </p>
                                    </ReactTooltip>
                                    <div className='my-auto font-extralight text-xs'>
                                        <FontAwesomeIcon icon={["fas", "info-circle"]} fixedWidth className="text-blue-500" data-tip data-for={`futureContent${content._id}`}/>
                                    </div>
                                </>
                            }
                        </div>
                        {content.daily_limit &&
                            <>
                            <div>
                                <div className="flex">
                                    <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fas", "tasks-alt"]} fixedWidth className="text-blue-300 mr-1 ml-1 text-sm" />
                                    {content.daily_limit && getTimeThatContentFitsInTimeLeftOnScreens(content,{items: [content],screens: [this.props.screen]})[this.props.screen._id] < content.daily_limit &&
                                        <>
                                        <span className="cursor-pointer mr-1" data-tip data-for={`dailyLimitExceeded${content._id}`}><FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-sm" /></span>
                                            <ReactTooltip effect="solid" className="p-2" id={`dailyLimitExceeded${content._id}`} aria-haspopup='true'>
                                                <p className="text-center max-w-sm overflow-ellipsis">
                                                    {this.props.t('sections.screens.screenPlaylist.dailyImpressionsExceededTooltip').replace('_impressions_',getTimeThatContentFitsInTimeLeftOnScreens(content,{items: [content],screens: [this.props.screen]})[this.props.screen._id])}
                                                </p>
                                            </ReactTooltip>
                                            <span className="playlist-item-duration font-extralight text-gray-600 ml-2 mr-1">{this.props.t('sections.screens.screenPlaylist.limit')}:</span>
                                        </>
                                    }
                                        <span className="bold">{formatNumber(content.impressions_in_day, "currency", null, {decimals:0})}/{formatNumber(content.daily_limit, "currency", null, {decimals:0})} {this.props.t('sections.screens.screenPlaylist.dailyImpressions')}</span>
                                    </span>

                                </div>
                            </div>
                            </>
                        }
                        <span className="font-light text-xs">
                            <FontAwesomeIcon icon={content.downloaded.includes(this.props.screen._id) ? ["fad", "check-double"] : ["fad", "hourglass-half"]} fixedWidth className="text-blue-300 ml-1 mr-1.5"/>
                            {content.downloaded.includes(this.props.screen._id) ? this.props.t('sections.screens.screenPlaylist.downloaded') : this.props.t('sections.screens.screenPlaylist.toSync')}
                        </span>
                    </div>
                </div>
                <div className="playlist-item-options w-1/6 flex justify-end items-center font-extralight text-gray-600 mr-2">
                    <div className={`absolute z-10 right-0 mt-6 mr-6 p-2 px-6 text-xs bg-white rounded-md shadow divide-y divide-blue-200 ${selectedContentId === content._id && contentMenuIsVisible ? "" : "hidden"}`}>
                        {content.original_content
                            ? <div onClick={() => this.props.openModal(`deleteRepeatedContent${this.props.screen._id}`)} className="select-none text-left py-2 text-red-600 hover:text-black">{this.props.t('common.buttons.delete')}</div>
                            : <div onClick={() => this.props.openModal(`repeatContent${this.props.screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{this.props.t('sections.screens.screenPlaylist.repeatContent')}</div>
                        }
                        {!content.original_content &&
                            <div onClick={() => this.props.openModal(`deLinkContent${this.props.screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{this.props.t('sections.screens.screenPlaylist.deLinkContent')}</div>
                        }
                        <div onClick={() => this.props.openModal(`unLinkAllContent${this.props.screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{this.props.t('sections.screens.screenPlaylist.deLinkAllButThis')}</div>
                        {this.props.contentHasBeenRepeated(content._id) &&
                            <div onClick={() => this.props.openModal(`subtractRepeatedContent${this.props.screen._id}`)} className="select-none text-left py-2 text-red-600 hover:text-black">{this.props.t('sections.screens.screenPlaylist.subtractRepeatedContent')}</div>
                        }
                    </div>
                    {content.isCreativeCarrousel && <div onClick={() => this.props.handleModalOfContent("CarrouselCreativeModal",content)} className="items-center cursor-pointer flex  mr-3 font-bold hover:text-black"><FontAwesomeIcon icon={["fal", "sliders-h"]} /></div>}
                    {
                    this.props.hasContentCloned(content._id,this.props.screen._id) &&
                        <div className="w-10 flex items-center">
                            {content.hideCloned ?
                            <>
                                <FontAwesomeIcon onClick={()=> this.props.handleContentCloned(content._id)} icon={["fas", "eye-slash"]} className="text-blue-400 ml-1 mr-1 cursor-pointer"  />
                                <label>{this.props.countClonedContentWithID(content._id)}x</label>
                            </>

                            :
                            <>
                                <FontAwesomeIcon onClick={()=> this.props.handleContentCloned(content._id)} icon={["fas", "eye"]} className="text-blue-400 ml-1 mr-1 cursor-pointer"  />
                                <label>{this.props.countClonedContentWithID(content._id)}x</label>
                            </>
                            }

                        </div>
                    }
                    <div data-type="content" data-target={content._id} data-screen={this.props.screen._id} className="py-1 px-2 cursor-pointer text-center"><FontAwesomeIcon icon={["fal", "cog"]} className="text-blue-600 text-sm" /></div>
                    <div id={"content" + index}>
                        {this.props.renderPlaylistContentSwitch(index, content)}
                    </div>
                </div>
            </>
        )
    }
}
export default withTranslation()(ListItem);