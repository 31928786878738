import React, { Component } from 'react';
import ContentHeader from './ContentHeader';
import { getAllContent, deleteContent, changePath, checkLimitExceeded, updateContent, renameContent, replaceMediaContentFromItem } from './contentAJAXFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBrands, getCustomers, getScreenGroups } from '../utils/CommonRequests';
import { getScreens } from '../map/MapFunctions';
import ContentList from './ContentList';
import Select, { createFilter, components } from 'react-select';
import iabCatEN from '../../util_files/iab_categories_en.json';
import { withTranslation } from 'react-i18next';
import * as dotenv from 'dotenv';
import {cloneDeep, isEqual} from 'lodash';
import ButtonSwitcher from '../buttons/ButtonSwitcher';
import GroupedSelect from '../utils/GroupedSelect/GroupedSelect';
import CustomScreenOption from '../utils/GroupedSelect/CustomComponents/CustomScreenOption';
import CustomContentOption from '../utils/GroupedSelect/CustomComponents/CustomContentOption';
import CustomMenu from '../utils/GroupedSelect/CustomComponents/CustomMenu';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { getTimeThatContentFitsInTimeLeftOnScreens } from './Content.utils';
import { uploadFiles } from '../utils/StorageFunctions';
dotenv.config();

class Content extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            itemDetail: {},
            screens: [],
            screenDefault: [],
            defaultCategories: [],
            iab_categories: [],
            isDetailVisible: false,
            checkedCheckboxes: [],
            isCheckboxVisible: false,
            isDropped: false,
            startDate: "",
            endDate: "",
            adTime: "",
            screensExceeded: [],
            newItemDetail: {},
            itemDetailIsChanged: false,
            brands: [],
            customers: [],
            contentHasEndDate: false,
            contentHasDailyLimitRepetitions:false,
            isScreenSelectLoading: true,
            changeNameIsVisible: false,
            defaultScreenOptions: [],
            groups: [],
            isBeingEdited:true,
            contentBeingEdited:{},
            IdcontentBeingEdited:0,
            contentsToOrder:[],
        }
    }
    setContent=(item)=>{
        let itemsCloned=cloneDeep(this.state.items);
        if(itemsCloned){
            let itemModify=itemsCloned.find(item=>item._id===itemsCloned[0]._id);
            if(itemModify !== undefined){
                itemsCloned.forEach((item,i) => {
                    if(item._id === itemModify._id){
                        item.screens = itemModify.screens;
                    }
                })
                this.setState({items:itemsCloned});
            }
        }
    }

    toggleisBeingEdited= (boolean=false) => {
        this.setState({isBeingEdited:boolean});
    }
    setContentBeingEdited = (item) => {
        return new Promise((resolve,reject)=>{
            this.setState({contentBeingEdited:item}, () => {
                if(item.isCreativeCarrousel){
                    let arrOfContent = [];
                    item.contents_id.forEach((id, i)=> {
                        let findedContent = cloneDeep(this.state.items).find(content => content._id === id);
                        if(findedContent){
                            arrOfContent.push(findedContent);
                        }else{
                            item.contents_id.splice(i, 1);
                        }
                    });
                    this.setState({contentsToOrder: arrOfContent},() => {
                        resolve(arrOfContent);
                    })
                }else{
                    resolve();
                }

            })

        })
    }

    handleDragEndModal = (result) => {
        if(!result.destination) return;
        let items = [];
        items = this.state.contentsToOrder;
        let contentBeingEditedCopy = cloneDeep(this.state.contentBeingEdited);
        let contentsToOrder = cloneDeep(items);
        let [reOrderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0 , reOrderedItem);
        let ids = items.map(item=>{
            return item._id;
        });
        contentBeingEditedCopy.contents_id = ids;
        this.setState({contentBeingEdited: contentBeingEditedCopy});
        this.setState({contentsToOrder: items});
        if(!isEqual(contentsToOrder,items)){
            this.setState({itemDetailIsChanged: true});
        }
    }

    componentDidMount = async () => {
        await this.refreshFunction();
        this.hideDetail();
        this.filterCategories();
        this.getBrands();
        this.getCustomers();
        document.getElementById('divDetail').style.top = '0px';
        await this.getScreensAndGroups();
        if(this.props.location.state){
            let content = cloneDeep(this.state.items.find(c => c._id === this.props.location.state.data.contentBeingEdited));
            await this.setContentBeingEdited(content);
            this.toggleDetail(content);
        }

        // document.getElementById('divDetail').style.top = '40px';
        /* getScreens()
        .then(r => {
            if (r.status === 200) {
                getScreenGroups().then(res => {
                    let optionsScreens = [
                        {
                            label: this.props.t('sections.wizard.stepOne.group-label'),
                            value: 1,
                            options: this.parseGroupedOptions(res.data)
                        },
                        {
                            label: this.props.t('sections.content.screen'),
                            value: 2,
                            options: this.parseGroupedOptions(r.data)
                        }
                    ];
                    let defaultOptions = cloneDeep(optionsScreens);
                    this.setState({
                        defaultScreenOptions: defaultOptions,
                        screens: r.data,
                        groups: res.data
                    })
                })
            }
        }) */ //Fragmento de codigo usado para select de grupos y pantallas
    }
    componentDidUpdate=(prevProps, prevState) => {
        if(!isEqual(prevState.itemDetail,this.state.itemDetail)){
            this.setState({changeNameIsVisible:false});
        }
        if(!isEqual(prevState.contentsToOrder,this.state.contentsToOrder)){
           this.setState({contentsToOrder:this.state.contentsToOrder});
        }
    }
    updateContents=(updatedContents = []) => {
        let stateContents=cloneDeep(this.state.items);
        stateContents.forEach((stateContent,i) => {
            let updatedContent=updatedContents.find(content=>content._id===stateContent._id);
            if(updatedContent){
                stateContents[i] = updatedContent;
            }
        })
        this.setState({items: stateContents});
    }
    getBrands = async() => {
        try {
            const response = await getBrands();
            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                return;
            }
            this.setState({brands: response.data});
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.serverError')
            });
        }
    }

    getBrandsOptions = () => this.state.brands.map(brand => {return {label: brand.name, value: brand._id}});
    getContentsOptions = () => this.state.items.map(content => {return {label: content.name, value: content._id}});
    getCustomers = async() => {
        try {
            const response = await getCustomers();
            if (response.status !== 200) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                return;
            }
            this.setState({customers: response.data});
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.serverError')
            });
        }
    }

    getCustomersOptions = () => this.state.customers.map(customer => {return {label: customer.contact_name+"-"+customer.company, value: customer._id}});

    getScreensAndGroups = () => {
        return new Promise((resolve, reject) => {
            getScreens()
            .then(r => {
                if (r.status === 200) {
                        this.setState({screens: r.data});
                        getScreenGroups().then(res => {
                            if(res.status === 200) {
                                this.setState({groups: res.data});
                                let optionsScreens = [
                                    {
                                        label: this.props.t('sections.wizard.stepOne.group-label'),
                                        value: 1,
                                        options: this.parseGroupedOptions(res.data)
                                    },
                                    {
                                        label: this.props.t('sections.content.screen'),
                                        value: 2,
                                        options: this.parseGroupedOptions(r.data)
                                    }
                                ];
                                let defaultOptions = cloneDeep(optionsScreens);
                                this.setState({
                                    defaultScreenOptions: defaultOptions
                                },() => {
                                    resolve();
                                });

                            }
                        })
                        .catch(error => {
                            console.log(error);
                            reject();
                            this.props.showNotification({
                                type: "error",
                                text: this.props.t('common.notification.fileDropper.scheduleDate')
                            });
                            return;
                        })
                }
            })
            .catch(err => {
                console.log(err);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.fileDropper.scheduleDate')
                });
                return;
            })
        })

    }

    toggleDetail = (item) => {
        this.setState({itemDetailIsChanged: false});
        let newItem = { ...item };
        if (!this.state.isDetailVisible)
        document.getElementById('divDetail').classList.remove("hidden");
        document.getElementById('divDetail').style.right = '0px';
        this.setState({
            itemDetail: item
        }, () => {
            let selectedScreens = [];
            let optionScreens = cloneDeep(this.state.defaultScreenOptions);
            optionScreens.forEach(screen => {
                screen.options.forEach(option => {
                    if (this.state.itemDetail?.screen_id?.length === 0) {
                        return;
                    }
                    if (this.state.itemDetail?.screen_id?.includes(option.value)) {
                        selectedScreens.push(option);
                    }
                })
            });
            let groupedScreens = cloneDeep(this.state.groups).map(g => {
                return {
                    group: g._id,
                    screens: cloneDeep(this.state.screens).filter(s => {
                        return s.group === g._id;
                    })
                }
            });
            groupedScreens.forEach(grouped => {
                if(grouped.screens.every(screen => selectedScreens.find(s => s.value === screen._id))){
                    optionScreens.forEach(option => option.options = option.options.filter(opt => opt.value !== grouped.group));
                }
            })
            this.setState({
                screenDefault: selectedScreens,
                screenOptions: optionScreens,
                isScreenSelectLoading: false
            })
        });
        this.setState({ newItemDetail: newItem });
        this.setState({ isDetailVisible: !this.state.isDetailVisible });
        this.setState({
            contentHasEndDate: item.schedule.endDate !== '',
            contentHasDailyLimitRepetitions:item.daily_limit > 0
        });
    }

    hideDetail = () => {
        let video = document.getElementsByTagName('video')[0];
        if (video) {
            video.pause();
            video.currentTime = 0;
        }
        const divDetail = document.getElementById('divDetail');
        const style = getComputedStyle(divDetail);
        const width = style.width.replace('px', '');
        const marginLeft = style.marginLeft.replace('px', '');
        divDetail.style.right = '-' + width + marginLeft + 'px';
        divDetail.classList.add("hidden");
        this.setState({
            isDetailVisible: false,
            isScreenSelectLoading: true,
            contentHasEndDate: false,
            editingDuration: false
        });
    }

    deleteContent = (ids) => {
        /* Codigo de borrado */
        // const content = this.state.checkedCheckboxes;
        deleteContent(ids)
            .then(r => {
                if (r.status === 200) {
                    if(this.state.isDetailVisible){
                        this.hideDetail();
                    }
                    let items=cloneDeep(this.state.items).filter(item=> !ids.includes(item._id));
                    this.setState({items});
                    this.setState({ checkedCheckboxes: [] });
                    this.props.showNotification({
                        text: this.props.t('common.notification.management.removeItem.success'),
                        type: "success",
                        duration:3000
                    })
                }else{
                    this.props.showNotification({
                        text: this.props.t('common.notification.management.removeItem.error'),
                        type: "error",
                        duration:3000
                    })
                }
            })
            .catch(err => console.log(err));
    }
    refreshFunction = () => {
      return new Promise((resolve, reject) => {
        this.setState({ items: [] });
        getAllContent()
            .then(r => {
                let newStateItems = [...this.state.items];
                r.data.forEach(item => {
                    newStateItems.push(item);
                });
                for (let index = 0; index < newStateItems.length; index++) {
                    const item = newStateItems[index];
                    if(item.original_content){
                        newStateItems.splice(index,1);
                        index--;
                    }
                }
                this.setState({ items: newStateItems },() => {
                    resolve();
                });
            })
            .catch(err =>{
                console.log(err);
                reject();
            })
      })
    }
    addContent = (item) => {
        let newStateItems = cloneDeep(this.state.items);
        newStateItems.push(item);
        this.setState({ items: newStateItems });
    }
    handleDrop = (e) => {
        e.preventDefault();
        if (e.target.classList.contains('contentItem')) {
            return;
        } else if (e.target.parentNode.classList.contains('contentItem')) {
            return;
        } else if (e.target.parentNode.parentNode.classList.contains('contentItem')) {
            return;
        }

        changePath('/', e.dataTransfer.getData('ID')).then(r => {
            if (r.status === 200) {
                this.refreshFunction();
            }
        })
    }

    dragEndHandler = (e) => {
        e.preventDefault();
    }

    onChangeSelect = (selected) => { //Funcion utiliazada para select de grupos y pantallas, reemplazar funcion actual por esta
        if(selected === null ){
            selected = [];
            this.setState({ screenOptions: this.state.defaultScreenOptions });
        }else{
            selected.forEach(sel => {
                let filteredOptions;
                if(this.state.groups.find(g => g._id === sel.value)){
                    let screens = this.state.screens;
                    let selectedScreens = screens.filter(s => s.group === sel.value);
                    filteredOptions = this.state.screenOptions.map(data => {
                        return {
                            label: data.label,
                            value: data.value,
                            options: data.options.filter(o =>
                                        !selectedScreens.find(s => s._id === o.value)
                                    )
                        };
                    })
                }else if(this.state.screens.find(s => s._id === sel.value)){
                    let groups = this.state.groups;
                    let screensByGroup = groups.map(g => {
                        return {
                            group: g._id,
                            screens: this.state.screens.filter(s => {
                                return s.group === g._id;
                            })
                        }
                    });
                    let selectedScreenOfGroup = screensByGroup.filter(s => {
                        return s.screens.find(screen => screen._id === sel.value)
                    })[0];
                    let selectedScreens = this.state.screens.map(screen => selected.find(s => s.value === screen._id)).filter(screen => screen !== undefined);
                    if(selectedScreenOfGroup && selectedScreenOfGroup.screens.every(s => selectedScreens.find(screen => screen.value === s._id))){
                        filteredOptions = this.state.screenOptions.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            selectedScreenOfGroup.group !== o.value
                                        )
                            }
                        });
                    }else{
                        filteredOptions = this.state.defaultScreenOptions.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            selectedScreens.find(s => s.value !== o.value)
                                        )
                            }
                        })
                    }
                }else{
                    if(this.state.prevScreenOptions)
                        filteredOptions = this.state.prevScreenOptions;
                    else
                        filteredOptions = this.state.screenOptions;
                }
                this.setState({
                    prevScreenOptions: this.state.screenOptions,
                    screenOptions: filteredOptions,
                })
            })
        }
        let item = { ...this.state.itemDetail };
        this.setState({
            screenDefault: selected
        }, () => {
            item.screen_id = this.parseSelected(selected);
            item.screen_id.forEach(id => {
                if(item.disabled.includes(id)){
                    let indexToRemove = item.disabled.indexOf(id);
                    item.disabled.splice(indexToRemove, 1);
                };
            });
            this.setState({ newItemDetail: item }, this.itemDetailIsChanged);
        });
    }
    parseSelected = (selected) => {
        let parsedSelected = [];
        selected.forEach(sel => {
            if(this.state.groups.find(g => g._id === sel.value)){
                let screens = this.state.screens;
                let selectedScreens = screens.filter(s => s.group === sel.value);
                selectedScreens.forEach(screen => parsedSelected.push(screen._id));
            }else{
                parsedSelected.push(sel.value);
            }
        });
        return parsedSelected;
    }

    itemDetailIsChanged = () => {
        let itemDetail = this.state.itemDetail;
        let newItemDetail = this.state.newItemDetail;
        if (!this.equalsArray(itemDetail.screen_id, newItemDetail.screen_id)) {
            this.setState({ itemDetailIsChanged: true });
        } else {
            let isChanged = itemDetail.schedule.startDate !== newItemDetail.schedule.startDate
                || itemDetail.schedule.endDate !== newItemDetail.schedule.endDate
                || itemDetail.schedule.time !== newItemDetail.schedule.time
                || itemDetail.schedule.endTime !== newItemDetail.schedule.endTime
                || itemDetail.aspectRel !== newItemDetail.aspectRel;
            this.setState({ itemDetailIsChanged: isChanged });
        }

    }
    equalsArray = (a, b) => {
        let boolean = false;
        if (a.length === b.length) {
            boolean = true;
            a.forEach(itemA => {
                if (!b.includes(itemA)) {
                    boolean = false;
                }
            })
        }
        return boolean;
    }
    obtainIdsOfContent = (items) =>{
        let ids = [];
        items.forEach(item=>{
            ids.push(item._id);
        })
        return ids;
    }
    /**
     * It returns the name of the screen with the given id.
     * @param screen_id - The id of the screen you want to get the name of.
     * @returns The name of the screen with the given id.
     */
    getScreenName = (screen_id) => {
        return cloneDeep(this.state.screens).find(screen => screen._id === screen_id).name;
    }

    sendScreensForContent = () => {
        const startDate = this.state.newItemDetail.schedule.startDate;
        const endDate = this.state.newItemDetail.schedule.endDate;

        if(endDate !== '' && endDate !== '0001-01-01' && startDate >= endDate){
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.fileDropper.scheduleDate')
            });
            return;
        }
        let newItemToPut = { ...this.state.newItemDetail };
        newItemToPut.schedule = { ...newItemToPut.schedule };
        if(newItemToPut.isCreativeCarrousel){
            newItemToPut.contents_id = this.obtainIdsOfContent(this.state.contentsToOrder);
            if(newItemToPut.contents_id.length<=0){
                return;
            }
        }
        let dontShowNotification = false;
        if(this.state.itemDetail.daily_limit > 0 && this.state.contentHasDailyLimitRepetitions){
            let isTimeToReturn = false;
            newItemToPut.daily_limit = Number(this.state.itemDetail.daily_limit);
            newItemToPut.impressions_in_day = 0;
            let notFitsInTimeleftScreens = getTimeThatContentFitsInTimeLeftOnScreens(newItemToPut,{items: cloneDeep(this.state.items),screens: cloneDeep(this.state.screens)});
            console.log(notFitsInTimeleftScreens);
            if(Object.keys(notFitsInTimeleftScreens).length > 0){
                let bodyOfNotification = [];
                Object.keys(notFitsInTimeleftScreens).forEach(screen => {
                    let screenName = this.getScreenName(screen);
                    bodyOfNotification.push(this.props.t('common.notification.content.dailyLimitExceededLeft')
                    .replace('_name_',screenName).replace('_impressions_',notFitsInTimeleftScreens[screen].left));
                    console.log(newItemToPut.daily_limit, notFitsInTimeleftScreens[screen].total);
                    if(newItemToPut.daily_limit > notFitsInTimeleftScreens[screen].total){
                        isTimeToReturn = true;
                        bodyOfNotification.push(this.props.t('common.notification.content.dailyLimitExceededTotal')
                        .replace('_name_',screenName).replace('_impressions_',notFitsInTimeleftScreens[screen].total));
                    }
                  
                });
                this.props.showNotification({
                    type: 'warning',
                    duration : 5000,
                    arrOfText: bodyOfNotification
                });
                if(isTimeToReturn){
                    dontShowNotification = true;
                    return false;
                }
                
            };
        } else {
            delete newItemToPut.daily_limit;
            delete newItemToPut.impressions_in_day;
        };
        newItemToPut.duration = Number(newItemToPut.duration);
        this.updateContentOrder(this.state.itemDetail, newItemToPut);
        updateContent(newItemToPut)
            .then(r => {
                if (r.status === 200) {
                    checkLimitExceeded(newItemToPut.screen_id)
                        .then(r => {
                            if (r.data.length > 0){
                                this.setState({ screensExceeded: r.data }, () => {
                                    let quantityContentMore = 0;
                                    r.data.forEach(screenCnt => {
                                        if(screenCnt.quantityContent>screenCnt.quantitySpots){
                                            quantityContentMore += screenCnt.quantityContent - screenCnt.quantitySpots;
                                        }
                                    })
                                    this.props.showNotification({
                                        type: 'warning',
                                        title: this.props.t('common.notification.fileDropper.exceedTitle').replace('_number_',quantityContentMore),
                                        text: this.props.t('common.notification.fileDropper.exceedBody').replace('_number_',r.data.length),
                                    });
                                });
                            }else {
                                if(!dontShowNotification){
                                    this.props.showNotification({
                                        type: 'success',
                                        text: this.props.t('common.notification.screens.contentAssigned'),
                                    });
                                }else{
                                    setTimeout(() =>  this.props.showNotification({
                                        type: 'success',
                                        text: this.props.t('common.notification.screens.contentAssigned'),
                                    }),5000);
                                }
                            }

                        })
                        .catch(err =>{
                            this.props.showNotification({
                                type: "error",
                                text: this.props.t('common.notification.serverError')
                            });
                        });
                    this.hideDetail();
                    this.updateContents([newItemToPut]);
                }
            })
            .catch(err => console.log(err));
    }
    updateContentOrder = (contentBeforeChange, contentAfterChange) => {
        if (!contentAfterChange.screen_id || contentAfterChange.screen_id.length === 0) {
            contentAfterChange.order = [];
            return;
        }
        contentAfterChange.screen_id.forEach(id => {
            if (contentAfterChange.order.filter(order => order.screen_id === id).length === 0) {
                const maxIndex = this.getMaxOrderIndexOfScreen(id);
                contentAfterChange.order.push({
                    screen_id: id,
                    index: maxIndex + 1
                });
            };
        });
        const screen_ids_deleted = contentBeforeChange.screen_id.filter(id => !contentAfterChange.screen_id.includes(id));
        if (screen_ids_deleted.length > 0) {
            const orderFiltered = contentAfterChange.order.filter(order => !screen_ids_deleted.includes(order.screen_id));
            contentAfterChange.order = orderFiltered;
        }
    }
    getMaxOrderIndexOfScreen = (screen_id) => {
        const contents = [...this.state.items];
        let maxIndex = -1;
        contents.forEach(content => {
            if (content.order) {
                content.order.forEach(order => {
                    if (order.screen_id === screen_id && order.index > maxIndex)
                        maxIndex = order.index;
                });
            }
        });
        return maxIndex;
    }
    itemToChange = (item) => {
        let items = [...this.state.items];
        items.forEach(i => {
            if (i._id === item._id) {
                i = item;
            }
        })
    }
    switchCheckedCheckbox = () => {
        this.setState({ isCheckboxVisible: !this.state.isCheckboxVisible });
    }

    getCheckboxes = (checkboxesID) => {
        let copyCheckboxes = checkboxesID;
        this.setState({ checkedCheckboxes: copyCheckboxes });
    }

    clearDeleteCheckboxes = () => {
        this.setState({ checkedCheckboxes: [] });
        document.querySelectorAll('.checkboxDelete').forEach(check => check.checked = false);
        document.getElementById('allContentCheck').checked = false;
    }

    onChangeInput = (e) => {
        if((e.target.id === "startDate" || e.target.id === "time") && e.target.value === ""){
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.fileDropper.selectSchedule')
            });
            return;
        }
        let newItemDetail = { ...this.state.newItemDetail };
        newItemDetail.schedule = { ...newItemDetail.schedule };
        newItemDetail.schedule[e.target.id] = e.target.value;
        this.setState({
            newItemDetail: newItemDetail,
        }, this.itemDetailIsChanged);
    }

    handleAspectRatio = (e) => {
        let checkbox = e.target;
        let itemDetail = {...this.state.itemDetail}
        if(checkbox.getAttribute("id") === "imgRelation"){
            itemDetail.aspectRel = "image";
        }else{
            itemDetail.aspectRel = "screen";
        }
        this.setState({
            newItemDetail: itemDetail
        }, this.itemDetailIsChanged);
    }

    filterCategories = () => {
        let filtered_categories = [];
        iabCatEN.forEach(cat => {
            let filt_cat = {};
            filt_cat.label = cat.Name;
            filt_cat.value = cat.UniqueID;
            filtered_categories.push(filt_cat);
        })
        this.setState({iab_categories: filtered_categories});
    }

    handleChangeCategories = (e) => {
        this.setState({defaultCategories: e});
        let newItemDetail = {...this.state.newItemDetail};
        newItemDetail.categories = e;
        this.setState({newItemDetail, itemDetailIsChanged: true});
    }

    getCategories = () => {
        return this.state.selectedCategories;
    }

    handleBrand = (option) => {
        const newItem = cloneDeep(this.state.newItemDetail);
        if (option)
            newItem.brand = option.value;
        else
            delete newItem.brand;
        this.setState({newItemDetail: newItem, itemDetailIsChanged: true});
    }

    handleCustomer = (option) => {
        const newItem = cloneDeep(this.state.newItemDetail);
        if (option)
            newItem.customer = option.value;
        else
            delete newItem.customer;

        this.setState({newItemDetail: newItem, itemDetailIsChanged: true});
    }

    outOfDate = (date) => {
        return (new Date() - new Date(date)) > 0;
    }

    handleNoEndDate = () => {
        this.setState({
            contentHasEndDate: !this.state.contentHasEndDate
        }, () => {
            let newItemDetail = {...this.state.newItemDetail};
            if (this.state.contentHasEndDate)
                newItemDetail.schedule.endDate = "";
            this.setState({newItemDetail, itemDetailIsChanged: true});
        })
    }
    /**
     * Issue DS-224,
     * Function that change the value of the switch of daily limit
     */
    handleDailyLimitRepetitions = () => {
        this.setState({
            contentHasDailyLimitRepetitions: !this.state.contentHasDailyLimitRepetitions
        }, () => {
            let newItemDetail = {...this.state.newItemDetail};
            if(newItemDetail.daily_limit > 0){
                this.setState({itemDetailIsChanged: true});
            }
        })
    }

    screenOptions = () => this.state.screenOptions?.map(c => c);

    parseGroupedOptions = (options) => {
        return options.map(c => {
            return {
                label: c.name,
                value: c._id,
            };
        })
    }
    toggleItemName = () => {
      this.setState({changeNameIsVisible: !this.state.changeNameIsVisible});
    }
    renameContent= () => {
        let input= cloneDeep(this.state.newItemDetail.name);
        if(input!==""){
            renameContent(input,this.state.itemDetail._id)
            .then((res)=>{
                if(res.status===200){
                    let newItem = cloneDeep(this.state.itemDetail);
                    newItem.name = res.data.name;
                    newItem._rev = res.data.rev;
                    this.setState({itemDetail:newItem});
                    this.props.showNotification({
                        text: this.props.t('common.notification.content.renameSuccess'),
                        type: "success",
                        duration:3000
                    });
                    this.updateContents([newItem]);
                }else{
                    this.props.showNotification({
                        text: this.props.t('common.notification.serverError'),
                        type: "error",
                        duration:3000
                    });
                }
                })
            .catch(err => {
                console.log(err)
                this.props.showNotification({
                    text: this.props.t('common.notification.serverError'),
                    type: "error",
                    duration:3000
                })
            })
        }
        this.toggleItemName();
    }

    handleEditAdDuration = () => {
        this.setState({
            editingDuration: this.state.editingDuration ? !this.state.editingDuration : true
        }, () => {
            if(!this.state.editingDuration){
                let newItem = cloneDeep(this.state.newItemDetail);
                newItem.duration = this.state.itemDetail.duration || undefined;
                this.setState({
                    newItemDetail: newItem,
                });
            }
        });
    }

    getMaxDuration = (item) => {
        let maxDuration;
        let assignedScreenDuration = this.state.screens.filter(s => item.screen_id?.includes(s._id)).sort((s1, s2) => s1.ad_duration > s2.ad_duration)[0];
        maxDuration = assignedScreenDuration?.ad_duration;
        return maxDuration;
    }

    handleSelectContent = (e)=>{
        e = e || [] ;// if(!e){e=[]}
        let contents = cloneDeep(this.state.items);
        let idsSelected = e.map(opt=>opt.value);
        let contentSelected = contents.filter(content => idsSelected.includes(content._id) && !content.isCreativeCarrousel);
        this.setState({contentsToOrder:contentSelected,itemDetailIsChanged: contentSelected.length > 0 ? true : false});
    }

    handleChangeDuration = (e) => {
        let item = cloneDeep(this.state.newItemDetail);
        let regEx = /^[+]?([0-9]+(?:[0-9]*)?|\.[0-9]+)$/;
        if(e.target.value.match(regEx)){
            item.duration = e.target.value;
            this.setState({
                newItemDetail: item,
            });
        }else{
            e.target.value = item.duration;
            this.props.showNotification({
                text: this.props.t('sections.content.inputOnlyAcceptNumbers'),
                type: "error",
                duration:3000
            });
        }
    }
    getOptionSelect = (items=[]) => {
        let options=[];
        items.forEach(e => {
           let obj={
               label:e.name,
               value:e._id
           };
            options.push(obj);
        })
        return options;
    }
    handleSaveDuration = () => {
        this.sendScreensForContent();
        this.props.showNotification({
            text: this.props.t('sections.content.durationEditSuccess'),
            type: "success",
        });
    }
    /**
     * Issue DS-224,
     * Change the value of the inut for some valid value (integer)
     * @param {*} e value imput number
     */
    onChangeValueOfInput=(e)=>{
        let content = cloneDeep(this.state.itemDetail);
        content.daily_limit = Number(e.target.value.replace(/[,-.]/g,'')).toString();
        this.setState({itemDetail:content,itemDetailIsChanged: true});
    }
    handleOnChangeName = (e) => {
        let item = cloneDeep(this.state.newItemDetail);
        item.name = e.target.value;
        this.setState({
            newItemDetail: item,
        });
    }
    /**
     * Issue DS-224,
     * Change the value of the imput for some valid value (integer)
     * @param {*} e value imput number
     */
    onChangeValueOfImput = (e) => {
        let content = cloneDeep(this.state.itemDetail);
        content.daily_limit = Number(e.target.value.replace(/[,-.]/g,'')).toString();
        this.setState({itemDetail:content,itemDetailIsChanged: true});
    }

    handleReplaceMediaContent = async (e) => {
        let fileToUpload = e.target.files;
        try {
            let uploadResponse = await uploadFiles(fileToUpload);
            let newPath = uploadResponse.data.filesArray[0].key;
            if(uploadResponse.status === 201){
                let replaceResponse = await replaceMediaContentFromItem(this.state.itemDetail._id, newPath);
                if(replaceResponse.status === 200){
                    this.props.showNotification({
                        text: this.props.t('common.notification.content.replaceSuccess'),
                        type: "success",
                        duration: 3000
                    })
                    await this.refreshFunction();
                    let updatedItem = this.state.items.find(item => item._id === this.state.itemDetail._id);
                    this.setState({
                        itemDetail: updatedItem
                    })
                }else{
                    this.props.showNotification({
                        text: this.props.t('common.notification.content.replaceError'),
                        type: "error",
                        duration: 3000
                    })
                }
            }
        } catch (error) {
            this.props.showNotification({
                text: this.props.t('common.notification.serverError'),
                type: "error",
                duration: 3000
            })
        }
    }

    render() {
        const imageExtensions = ['png', 'jpg', 'jpeg'];
        const videoExtensions = ['mp4', 'ogg', 'webm'];
        const htmlExtensions = ['html', 'zip'];
        const zipExtensions = ['zip', 'rar', '7z'];
        const itemDetail = this.state.itemDetail;
        const brandOptions = this.getBrandsOptions();
        const customerOptions = this.getCustomersOptions();
        const customFilter = createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        });
        const CustomOption = props => {
            let filteredOption = this.state.items.filter(c => c._id === props.value);
            return (
                <components.Option {...props}>
                    <CustomContentOption content={filteredOption} props={props}/>
                </components.Option>
            );
        };
        const MenuList = props => {
            return (
                <components.MenuList {...props}>
                    <CustomMenu props={props} />
                </components.MenuList>
            );
          };
        /* A custom component for the Select component. */
        const customScreenOption = props => {
            return (
                <components.Option {...props}>
                    <CustomScreenOption props={props}/>
                </components.Option>
            )
        }
        const simpleTooltip = (text, id) => {
            return (
                <>
                    <ReactTooltip effect="solid" className="p-2 z-50" id={`badgeContent${id}`} aria-haspopup='true' overridePosition={({left, top}) => {return {left: 5, top: top}}}>
                        <p className="text-center max-w-sm overflow-ellipsis">
                            {text}
                        </p>
                    </ReactTooltip>
                    <p className="max-w-sm truncate font-bold py-0 text-lg my-auto" data-tip data-for={`badgeContent${id}`} data-place="bottom">{text}</p>
                </>
            );
        }
        return (
            <div onDrop={this.handleDrop}
                onDragOver={this.dragEndHandler}>
                <ContentHeader openModal={this.props.openModal}  deleteContent={this.deleteContent} switchCheckedCheckbox={this.switchCheckedCheckbox} isCheckboxVisible={this.state.isCheckboxVisible} checkedCheckboxes={this.state.checkedCheckboxes} clearDeleteCheckboxes={this.clearDeleteCheckboxes} items={this.state.items} openDropper={this.props.openDropper}/>
                <div className="relative w-full h-screen overflow-hidden flex py-2">
                    <ContentList  contentBeingEdited={this.setContentBeingEdited} toggleisBeingEdited={this.toggleisBeingEdited} addContent={this.addContent} setContent={this.setContent} showNotification={this.props.showNotification} updateContents={this.updateContents} newItemDetail={this.state.newItemDetail} getBrandsOptions={this.getBrandsOptions} handleBrand={this.handleBrand} openModal={this.props.openModal} deleteContent={this.deleteContent} getCheckboxes={this.getCheckboxes} isCheckboxVisible={this.state.isCheckboxVisible} toggleDetail={this.toggleDetail} items={this.state.items} callbackRefreshFunction={this.refreshFunction} isDetailVisible={this.state.isDetailVisible} hideDetail={this.hideDetail} screens={this.state.screens}/>
                    <input hidden type="file" id="files_input" name="files_input_nm" multiple />
                </div>
                <div id="divDetail" className="fixed content-detail bg-white backdrop rounded-tl-md flex flex-col items-start content-start h-full overflow-auto overflow-x-hidden transition-all shadow-lg duration-300 w-5/6 md:w-3/6 border-l-2 ml-2 p-2 z-10">
                    <div className="absolute left-2 top-2"><FontAwesomeIcon icon={['fal', 'times']} onClick={this.hideDetail} className="cursor-pointer" /></div>
                    <div className={`cursor-pointer absolute right-2 top-2 ${!itemDetail.isCreativeCarrousel ? "" : "hidden"}`}>
                        <button className="group mx-2 bg-blue-400 rounded-md p-1 text-white hover:bg-blue-600 transition ease-in duration-300">
                            <FontAwesomeIcon icon={['fal', 'exchange']} className="transform -rotate-45 mx-1" /><span className='pr-1'>{this.props.t('sections.content.replaceContent')}</span>
                            <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif, video/webm, video/mp4, video/ogg" title=" " onChange={this.handleReplaceMediaContent} class="absolute opacity-0 left-0 text-sm custom-file-input" data-before={"Reemplazar"}></input>
                        </button>
                    </div>
                    {Object.keys(itemDetail).length !== 0 && 
                        <div className="w-full h-auto p-4 mt-6">
                        {Array.isArray(itemDetail.path) ?
                            <>
                            {imageExtensions.includes(itemDetail.path[0].split(".")[1]) &&
                            <><img src={`${window.Config.REACT_APP_FILESERVER_URL}/${itemDetail.path[0]}`} alt={itemDetail.name} className="object-contain rounded-lg m-auto custom-max-h-90" /></>
                            }
                            {videoExtensions.includes(itemDetail.path[0].split(".")[1]) &&
                                <><video controls src={`${window.Config.REACT_APP_FILESERVER_URL}/${itemDetail.path[0]}`} alt={itemDetail.name} className="object-contain rounded-lg m-auto custom-max-h-90"></video></>
                            }
                            {zipExtensions.includes(itemDetail.ext) &&
                                <><img src={require(`../../images/common/html.png`)} alt={itemDetail.name} className="object-contain rounded-lg w-full max-h-80" /><br /></>
                            }
                            </>
                        :
                            <>
                            {imageExtensions.includes(itemDetail.path.split(".")[1]) &&
                            <><img src={`${window.Config.REACT_APP_FILESERVER_URL}/${itemDetail.path}`} alt={itemDetail.name} className="object-contain rounded-lg m-auto custom-max-h-90" /></>
                            }
                            {videoExtensions.includes(itemDetail.path.split(".")[1]) &&
                                <><video controls src={`${window.Config.REACT_APP_FILESERVER_URL}/${itemDetail.path}`} alt={itemDetail.name} className="object-contain rounded-lg m-auto custom-max-h-90"></video></>
                            }
                            {zipExtensions.includes(itemDetail.ext) &&

                                <><img src={require(`../../images/common/html.png`)} alt={itemDetail.name} className="object-contain rounded-lg w-full max-h-80" /><br /></>
                            }
                            </>
                        }
                        
                        <div className="flex flex-wrap w-full align-middle">
                            <label id="labelChangeName" className={`${this.state.changeNameIsVisible ? "" : "hidden"}`}>
                                <div className="flex justify-between">
                                    <input id="inputChangeName" type="text" className="inputRename py-0 appearance-none text-gray-600 border border-gray-200  rounded mr-1  leading-tight focus:outline-none focus:bg-white"  value={this.state.newItemDetail.name} onChange={this.handleOnChangeName}></input>
                                    <div className="align-middle ">
                                        <button className="buttonPrimary-sm mr-2" onClick={this.renameContent}>{this.props.t("sections.content.contentList.rename")}</button>
                                        <button className="buttonSecondary-sm" onClick={this.toggleItemName}>{this.props.t("sections.content.header.cancel")}</button>
                                    </div>
                                </div>
                            </label>
                            <div id="nameItem" className={`${this.state.changeNameIsVisible ? "hidden" : ""}`}>
                                <div className='flex content-center'>
                                    {simpleTooltip(this.state.itemDetail.name, this.state.itemDetail._id)}
                                    <span className="font-light my-auto text-gray-600">.{this.state.itemDetail.ext}</span>
                                    <span onClick={this.toggleItemName} className={`${this.state.changeNameIsVisible ? "hidden" : "buttonPrimary-sm mx-1 cursor-pointer"}`}>{this.props.t("sections.content.contentList.rename")}</span>
                                </div>
                                <div >
                                    {!itemDetail.isCreativeCarrousel &&
                                        !zipExtensions.includes(itemDetail.ext) ? <span className="font-light relative bottom-2 text-gray-500  text-sm">{itemDetail.width + "x" + itemDetail.height}</span> : ''
                                    }
                                    </div>
                            </div>
                            <label className={`mx-2 h-1/2 flex items-center ${(this.state.itemDetail && imageExtensions.includes(itemDetail.ext) && !itemDetail.isCreativeCarrousel && this.state.itemDetail.screen_id.length > 0) ? "visible" : "invisible"}`}>
                                {this.state.editingDuration ?
                                    <input type="number" className="py-1 h-1/2 appearance-none text-gray-600 border border-gray-200 rounded mr-1 mt-1 leading-tight focus:outline-none focus:bg-white" onChange={this.handleChangeDuration} value={this.state.newItemDetail.duration ? this.state.newItemDetail.duration : this.getMaxDuration(this.state.newItemDetail)}/>
                                :
                                    <span className="py-0 text-lg h-1/2 mt-1 mr-1">{this.state.itemDetail.duration ? this.state.itemDetail.duration : this.getMaxDuration(this.state.itemDetail)}"</span>
                                }
                                {this.state.editingDuration &&
                                    <button className="buttonPrimary-sm h-1/2 mr-2 capitalize" onClick={this.handleSaveDuration}>{this.props.t('common.buttons.accept')}</button>
                                }
                                <button className={`mr-2 capitalize ${this.state.editingDuration ? "buttonSecondary-sm" : "buttonPrimary-sm"} h-1/2`} onClick={this.handleEditAdDuration}>{this.state.editingDuration ? this.props.t('common.buttons.cancel') : this.props.t('sections.content.editContentDuration')}</button>
                            </label>
                        </div>
                        </div>
                    }
                    
                    <div className="w-full p-4">
                        <FontAwesomeIcon fixedWidth icon={['fad', 'sign']} className="mr-4 text-blue-300" />
                        <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.associatedScreens')}:</span>
                        {Object.keys(this.state.screenDefault).length > 0 &&
                            <GroupedSelect
                                getGroupedOptions={this.screenOptions}
                                selected={this.state.screenDefault}
                                customComponents={{Option: customScreenOption, MenuList: MenuList}}
                                changeCallback={this.onChangeSelect}
                                extraProps={{isMulti: "true", isLoading: this.state.isScreenSelectLoading, maxMenuHeight: 200, filterOptions: customFilter}}
                            ></GroupedSelect>
                            // <Select filterOptions={customFilter} onChange={this.onChangeSelect} className="w-full mt-2" value={this.state.screenDefault} isMulti options={this.screenOptions() || {}} components={{ MenuList, Option: CustomScreenOption }} isLoading={this.state.isScreenSelectLoading}/>
                        }
                        {Object.keys(this.state.screenDefault).length === 0 &&
                            <GroupedSelect
                                getGroupedOptions={this.screenOptions}
                                selected={[]}
                                customOption={{Option: CustomScreenOption, MenuList: MenuList}}
                                changeCallback={this.onChangeSelect}
                                extraProps={{isMulti: "true", isLoading: this.state.isScreenSelectLoading, maxMenuHeight: 200, filterOptions: customFilter}}
                            ></GroupedSelect>
                            // <Select filterOptions={customFilter} onChange={this.onChangeSelect} className="w-full mt-2" isMulti options={this.screenOptions() || {}} components={{ MenuList, Option: CustomScreenOption }} isLoading={this.state.isScreenSelectLoading}/>
                        }
                    </div>
                    {this.state.itemDetail.schedule &&
                        <>
                            <div className="px-4">
                                <FontAwesomeIcon fixedWidth icon={['fad', 'calendar']} className="mr-4 text-blue-300" />
                                <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.contentPeriod')}:</span>
                            </div>
                            <div className="flex md:flex-row px-4 pb-8 w-full">
                                <div className="flex flex-col w-4/6">
                                    <div className="mt-5 mr-2 w-5/6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="startDate">
                                            {this.props.t('sections.content.calendarFrom')}
                                        </label>
                                        <input className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="startDate" type="date" value={this.state.newItemDetail.schedule?.startDate??"0000-00-00"} onChange={this.onChangeInput} />
                                    </div>
                                    <div className="mt-5 flex justify-between">
                                        <div className="w-5/6">
                                            <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 flex align-center" htmlFor="endDate">
                                                <span className='mr-2 mt-1'>
                                                    {this.props.t('sections.content.calendarUntil')}
                                                </span>
                                                <span className="align-middle font-bold">
                                                    <ButtonSwitcher handleVisibility={this.handleNoEndDate} status={this.state.contentHasEndDate} labelOn={this.props.t("common.modals.fileDropper.withEndDate")} labelOff={this.props.t("common.modals.fileDropper.noEndDate")} revertLabel={true}></ButtonSwitcher>
                                                </span>
                                                {this.outOfDate(this.state.itemDetail.schedule.endDate) ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-500 ml-2 text-sm" /> : ""}
                                            </label>
                                            <div id="endDateDiv">
                                                <input className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${this.state.contentHasEndDate ? "" : "hidden"}`} id="endDate" type="date" value={this.state.newItemDetail.schedule?.endDate??"0000-00-00"} onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="mt-5 ml-auto w-auto">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="time">
                                            <FontAwesomeIcon fixedWidth icon={['fad', 'clock']} className="mr-4 text-blue-300" />
                                            <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.contentStartingHour')}:</span>
                                        </label>
                                        <input onChange={this.onChangeInput} className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="time" name="" value={this.state.newItemDetail.schedule?.time??"00:00"} id="time" />
                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    <div className="mt-4 w-full p-4">
                        <FontAwesomeIcon fixedWidth icon={['fad', 'tags']} className="mr-4 text-blue-300" />
                        <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.brand')}:</span>
                        <Select isClearable={true} filterOptions={customFilter} onChange={this.handleBrand} className="w-full" value={brandOptions.find(option => option.value === this.state.newItemDetail.brand) || []} options={brandOptions} components={{ MenuList }}/>
                    </div>
                    <div className="mt-4 w-full p-4">
                        <FontAwesomeIcon fixedWidth icon={['fad', 'user-tie']} className="mr-4 text-blue-300" />
                        <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.customer')}:</span>
                        <Select isClearable={true} filterOptions={customFilter} onChange={this.handleCustomer} className="w-full" value={customerOptions.find(option => option.value === this.state.newItemDetail.customer) || []} options={customerOptions} components={{ MenuList }}/>
                    </div>
                    <div className="mt-4 w-full p-4">
                        <div className="flex">
                            <FontAwesomeIcon fixedWidth icon={['fas', 'tasks-alt']} className="mr-4 mt-1 text-blue-300" />
                            <span className="text-gray-700 font-bold mr-2">{this.props.t('sections.content.Dailyimpressionlimit')}:</span>
                            <span>
                                <ButtonSwitcher handleVisibility={this.handleDailyLimitRepetitions} status={this.state.contentHasDailyLimitRepetitions} labelOn={this.props.t('sections.content.repLimit')} labelOff={this.props.t('sections.content.noReplayLimit')} revertLabel={true}></ButtonSwitcher>
                            </span>
                        </div>
                        <input id="inputDailyLimit" type="number"  data-input-value={1} value={this.state.itemDetail.daily_limit===undefined ? "": this.state.itemDetail.daily_limit}  className={`block w-1/4 appearance-none border border-gray-300  rounded py-2  text-gray-700 leading-tight focus:outline-none focus:focus:ring ${!this.state.contentHasDailyLimitRepetitions && "hidden"}`} onChange={this.onChangeValueOfImput}></input>
                    </div>
                    {(this.state.contentBeingEdited.isCreativeCarrousel) &&
                    <div className="mt-4 w-full p-4 relative ">
                        <div className=' flex justify-between  '>
                            <div className='flex'>
                                <FontAwesomeIcon icon={['fad','clipboard-list' ]} className="mt-1 mr-4 text-blue-300"/>
                                <span className="text-gray-700 font-bold ">{this.props.t('sections.screens.modals.content')}:</span>
                            </div>
                        </div>
                        <GroupedSelect
                            getGroupedOptions={this.getContentsOptions}
                            customComponents={{Option: CustomOption}}
                            selected={this.getOptionSelect(this.state.contentsToOrder)}
                            changeCallback={this.handleSelectContent}
                            extraProps={{isMulti: "true", closeMenuOnSelect: "false", maxMenuHeight: 200}}
                        ></GroupedSelect>
                        <div className={`flex mt-1 ${this.state.contentsToOrder.length > 0 ? "visible" : "invisible"}`}>
                            <FontAwesomeIcon icon={['fad','clipboard-list' ]} className="mt-1 mr-4 text-blue-300"/>
                            <span className="text-gray-700 font-bold">{this.props.t("sections.content.order")}:</span>
                        </div>
                        <DragDropContext onDragEnd={this.handleDragEndModal}>
                            <Droppable droppableId="screenPlaylist">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className={`playlist-items relative mt-2 divide-y `}>
                                    {/* Item */}
                                    {this.state.contentsToOrder.map((content, index) =>
                                    <>
                                        <Draggable key={content._id} draggableId={content._id} index={index} >
                                        {(provided,snapshot) => {
                                            if (snapshot.isDragging) {
                                                provided.draggableProps.style.left = undefined;
                                                provided.draggableProps.style.top = undefined;
                                            }
                                            return <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={` playlist-item flex justify-between  items-start py-2 rounded-md text-sm ${content.original_content ? 'item-cloned' : 'item-original'} hover:bg-gray-200 active:bg-white active:shadow`}>
                                                <div className=" w-full flex flex-row truncate ">
                                                    <div className=" contenedorEvento flex items-center justify-center mx-1  rounded-lg bg-black   overflow-hidden ">
                                                        {imageExtensions.includes(content.ext) &&
                                                            <> <img className="" src={`${window.Config.REACT_APP_FILESERVER_URL}/${content.path}`} alt={content.name}/></>
                                                        }
                                                        {videoExtensions.includes(content.ext) &&
                                                            <><video src={`${window.Config.REACT_APP_FILESERVER_URL}/${content.path}`} alt={content.name}></video></>
                                                        }
                                                        {htmlExtensions.includes(content.ext)&&
                                                            <><img src={require('../../images/common/html.png')} alt={content.name}></img></>
                                                        }
                                                    </div>
                                                    <div className="playlist-item-description mr-2 md:w-5/6 flex flex-col items-start content-center truncate">
                                                        <span className="playlist-item-name font-bold mr-3">
                                                            <ReactTooltip effect="solid" className="w-72 p-2" id={`contentFullName${content._id}`} aria-haspopup='true'>
                                                                <p className="text-center truncate">
                                                                    {content.name}
                                                                </p>

                                                            </ReactTooltip>
                                                            <div className='flex justify-between'>
                                                                <p className="max-w-xs truncate" data-tip data-for={`contentFullName${content._id}`}>
                                                                    {content.name}
                                                                </p>
                                                                {/* <p className="max-w-xs truncate" data-tip data-for={`contentFullName${content._id}`}>
                                                                    |{content._id}
                                                                </p> */}
                                                            </div>
                                                            {!htmlExtensions.includes(content.ext) ?
                                                            <span className="playlist-item-size font-extralight text-gray-600 ml-1"> {content.width}x{content.height}</span>
                                                            :
                                                            <span className="playlist-item-size font-extralight text-gray-600 ml-1">{this.props.t('sections.screens.screenPlaylist.htmlFile')}</span>
                                                            }
                                                            <span className="italic text-xs text-gray-400">{content.original_content ? this.props.t('sections.screens.screenPlaylist.cloned') : ""}</span>
                                                        </span>
                                                        <div className='flex'>
                                                            {videoExtensions.includes(content.ext) &&
                                                                <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fal", "stopwatch"]} fixedWidth className="text-blue-600 mr-1 ml-0.5 text-sm" />{(content.duration)?.toFixed(2)}"</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }}
                                        </Draggable>
                                    </>
                                    )}
                                    {provided.placeholder}

                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    }
                    {/* SECTION CATEGORIES ON DIV DETAIL */ }
                    {/* <div className="mt-4 w-full p-4">
                        <FontAwesomeIcon fixedWidth icon={['fad', 'tags']} className="mr-4 text-blue-300" />
                        <span className="text-gray-700 font-bold mb-2">{this.props.t('sections.content.contentCategory')}:</span>
                        {this.state.defaultCategories.length > 0 &&
                            <Select filterOptions={customFilter} onChange={this.handleChangeCategories} className="w-full" value={this.state.defaultCategories} isMulti options={this.state.iab_categories} />
                        }
                        {this.state.defaultCategories.length === 0 &&
                            <Select filterOptions={customFilter} onChange={this.handleChangeCategories} className="w-full" isMulti options={this.state.iab_categories} />
                        }
                    </div> */}
                    {/* && this.state.contentsToOrder.length > 0 */}
                    {this.state.itemDetailIsChanged === true &&
                        <button className="buttonPrimary ml-4"
                            onClick={this.sendScreensForContent}>
                            <span className="mr-2">{this.props.t('sections.content.confirm')}</span>
                        </button>
                    }

                </div>
            </div>
        );
    }
}

export default withTranslation()(Content);
