import React, { Component } from 'react'
import { login } from './UserFunctions'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import { ReactComponent as LogoWhite } from '../../images/taggify/logo-white.svg';
// import { ReactComponent as LogoColor } from '../../images/logo-color.svg';
class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: ''
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount = () => {
        if(this.props.location.state){
            if(this.props.location.state.Confirmed){
                this.props.showNotification({
                    type: "success",
                    text: this.props.t('common.notification.login.confirmedAccount')
                });
            }else{
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.login.accountConfirmError')
                });
            }
        }
    }
    onChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    onSubmit(e) {
        e.preventDefault();
        const user = {
            email: this.state.email,
            password: this.state.password
        }
        if (user.email !== '' && user.password !== '') {
            login(user)
                .then(res => {
                    if (res.status === 200) {
                        this.props.setLogin();
                        this.props.history.push('/');
                    }
                    else if (res.status === 401){
                        this.props.showNotification({
                            type: "error",
                            text: this.props.t('common.notification.login.badCredentials')
                        });
                    }
                    else if (res.status === 403){
                        this.props.showNotification({
                            type: "error",
                            text: this.props.t('common.notification.login.confirmYourAccount')
                        });
                    }
                    else if (res.status === 404){
                        this.props.showNotification({
                            type: "error",
                            text: this.props.t('common.notification.login.accountNotFound')
                        });
                    }
                    else {
                        this.props.showNotification({
                            type: "error",
                            text: this.props.t('common.notification.serverError')
                        });
                    }
                })
                .catch((err) => {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t('common.notification.serverError')
                    });
                })
        } else {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.login.emptyInput')
            });
        }
    }

    render() {
        return (
            <>
                <div className={`w-full h-screen flex justify-between bg-cover bg-center bg-gradient-to-r ${ window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? "from-green-400 to-blue-500 w-4/6" : "from-purple-400 to-pink-800 w-full"}`}>{/* bg-cover bg-center bg-gradient-to-r ${ window.Config.REACT_APP_WL_NAME.toString() === "bebot" ? "from-green-400 to-blue-500 w-4/6" : "from-purple-400 to-pink-800 w-full"} */}
                    <div className= {`relative hidden md:block h-full text-white p-4`}>
                        <div className="logo overflow-hidden">
                            {/* <LogoWhite className="h-10 object-left-top" /> */}
                            <img className="h-10 object-left-top" src={require(`../../images/${window.Config.REACT_APP_WL_NAME}/logo-white.svg`)} alt="Taggify Simple Signage" />
                        </div>
                        <div className="logo-product"></div>
                        <div className="absolute z-10 bottom-5 font-light flex flex-col">
                            <p>
                                Simple <span className="text-lg font-bold subpixel-antialiased">Signage</span>
                            </p>
                            <span className='w-max'>
                                {this.props.t('sections.login.slogan')}
                            </span>
                        </div>
                    </div>
                    <div className={`relative w-full md:w-2/6 h-full text-black p-4 flex flex-col ${window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? "bg-white" : "bg-transparent"}`}>
                        <div className="logo block md:hidden">
                            <img src={require(`../../images/${window.Config.REACT_APP_WL_NAME}/logo-color.svg`)} alt="Taggify Simple Signage" /> 
                        </div>
                        <div className="flex justify-end md:hidden content-center mt-2">
                            <div className="inline font-bold mt-3 ml-2 text-gray-700"><span className="font-extralight">Simple</span>Signage</div>
                            <div className="align-bottom font-extralight text-gray-400 text-xs mt-4 ml-4">by Taggify</div>
                        </div>
                        <div className={`mt-5 p-0 md:p-8 h-full font-light ${window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? "text-gray-500" : "text-white"}`}>
                            <div className={`md:text-3xl font-extralight`}>{this.props.t('sections.login.mainTitle')}</div>
                            <p id='p_error' className='hidden absolute right-0 top-0 mr-2 mt-2 text-red-500 text-sm bg-gray-200 p-3 rounded' > {this.state.err_message} </p>
                            <form noValidate autoComplete="off" onSubmit={this.onSubmit} className="mt-8 space-y-6" action="#" method="POST">
                                <div className="my-4">
                                    <input className="mt-2 p-2 rounded-lg w-full bg-gray-200 border-none focus:ring-0"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        id="email" name="email" type="email" autoComplete="off" required
                                        placeholder={this.props.t('sections.login.email')}
                                    ></input>
                                </div>
                                <div className="my-4">
                                    <input className="mt-2 p-2 rounded-lg w-full bg-gray-200 border-none focus:ring-0"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                        id="password" name="password" type="password" autoComplete="on" required
                                        placeholder={this.props.t('sections.login.password')}
                                    ></input>
                                </div>
                                <div className="my-4 flex justify-between content-center">
                                    <label className="text-sm">
                                    </label>
                                    <Link to='/forgotpassword' href="/forgot" className={`text-right text-xs ${window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? "link" : ""}`}>{this.props.t('sections.login.forgotPassword')}</Link>
                                </div>
                                <div className="my-4">
                                    <button type="submit" className="rounded-sm bg-blue-400 text-white transition-colors duration-300 ease-in-out font-light text-sm my-2 mr-2 p-1 py-2 px-2 lg:px-4 focus:outline-none" label={this.props.t('common.buttons.signin')} styleType={"primary"} action="/dashboard">{this.props.t('common.buttons.signin')}</button>
                                    <button label="Cancel" styleType={"secondary"} action="commercial" />
                                </div>
                            </form>
                        <div className="absolute bottom-0 tcenter text-xs pb-4">{this.props.t('sections.login.wantToUse')} <Link className="font-medium underline hover:text-indigo-500" to='/register'> {this.props.t('common.buttons.signup')}</Link></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withTranslation()(Login);